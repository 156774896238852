<template>
  <div class="page-wrapper">
     <!-- All Modal -->

     <div id="auth_info_modal" class="modal custom-modal fade" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Authorised Person</h5>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                             
                                                    <input class="form-control " type="email">
                                        
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Designation</label>
                                                <input class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Phone no <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text">
                                            </div>
                                        </div>
                         
                                        
                                       
                                    </div>
                                    <div class="submit-section">
                                        <button class="btn btn-primary submit-btn">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


 

    <!-- Page Content -->



    <div class="content container-fluid pb-0">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Profile</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="dashboard">Dashboard</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="card mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="profile-view">
                <div class="profile-img-wrap">
                  <div class="profile-img">
                    <a href="" data-bs-toggle="modal" data-bs-target="#addModal78">
                      <img :src="logourl" alt="" />
                    </a>
                  </div>
                </div>
                <div class="profile-basic">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="profile-info-left">
                        <h3 class="user-name m-t-0">
                          {{ basic_information.company_name }}
                        </h3>

                        <small class="text-muted">Technology</small>
                        <div class="staff-id"><span class="text-muted"> Company ID :</span> CLT-0001</div>
                        <div class="staff-id"><span class="text-muted"> Address: </span> 5754 Airport Rd, Coosada, AL, 36020</div>
                        <div class="staff-msg">
                          <a href="apps-chat" class="btn btn-custom">Update</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="personal-info">
                        <li>
                          <span class="title">Contact person name:</span>
                          <span class="text">Md. Rezaul Karim</span>
                        </li>
                        <li>
                          <span class="title">Contact person email:</span>
                          <span class="text"
                            >{{
                              basic_information.sigin_email
                            }}</span
                          >
                        </li>
                        <li>
                          <span class="title">Contact person phone:</span>
                          <span class="text">+880 123 456 789</span>
                        </li>
                       
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card tab-box mt-3">
        <div class="row user-tabs">
          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
            <ul class="nav nav-tabs nav-tabs-bottom">
              <li class="nav-item col-sm-3">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#com_profile"
                  >Other info</a
                >
              </li>
              <li class="nav-item col-sm-3"><a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a></li>
            </ul>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content profile-tab-content">
            <div
              id="com_profile"
              class="pro-overview tab-pane fade show active"
            >
              <div class="row">
                
                
              </div>
              
              
            </div>

            <!-- /Projects Tab -->

            <!-- Task Tab -->
            <div id="tasks" class="tab-pane fade">
              <div class="project-task">
                <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      href="#all_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="true"
                      >All Tasks</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#pending_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="false"
                      >Pending Tasks</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#completed_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="false"
                      >Completed Tasks</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="all_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label"
                                  >Doctor available module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient Profile add</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="pending_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul class="task-lists" id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient Profile add</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="completed_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul class="completes-task-list" id="task-list">
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Task Tab -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>


  <!-- Add Client Modal -->
  <div id="addModal78" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change Logo</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="logo" class="col-form-label">Select Logo:</label>
                <input type="file" id="logo" class="form-control" accept="image/*" v-on:change="handleLogoChange">
                <!-- You can add additional styling or functionality for the logo upload if needed -->
              </div>
            </div>
          </div>
          <div class="submit-section">
            <button @click.prevent="changepassword" class="btn btn-primary submit-btn">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- /Add Client Modal -->
</template>
    
    <script>
// import $ from "jquery";
import axios from "axios";
import Constant from "@/other/Constant";

export default {
  data() {
    return {
      errors: {},
      profile: {},
      logourl: "",
      basic_information: {},
      priceplan: "",

      auth_person: {},
      key_person: "",
      levelone: "",
      company_address: {},
      trade_day: "",
      company_categories: "",
      company_sectors: "",
      countries: "",
      company_hour: {},
    };
  },
  methods: {
    getProfile(uuid) {
      if (uuid) {
        axios
          .get("company/profiles/" + uuid)
          .then((response) => {
            if (response) {
              this.profile = response.data.company;
              this.priceplan = this.profile.price.price.name;
              if (this.profile.basic_information != null) {
                this.basic_information = JSON.parse(
                  this.profile.basic_information
                );
              }
              if (this.profile.logo != null) {
                this.logourl =
                  Constant.filebaseurl + this.profile.logo.file.path;
              }

              if (this.profile.authorised_person_details != null) {
                this.auth_person = JSON.parse(
                  this.profile.authorised_person_details
                );
              }
              if (this.profile.company_address != null) {
                this.company_address = JSON.parse(this.profile.company_address);
              }
              if (this.profile.trading_hour != null) {
                this.company_hour = JSON.parse(this.profile.trading_hour);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted: function () {
    this.uuid = JSON.parse(localStorage.getItem("user")).user.uuid;
    if (this.uuid) {
      this.getProfile(this.uuid);
    }
  },
};
</script>