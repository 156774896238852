<template>
    <div class="wrapper">
      <!-- Page Content -->
      <div class="content-wrapper p-4">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>Payslips</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Manage
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  
  
                  <input type="text" class="form-control" placeholder="Employee ID" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  
  
                  <input type="text" class="form-control" placeholder="Employee Name" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  <select class="form-control form-select">
                    <option>Select Position</option>
                    <option>Global Technologies</option>
                    <option>Delta Infotech</option>
                  </select>
            
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
              
                  <i class="fas fa-search mt-3 secondary_color "></i> 
                
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              <a href="clients" class="grid-icon"><i class="fas fa-th"></i></a>
              <a href="clients_list" class="list-icon active"
                ><i class="fas fa-bars"></i
              ></a>
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-plus"></i> Create payslip</a
              >
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable
              :data="payslips"
              class="table table-striped custom-table"
            >
              <thead>
                <tr>
                  <th>Payslip No</th>
                  <th>Month</th>
                  <th>Year</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create Payslip</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div id="payslip" class="modal-body">
            <form ref="addForm">
              <!-- <h4 class="text-center">Hrm company</h4>
              <p class="text-center mt-1">24,Down Town,Uk</p>
              <hr /> -->

              <div class="row">
                <div class="col-lg-6">
                  <p>
                    Employee name : {{ basic_information.first_name }}
                    {{ basic_information.last_name }}
                  </p>
                  <p>Designation : {{ position }}</p>
                  <p>Department : {{ company_department }}</p>
                </div>

                <div class="col-lg-6">
                  <p>Date of joining : {{ profile.joning_date }}</p>
                  <p>
                    Pay of period : {{ currentMonthName }} {{ currentYear }}
                  </p>
                </div>

                <div class="col-3">
                  <p class="border p-2"> Working days : 30</p>
                </div>
                <div class="col-3">
                  <p class="border p-2">Absent : {{ absentdays }}</p>
                </div>
                <div class="col-3">
                  <p class="border p-2">Leave : {{ leavedays }}</p>
                </div>
                <div class="col-3">
                  <p class="border p-2">Total Attendance : {{ workdays }}</p>
                </div>

                <div class="col-lg-6">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col ">Earnings</th>
                        <th scope="col ">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">Basic</td>
                        <td class="text-right">{{ payroll.basic }}.00</td>
                      </tr>

                      <tr v-for="addings in adding_payrolls" :key="addings">
                        <td class="text-left">{{ addings.allowance }}</td>
                        <td class="text-right">
                          {{ payroll.basic * (addings.value / 100) }}.00
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left">Overtime</td>
                        <td class="text-right">{{ over_ernings }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-right text-bold" colspan="2">
                          Total Earning : {{ totaladdings }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-6">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Deduction</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">Tax</td>
                        
                        <td class="text-right">{{ monthlytax }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-left">Provident Fund</td>
                        
                        <td class="text-right">{{ provident_fund }}.00</td>
                      </tr>

                      <tr
                        v-for="deduction in duduction_payrolls"
                        :key="deduction"
                      >
                        <td class="text-left">{{ deduction.allowance }}</td>
                        <td class="text-right">
                          {{ payroll.basic * (deduction.value / 100) }}.00
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left">Absent Penalty</td>
                        
                        <td class="text-right">{{absent_penalty }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-right text-bold" colspan="2">
                          Total deduction : {{ totaldeduction }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <span class="text-bold">Other Earnings :</span>

              <div class="row mt-2">
                <div class="col-lg-4">
                  <div class="form-group">
                    <select
                      v-model="other_earn"
                      class="form-control"
                      style="width: 100%"
                    >
                      <option disabled value="">Select earning</option>
                      <option value="Festival Bonous">Festival Bonous</option>
                      <option value="Performance Bonous">
                        Performance Bonous
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <input
                      v-model="p_earn"
                      class="form-control"
                      placeholder="%"
                    />
                  </div>
                </div>

                <div class="col-lg-1">
                  <h6 class="mt-1 text-center">Of</h6>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <select
                      v-model="form"
                      class="form-control"
                      style="width: 100%"
                    >
                      <option disabled value="">Select from</option>
                      <option value="basic">Basic</option>
                      <option value="gross">Gross salary</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-1">
                  <button
                      @click.prevent="otherearing"
                      class="form-control btn btn-primary"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                </div>
                <div class="col-6">
                  <table
                    v-if="other_earings.length > 0"
                    class=" table w-100 table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Other Earnings</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="other_earn in other_earings" :key="other_earn">
                        <td class="text-left px-2">{{ other_earn.name }}</td>
                        <td class="text-right px-2">
                          {{ other_earn.amount }}.00
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right text-bold px-2">
                          Total other earnings : {{ total_other }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <h6 class="border mt-4 p-2">
                <span class="text-bold"
                  >Net Payable :
                  {{ totaladdings - totaldeduction + total_other }}.00</span
                >
              </h6>
              <h6 class="p-2">
                <span class="text-bold"
                  >In word :
                  {{ getword(totaladdings - totaldeduction + total_other) }}
                </span>
              </h6>

              <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="goto_payslip_view"
                    class="btn btn-primary submit-btn"
                  >
                    Create
                  </button>
                </div>
            </form>
          </div>

          

          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="editModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update Department</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Department name <span class="text-danger">*</span></label
                      >
                      <input
                        id="okk"
                        type="text"
                      
                        class="form-control"
                      />
                  
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Department ID(Max 2 Digit)</label
                      >
                      <input
                        type="number"
                      
                        class="form-control"
                      />
                   
                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent=""
                    class="btn btn-primary submit-btn"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="delete_client" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete Client</h3>
                <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
  
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent=""
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a
      
                      data-bs-dismiss="modal"
            
                   
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
      
      <script>
  import axios from "axios";
  //   import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
  import moment from "moment";
  import jsPDF from "jspdf";

  import Inword from "@/other/inword";


  import $ from "jquery";
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
        errors: {},
        departments: [],
        department: {},
        uuid:"",
        startOfMonth:"",
        endOfMonth:"",
        currentDate:"",
        currentMonth:"",
        currentMonth_single:"",
        currentMonthName:"",
        currentYear:"",
        selectedYear:"",
        selectedMonth:"",
        currentTime:"",


        profile:{},

        grade_id:"",
        employee_id:"",

        position:"",

        company_department : "",

        department_id:"",

        basic_information:{},



        activities:[],


        workdays:0,
        leavedays :0,
        absent : 0,





        payroll:{},

        allowances:[],

        adding_payrolls:[],

        duduction_payrolls:[],





        taxrule:{},

        totaladdings :0,

        totaldeduction:0,

        taxableamount:0,

        cap_details:[],

        diff :0,
        
        totaltax : 0,

        monthlytax :0,

        other_earings : [],
        total_other:0,



        pfrule:{},
        employee_contribution : 0,
        company_contribution : 0,
        provident_fund:0,

        total_over_time :0.0,
        over_ernings : 0,

        absent_penalty : 0,






        monthdifference : 0,

        joining_month : "",


        word : "",
        payslips : [],



         










        









        





      };
    },
  
    methods: {
      getpayslip() {
        axios
          .get("/company/payslips")
          .then((response) => {
            if (response) {
              var payslips = response.data.data;
  
              this.payslips = [];
  
              for (var payslip of payslips) {
                 var id = payslip.id;
                var uuid = payslip.uuid;
  
                var month =payslip.month;
                var year = payslip.year;
                var action =
                  "<a href='#' onclick='handleClick(" +
                  '"' +
                  uuid +
                  '"' +
                  ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                  '"' +
                  uuid +
                  '"' +
                  ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";
  
                this.payslips.push([id, month,year, action]);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

   
  
      handleClick(id) {
        axios
          .get("/company/departments/" + id)
          .then((response) => {
            if (response) {
              this.department = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deletePopUp(uuid) {
      this.uuid=uuid
    
  
      },
       

      current() {
      this.startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
      this.endOfMonth = parseInt(moment().endOf("month").format("DD"));
      this.currentDate = moment().format("D/MM/YYYY");
      this.currentMonth = moment().format("MM");
      this.currentMonth_single = moment().format("M");
      this.currentMonthName = moment().format("MMMM");
      this.currentYear = moment().format("YYYY");
      this.selectedYear = this.currentYear;
      this.selectedMonth = this.currentMonthName;
      this.currentTime = moment().format("hh:mm:ss A");
    },

    getEmployee(uuid) {
      if (uuid) {
        axios
          .get("company/employees/" + uuid)
          .then((response) => {
            if (response) {
              this.profile = response.data.data;
              this.employee_id = this.profile.id;

              this.grade_id = this.profile.grade_id;
              this.position = this.profile.position.position_name;
              this.company_department =
                this.profile.company_department.department_name;


              this.department_id = this.profile.company_department_id;
              if (this.grade_id) {
                this.getPayroll(this.grade_id, this.department_id);
                this.MonthDifference();
              }

              if (this.profile.basic_information != null) {
                this.basic_information = JSON.parse(
                  this.profile.basic_information
                );
              }

              if (this.profile.authorised_person_details != null) {
                this.auth_person = JSON.parse(
                  this.profile.authorised_person_details
                );
              }
              if (this.profile.company_address != null) {
                this.company_address = JSON.parse(this.profile.company_address);
              }
              if (this.profile.trading_hour != null) {
                this.company_hour = JSON.parse(this.profile.trading_hour);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    taxCalculation() {
      this.taxableamount = parseInt(this.payroll.basic) * 12;

      this.cap_details = JSON.parse(this.taxrule.cap_details);



      if (this.cap_details.length > 0) {
        for (let i = 0; i < this.cap_details.length; i++) {
          if (i == 0) {
            this.diff = this.taxableamount - this.cap_details[i].tax_high_cap;
          } else {
            if (this.taxableamount >= this.cap_details[i].tax_low_cap) {
              if (this.diff <= this.cap_details[i].tax_cap_difference) {
                this.totaltax =
                  this.totaltax + (this.diff * this.cap_details[i].p_tax) / 100;
              } else {
                this.totaltax =
                  this.totaltax +
                  (this.cap_details[i].tax_cap_difference *
                    this.cap_details[i].p_tax) /
                    100;
                this.diff = this.diff - this.cap_details[i].tax_cap_difference;
              }
            }
          }


        }

        this.monthlytax = Math.round(this.totaltax / 12);

        this.totaldeduction=this.totaldeduction+this.monthlytax





      }
    },

    getword(newword) {
      this.word = Inword.inWords(newword);
      return this.word;
    },

    getPayroll(grade_id, department_id) {
      axios
        .get("/company/payrolls/getbycompany/" + grade_id + "/" + department_id)
        .then((response) => {
          if (response) {
            this.payroll = response.data.data;

            this.allowances = JSON.parse(response.data.data.allowances);

            this.adding_payrolls = this.allowances.filter(
              (item) => item.type === "Adding"
            );

            this.duduction_payrolls = this.allowances.filter(
              (item) => item.type === "deduction"
            );
            this.totaladd();
            this.totaldeduc();
            this.getTaxRule();
            this.getPfrule(this.grade_id);
            this.getAttendance();


          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    getPfrule(grade_id) {
      axios
        .get("/company/pfrules/getbycompany/" + grade_id )
        .then((response) => {
          if (response) {
            this.pfrule = response.data.data;
            this.employee_contribution = this.pfrule.employee_contribution;
            this.company_contribution = this.pfrule.company_contribution;

            this.provident_fund = this.payroll.basic * (this.employee_contribution / 100);

            this.totaldeduction=this.totaldeduction+this.provident_fund


      
          }
        })
        .catch((error) => {

          console.log(error.response);
        });
    },


    getTaxRule() {
      axios
        .get("/company/taxrules")
        .then((response) => {
          if (response) {
            this.taxrule = response.data.data;
            this.taxCalculation();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    totaladd() {
      if (this.adding_payrolls.length > 0) {
        for (let i = 0; i < this.adding_payrolls.length; i++) {
          this.totaladdings =
            this.totaladdings +
            this.payroll.basic * (this.adding_payrolls[i].value / 100);
        }
        this.totaladdings=this.totaladdings+parseInt(this.payroll.basic)

      }
    },
    totaldeduc() {
      if (this.duduction_payrolls.length > 0) {
        for (let i = 0; i < this.duduction_payrolls.length; i++) {
          this.totaldeduction =
            this.totaldeduction +
            this.payroll.basic * (this.duduction_payrolls[i].value / 100);
        }



      }
     

     


    },

    MonthDifference() {
      const getDate = (date) => moment(date, "DD/MM/YYYY").startOf("month");
      this.monthdifference = Math.abs(
        getDate(this.currentDate).diff(
          getDate(this.profile.joning_date),
          "months"
        )
      );

      this.joining_month = moment(
        this.profile.joning_date,
        "DD/MM/YYYY"
      ).format("M");
    },


    download() {
      var pdf2 = new jsPDF("l", "pt", "letter");
      // pdf2.html(document.getElementById("dvContainer"), {
      //         callback: function () {
      //           pdf2.save("multipage.pdf")
      //         },
      //         x: 10,
      //         y: 10 ,
      //       });

      pdf2.html(document.getElementById("payslip")).then(() => {
        pdf2.save("payslip_" + this.employee_id);
      });
    },



    otherearing() {
      if (this.other_earn) {
        if (this.form === "basic") {
          var amount = this.payroll.basic * (this.p_earn / 100);
          this.total_other += Math.round(amount);

          this.other_earn_row = {
            name: this.other_earn,
            amount: Math.round(amount, 2),
          };
          this.other_earings.push(this.other_earn_row);
        }

        if (this.form === "gross") {
          var amount2 = this.totaladdings * (this.p_earn / 100);
          this.total_other += amount2;

          this.other_earn_row = {
            name: this.other_earn,
            amount: amount2,
          };
          this.other_earings.push(this.other_earn_row);
        }
      }
    },

    getAttendance() {
      axios
        .get(
          "/company/attendances/activity/getbymonthid/" +
            this.selectedMonth +
            "/" +
            this.selectedYear +
            "/" +
            this.employee_id
        )
        .then((response) => {
          if (response) {
            this.attendance_id = response.data.data.id;
            this.activities = JSON.parse(response.data.data.activities);

            for( var activity of this.activities){
              if(activity.status!="leave"){
                this.workdays=this.workdays+1
                
              }else{
                this.leavedays=this.leavedays+1
              }

              this.total_over_time=this.total_over_time+parseInt(activity.over_time)

             }


            this.absentdays=30-(this.workdays+this.leavedays)
            this.over_ernings=this.total_over_time*300
            this.totaladdings=this.totaladdings+this.over_ernings

           var perdayworking = parseInt(this.payroll.basic)  / 30;



            this.absent_penalty=parseInt(this.absentdays)*parseInt(perdayworking)

           this.totaldeduction=this.totaldeduction+this.absent_penalty             


             
           

          }
        })
        .catch((error) => {
      
            console.log(error);
          
        });
    },

    goto_payslip_view(){
      $("#addModal .close").click();

      var query ={

        employee_name : this.basic_information.first_name+" "+this.basic_information.last_name,
        position  : this.position,
        department : this.company_department,
        date_of_joining : this. profile.joning_date,
        current_month: this.currentMonthName,
        current_year : this.currentYear,
        working_days : 30,
        employee_id : this.employee_id,
        uuid : this.uuid,
        
        absent_days : this.absentdays,

        leave : this.leavedays,

        total_attendance : this.workdays,

        basic : this.payroll.basic,

        adding_payrolls : JSON.stringify(this.adding_payrolls),

        over_ernings : this.over_ernings,

        total_earing : this.totaladdings,

         monthly_tax :this.monthlytax,

         provident_fund  :this.provident_fund,
         duduction_payrolls: JSON.stringify(this.duduction_payrolls),

         absent_penalty :this.absent_penalty,

         totaldeduction : this.totaldeduction,

         other_earings : JSON.stringify(this.other_earings),

         total_other : this.total_other,

         word : this.getword(this.totaladdings - this.totaldeduction + this.total_other)




      }

      this.$router.push({ name: "company-payslip", query:query});
    }

    




    },
  
    mounted: function () {
      this.name = JSON.parse(localStorage.getItem("user")).user.name;
      
      this.uuid = this.$route.query.uuid;

      this.getEmployee(this.uuid);
  
      window.handleClick = this.handleClick;
      window.deletePopUp = this.deletePopUp;
      this.getpayslip();
      this.current();
    },
  };
  </script>
      
      
            
         <style>
  @import "datatables.net-bs5";
  </style>