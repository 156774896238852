<template>

    <div>

        <TopbarView/>
        <sidebar-view/>
        <router-view/>

      

    </div>
    
  
</template>

<script>
import TopbarView from '@/components/employee/partial/TopbarView.vue'
import SidebarView from '@/components/sidebar/EmployeeSidebarView.vue'

export default {
components:{
    TopbarView,
    SidebarView
},



mounted() { 
  
    var scripts = [""];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

}
</script>

<style>

</style>