<template>
<auth-master-view v-if="this.$route.meta.layout == 'auth'" />
<admin-master-view v-if="this.$route.meta.layout == 'admin'" />
<company-master-view v-if="this.$route.meta.layout == 'company'" />
<employee-master-view v-if="this.$route.meta.layout == 'employee'" />




</template>

<script>
import AuthMasterView from "@/components/masterview/AuthMasterView.vue";
import AdminMasterView from "@/components/masterview/AdminMasterView.vue";
import CompanyMasterView from "@/components/masterview/CompanyMasterView.vue";
import EmployeeMasterView from "@/components/masterview/EmployeeMasterView.vue";

export default {
  components: {
    AuthMasterView,
    AdminMasterView,
    CompanyMasterView,     
    EmployeeMasterView
    

  },

  mounted() {


    var scripts = [
 

      "/assets/js/jquery-3.6.0.min.js",

      "/assets/js/bootstrap.bundle.min.js",
  
      "/assets/js/jquery-ui.min.js",
     



  

      
  




      // "/assets/js/adminlte.js",

    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      var allscript = document.getElementById("allscript");
      allscript.appendChild(tag);
    });

   
  }


 





}
</script>

