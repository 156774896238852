<template>
    <div class="page-wrapper">

        <div class="content container-fluid pb-0">

<!-- Page Header -->
<div class="row">
    <div class="col-md-12">
        <div class="page-head-box">
            <!-- <h3>Payslip</h3> -->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Payslip</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- /Page Header -->

<!-- Print Section -->
<div class="page-header">
    <div class="row align-items-center">
        <div class="col-auto float-end ms-auto">
            <div class="btn-group btn-group-sm">
              <button @click="save_payroll"  class="btn btn-white">SAVE</button>

                <button class="btn btn-white">CSV</button>
                <button @click="download" class="btn btn-white">PDF</button>
                <button class="btn btn-white"><i class="fa fa-print fa-lg"></i> Print</button>
            </div>
        </div>
    </div>
</div>
<!-- /Print Section -->

<div style="margin-top: -100px;" class="row">
    <div id="payslip"    style="
                    width: 600px;
                   
                    padding: 20px;
                    font-size: 80%;

                    margin: 0 auto;

                  ">
        <div  class="card">
            <div class="card-body">
                <h4 class="payslip-title">Payslip for the month of {{ data.current_month }} {{ data.current_year }}</h4>
                <div class="row">
                    <div class="col-sm-6 m-b-20">
                        <ul class="list-unstyled mb-0">
                            <li>Datafluent Analysis</li>
                            <li>Rampura,</li>
                            <li>Dhaka, BD</li>
                        </ul>
                    </div>
                    <div class="col-sm-6 m-b-20">
                        <div class="invoice-details">
                            <h4 class="text-uppercase">Payslip #49029</h4>
                            <ul class="list-unstyled">
                                <li>Salary Month: <span>{{ data.current_month }},{{ data.current_year }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 m-b-20">
                        <ul class="list-unstyled">
                            <li><h5 class="mb-0"><strong>{{data.employee_name}}</strong></h5></li>
                            <li><span>{{ data.position }}</span></li>
                            <li>Employee ID: FT-0009</li>
                            <li>Joining Date: 1 Jan 2022</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 text-right m-b-20">
                        <ul class="list-unstyled">
                            <li>Working days : <span>{{ data.working_days }}</span></li>
                            <li>Absent : <span>{{ data.absent_days }}</span></li>
                            <li>Leave : <span>{{ data.leavedays }}</span></li>

                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div>
                            <h4 class="m-b-10">Earnings</h4>
                            <table class=" w-100 table-bordered">
                    <thead>
                      <tr  >
                        <th class="p-2"  scope="col ">Earnings</th>
                        <th class="p-2" scope="col ">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td  class="text-left p-2">Basic</td>
                        <td class="text-right p-2">{{ data.basic }}.00</td>
                      </tr>

                      <tr v-for="addings in adding_payrolls" :key="addings">
                        <td class="text-left p-2">{{ addings.allowance }}</td>
                        <td class="text-right p-2">
                          {{ data.basic * (addings.value / 100) }}.00
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left p-2">Overtime</td>
                        <td class="text-right p-2">{{ data.over_ernings }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-right text-bold p-2" colspan="2">
                          Total Earning : {{ data.total_earing }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div>
                            <h4 class="m-b-10">Deductions</h4>

                            <table class="w-100 table-bordered">
                    <thead>
                      <tr>
                        <th class="p-2" scope="col">Deduction</th>
                        <th class="p-2" scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left p-2">Tax</td>
                        
                        <td class="text-right p-2 ">{{ data.monthly_tax }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-left p-2 ">Provident Fund</td>
                        
                        <td class="text-right p-2 ">{{ data.provident_fund }}.00</td>
                      </tr>

                      <tr
                        v-for="deduction in duduction_payrolls"
                        :key="deduction"
                      >
                        <td class="text-left p-2">{{ deduction.allowance }}</td>
                        <td class="text-right p-2">
                          {{ data.basic * (deduction.value / 100) }}.00
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left p-2">Absent Penalty</td>
                        
                        <td class="text-right p-2">{{data.absent_penalty }}.00</td>
                      </tr>

                      <tr>
                        <td class="text-right text-bold p-2" colspan="2">
                          Total deduction : {{ data.totaldeduction }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                  <table
                    v-if="other_earings.length > 0"
                    class="  w-100 table-bordered"
                  >
                    <thead>
                      <tr>
                        <th class="p-2" scope="col">Other Earnings</th>
                        <th class="p-2" scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="other_earn in other_earings" :key="other_earn">
                        <td class="text-left px-2 p-2">{{ other_earn.name }}</td>
                        <td class="text-right px-2 p-2">
                          {{ other_earn.amount }}.00
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right text-bold px-2 p-2">
                          Total other earnings : {{ data.total_other }}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                    
                    <div class="col-sm-12 mt-4">
                        <p><strong>Net Salary: {{  data.total_earing - data.totaldeduction +parseInt(data.total_other)}}</strong> BDT ({{ data.word }} only)</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Weekend<span class="text-danger">*</span></label
                      >
                      <div class="custom-select" >

                        <select
           v-model="day"
         >
           <option disabled value=null>Select a day</option>
           <option >Saturday</option>
           <option >Sunday</option>
           <option >Monday</option>
           <option >Tuesday</option>
           <option >Wednesday</option>
           <option >Thursday</option>
           <option >Friday</option>
         </select>
         <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                        
                      </div>
           
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Note</label
                      >
                      <input type="text"  @input="errors.note=null" v-model="note" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.note" >{{ errors.note[0] }}</p>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Work Rate (ex:10%) </label
                      >
                      <input type="text"  @input="errors.rate=null" v-model="rate" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.rate" >{{ errors.rate[0] }}</p>

                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="add"
                    class="btn btn-primary submit-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="editModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} name <span class="text-danger">*</span></label
                      >
                      <input
                        id="okk"
                        type="text"
                        @input="errors.department_name = null"
                        v-model="department.department_name"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.department_name">
                        {{ errors.department_name[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} ID(Max 2 Digit)</label
                      >
                      <input i type="text"  @input="errors.code=null" v-model="department.code" class="form-control" />
                      <p class="text-danger mt-1" v-if="errors.code" >{{ errors.code[0] }}</p>

                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="update(department.uuid)"
                    class="btn btn-primary submit-btn"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
  
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a
      
                      data-bs-dismiss="modal"
            
                   
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
      
      <script>
  import axios from "axios";
  //   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
  import $ from "jquery";
  import jsPDF from "jspdf";

  
//   DataTable.use(DataTablesCore);
  
  export default {

    data() {
      return {
        title: "Weekend",
        weekends: [],
        errors: {},
        department: {},
        day:null,
        uuid:"",

        data:"",
        adding_payrolls:"",
        duduction_payroll:"",
        other_earings:"",
        employee_id : "",
        current_month : "",
        current_year : "",
        

      };
    },
  
    methods: {
      list() {
        axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            
            var weekends = response.data.data;
            this.weekends=[]

            for(var weekend of weekends){
              var id=weekend.id
              var uuid=weekend.uuid
              var day=weekend.day
              var note=weekend.note
              var rate=weekend.rate
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id,day,note,rate,action]);

            }

   
          }
        })
        .catch((error) => {
          console.log(error);
          
        });
      },
      add() {
        axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
   
             
         
           this.rate

           this.holiday_name="";
           this.note="";
              this.list();
              $("#addModal .close").click()
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
      },
      update(uuid) {
        axios
          .put("/company/departments/" + uuid, {
            department_name: this.department.department_name,
            d_id: this.department.d_id,
          })
          .then((response) => {
            if (response) {
              this.list();
  
              $("#editModal .close").click();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      delete_single() {
        axios
          .delete("/company/departments/" + this.uuid)
          .then((response) => {
            if (response) {
              $("#deleteModal.close").click();
              this.list();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      edit(id) {
        axios
          .get("/company/departments/" + id)
          .then((response) => {
            if (response) {
              this.department = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      download() {
      var pdf2 = new jsPDF("a4", "pt", "a4");
      // pdf2.html(document.getElementById("dvContainer"), {
      //         callback: function () {
      //           pdf2.save("multipage.pdf")
      //         },
      //         x: 10,
      //         y: 10 ,
      //       });

      pdf2.html(document.getElementById("payslip")).then(() => {
        pdf2.save("payslip_" + this.employee_id);
      });
    },

    save_payroll() {
      var doc = new jsPDF("a4", "pt", "a4");

        doc.html(document.getElementById("payslip")).then(() => {
        var file = doc.output();

        var formData = new FormData();
        formData.append("employee_id", this.employee_id);
        formData.append("month", this.current_month);
        formData.append("year", this.current_year);
        formData.append("payslip_no", this.current_month+this.current_year );
        formData.append("type","salary" );
        formData.append("payslip_info", JSON.stringify(this.data));
        formData.append("status", 1);
        formData.append("file", file);

        axios
          .post("/company/payslips", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response) {
              this.$router.push({ name: "payslip-list", query:{uuid:this.uuid}});            
            }
          })
          .catch((error) => {

            alert(error.response.data.message)
          });
      });
    },

      deletePopUp(uuid) {
      this.uuid=uuid
    
  
      },
    },
  
    mounted: function () {
      this.name = JSON.parse(localStorage.getItem("user")).user.name;
      window.edit = this.edit;
      window.deletePopUp = this.deletePopUp;
      this.list();
      this.data= this.$route.query;
      this.adding_payrolls = JSON.parse(this.$route.query.adding_payrolls)
      this.duduction_payrolls = JSON.parse(this.$route.query.duduction_payrolls)
      this.other_earings = JSON.parse(this.$route.query.other_earings)
      this.employee_id = this.$route.query.employee_id
      this.current_month = this.$route.query.current_month
      this.current_year = this.$route.query.current_year
      this.uuid=this.$route.query.uuid
    },
  };
  </script>
      
      
            
         <style>
  @import "datatables.net-bs5";
  </style>