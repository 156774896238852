<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Price plan</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Clients
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus mb-0">
                <input type="text" class="form-control floating" />
                <label class="focus-label">Plan ID</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus mb-0">
                <input type="text" class="form-control floating" />
                <label class="focus-label">Plan Name</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus mb-0">
                <select class="select floating">
                  <option>Select Company</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
                <label class="focus-label">Company</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <a href="#" class="btn btn-success btn-search"
                ><i class="fas fa-search me-2"></i> Search
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a href="clients" class="grid-icon"><i class="fas fa-th"></i></a>
            <a href="clients_list" class="list-icon active"
              ><i class="fas fa-bars"></i
            ></a>
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add plan</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="prices" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Price</th>
                <th>Duration</th>
          
                <th>Status</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create plan</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Plane Name<span class="text-danger">*</span></label
                    >
                    <input      v-model="name"
                              @input="errors.name=null"
                             class="form-control" type="text" />
                             <p class="text-danger mt-1" v-if="errors.name" >{{ errors.name[0] }}</p>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Price</label>
                    <input   v-model="price"
                              @input="errors.price=null"
  class="form-control" type="text" />

  <p class="text-danger mt-1" v-if="errors.price" >{{ errors.price[0] }}</p>


  
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Duration <span class="text-danger">*</span></label
                    >
                    <input class="form-control"      v-model="duration"
                        @input="errors.duration=null" type="text" />

                        <p class="text-danger mt-1" v-if="errors.duration" >{{ errors.duration[0] }}</p>

                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Feature <span class="text-danger">*</span></label
                    >
                    <input v-model="feature" class="form-control floating" type="text" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Value <span class="text-danger">*</span></label
                    >
                    <input v-model="value" class="form-control floating" type="text" />
                  </div>
                </div>

                <div class="col-md-2">
                  <button
                    style="margin-top: 37px"
                    class="btn btn-primary px-3 py-2"
                    type="button"
                    @click="addFeature"
                  >
                    <i class="fa fa-plus"></i> ADD
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="table-responsive m-t-15">
                    <table v-if="features.length>0" class="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th class="text-center">value</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="feature in features" :key="feature" >
                          <td>{{feature.feature}}</td>
                          <td class="text-center">
                            {{feature.value}}
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div style="height: 300px;overflow: auto;" class="table-responsive m-t-15">
                    <table class="table table-striped custom-table">
                      <thead style=" position:sticky;
                            top: -3px ;background-color: white;" >
                        <tr>
                          <th>Comapany Module</th>
                          <th width="200px" >Parent</th>
                          <th class="text-center">Select</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="module in modules" :key="module" >
                          <td>{{ module.module_name }}</td>
                          <td  width="200px" v-if="module.parent!=null" >{{ module.parent.module_name }}</td>
                          <td  v-else ></td>
                           
                          <td class="text-center">
                            <input  v-model="selectedModulesID" :value="module.id" type="checkbox" />
                          </td>
                      
                    
                          
                        </tr>
                    
         
                 
                    
                      </tbody>
                    </table>
                  </div>

           
                </div>

                <div class="col-lg-12 mt-4">
                  <div style="height: 300px;overflow: auto;" class="table-responsive m-t-15">
                    <table class="table table-striped custom-table">
                        <thead style=" position:sticky;
                               top: -3px ;background-color: white;" >
                        <tr>
                          <th>Employee Module</th>
                          <th width="200px" >Parent</th>
                          <th class="text-center">Select</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="module in employeemodules" :key="module" >
                          <td>{{ module.module_name }}</td>
                          <td  v-if="module.parent!=null" >{{ module.parent.module_name }}</td>
                          <td v-else ></td>
                           
                          <td class="text-center">
                            <input  v-model="selectedEmployeeModulesID" :value="module.id" type="checkbox" />

                           
                          </td>
                      
                    
                          
                        </tr>
                    
         
                    
         
                 
                    
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="addPrice"  class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="Barry" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Cuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="barrycuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="barrycuda@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="CLT-0001"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      value="9876543210"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Global Technologies"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
  
  <script>
import axios from "axios";
import $ from "jquery";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      name: "",
      features: [],
      errors: {},
      modules: [],
      employeemodules: [],
      selectedModulesID: [],
      selectedEmployeeModulesID: [],
      prices:[]
    };
  },

  methods: {

    addFeature() {
      var feature = { feature: this.feature, value: this.value };
      this.features.push(feature);
    },

    //Adding price to database

    addPrice() {
      axios
        .post("/superadmin/prices", {
          name: this.name,
          price: this.price,
          duration: this.duration,
          // features: JSON.stringify(Object.assign({}, this.features))
          features: JSON.stringify(this.features),
          modules: this.selectedModulesID,
          employeemodules: this.selectedEmployeeModulesID,
        })
        .then((response) => {
          if (response) {
            this.name="",
            this.price="",
            this.duration="",
            this.features=[]    
            $("#addModal .close").click();
            this.getPrice()

          }
        })
        .catch((error) => {

           
          this.errors = error.response.data.errors;
        });
    },
    getModule() {
      axios
        .get("/superadmin/company_modules")
        .then((response) => {
          if (response) {
            this.modules = response.data.data;

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyModule() {
      axios
        .get("/superadmin/employee_modules")
        .then((response) => {
          if (response) {
            this.employeemodules = response.data.data;
          }
        })
      .catch((error) => {
          console.log(error);
        });
    },
    getPrice(){


axios
  .get("/superadmin/prices")
  .then((response) => {

    if(response){
      var prices=response.data.data

      for(var priceplan of prices){
          var uuid=priceplan.uuid
          var name=priceplan.name
          var price=priceplan.price
          var duration=priceplan.duration;
          var status="";
          var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")'  ><i class=' ms-3 far fa-eye'></i></a>";

        this.prices.push([name,price,duration,status,action])
      }


   
     
    }



   

  }).catch((error)=>{
     

   console.log(error.response)





  
  })
 





},


    handleClick(uuid) {
      this.$router.push({
        name: "admin-companyprofile",
        query: { uuid: uuid },
      });
    },
  },

  mounted: function () {

    this.getModule();
    this.getCompanyModule();
    this.getPrice()
    window.handleClick = this.handleClick;
  },
};
</script>
  
  
        
     <style>
@import "datatables.net-bs5";
</style>