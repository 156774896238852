<template>
   <div class="page-wrapper">

<div class="content container-fluid pb-0">

<!-- Page Header -->
<div class="row">
<div class="col-md-12">
<div class="page-head-box">
    <!-- <h3>Payslip</h3> -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Payslip</li>
        </ol>
    </nav>
</div>
</div>
</div>
<!-- /Page Header -->

<!-- Print Section -->
<div class="page-header">
<div class="row align-items-center">
<div class="col-auto float-end ms-auto">
    <div class="btn-group btn-group-sm">
        <button class="btn btn-white">CSV</button>
        <button class="btn btn-white">PDF</button>
        <button class="btn btn-white"><i class="fa fa-print fa-lg"></i> Print</button>
    </div>
</div>
</div>
</div>
<!-- /Print Section -->

<div class="row">
<div class="col-md-12">
<div class="card">
    <div class="card-body">
        <h4 class="payslip-title">Payslip for the month of Jan 2022</h4>
        <div class="row">
            <div class="col-sm-6 m-b-20">
                <img src="public/assets/img/dfa_logo.png" class="inv-logo" alt="">
                <ul class="list-unstyled mb-0">
                    <li>Datafluent Analysis</li>
                    <li>Rampura,</li>
                    <li>Dhaka, BD</li>
                </ul>
            </div>
            <div class="col-sm-6 m-b-20">
                <div class="invoice-details">
                    <h3 class="text-uppercase">Payslip #49029</h3>
                    <ul class="list-unstyled">
                        <li>Salary Month: <span>February, 2023</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 m-b-20">
                <ul class="list-unstyled">
                    <li><h5 class="mb-0"><strong>Sarwar Jahan Morshed</strong></h5></li>
                    <li><span>COO</span></li>
                    <li>Employee ID: FT-0009</li>
                    <li>Joining Date: 1 Jan 2022</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div>
                    <h4 class="m-b-10"><strong>Earnings</strong></h4>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td><strong>Basic Salary</strong> <span class="float-end">$800</span></td>
                            </tr>
                            <tr>
                                <td><strong>House Rent Allowance (H.R.A.)</strong> <span class="float-end">$55</span></td>
                            </tr>
                            <tr>
                                <td><strong>Conveyance</strong> <span class="float-end">$55</span></td>
                            </tr>
                            <tr>
                                <td><strong>Other Allowance</strong> <span class="float-end">$55</span></td>
                            </tr>
                            <tr>
                                <td><strong>Total Earnings</strong> <span class="float-end"><strong>$55</strong></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h4 class="m-b-10"><strong>Deductions</strong></h4>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td><strong>Tax Deducted at Source (T.D.S.)</strong> <span class="float-end">$0</span></td>
                            </tr>
                            <tr>
                                <td><strong>Provident Fund</strong> <span class="float-end">$0</span></td>
                            </tr>
                            <tr>
                                <td><strong>ESI</strong> <span class="float-end">$0</span></td>
                            </tr>
                            <tr>
                                <td><strong>Loan</strong> <span class="float-end">$90</span></td>
                            </tr>
                            <tr>
                                <td><strong>Total Deductions</strong> <span class="float-end"><strong>$90</strong></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-12">
                <p><strong>Net Salary: $930</strong> (Nine hundred thirty only.)</p>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>

<!-- /Page Content -->

<!-- Add Client Modal -->
<div id="addModal" class="modal custom-modal fade" role="dialog">
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Add {{ title }}</h5>
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >Weekend<span class="text-danger">*</span></label
              >
              <div class="custom-select" >

                <select
   v-model="day"
 >
   <option disabled value=null>Select a day</option>
   <option >Saturday</option>
   <option >Sunday</option>
   <option >Monday</option>
   <option >Tuesday</option>
   <option >Wednesday</option>
   <option >Thursday</option>
   <option >Friday</option>
 </select>
 <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                
              </div>
   
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >Note</label
              >
              <input type="text"  @input="errors.note=null" v-model="note" class="form-control" />
          <p class="text-danger mt-1" v-if="errors.note" >{{ errors.note[0] }}</p>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >Work Rate (ex:10%) </label
              >
              <input type="text"  @input="errors.rate=null" v-model="rate" class="form-control" />
          <p class="text-danger mt-1" v-if="errors.rate" >{{ errors.rate[0] }}</p>

            </div>
          </div>
        </div>

        <div class="submit-section">
          <button
            class="btn btn-primary cancel-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            @click.prevent="add"
            class="btn btn-primary submit-btn"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
<!-- /Add Client Modal -->

<!-- Edit Client Modal -->
<div id="editModal" class="modal custom-modal fade" role="dialog">
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Update {{ title }}</h5>
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >{{ title }} name <span class="text-danger">*</span></label
              >
              <input
                id="okk"
                type="text"
                @input="errors.department_name = null"
                v-model="department.department_name"
                class="form-control"
              />
              <p class="text-danger mt-1" v-if="errors.department_name">
                {{ errors.department_name[0] }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >{{ title }} ID(Max 2 Digit)</label
              >
              <input i type="text"  @input="errors.code=null" v-model="department.code" class="form-control" />
              <p class="text-danger mt-1" v-if="errors.code" >{{ errors.code[0] }}</p>

            </div>
          </div>
        </div>

        <div class="submit-section">
          <button
            class="btn btn-primary cancel-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            @click.prevent="update(department.uuid)"
            class="btn btn-primary submit-btn"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
<!-- /Edit Client Modal -->

<!-- Delete Client Modal -->
<div class="modal custom-modal fade" id="deleteModal" role="dialog">
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-body">
      <div class="form-header">
        <h3>Delete {{ title }}</h3>
        <button
        type="button"
        class="close visually-hidden"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="fas fa-times"></i>

        </button>
        <p>Are you sure want to delete?</p>
      </div>
      <div class="modal-btn delete-action">
        <div class="row">
          <div class="col-6">
            <a
              href="#"
              @click.prevent="delete_single"
              class="btn btn-primary continue-btn"
              >Delete</a
            >
          </div>
          <div class="col-6">
            <a

              data-bs-dismiss="modal"
    
           
              class="btn btn-primary cancel-btn"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- /Delete Client Modal -->
</div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
    department: {},
    day:null,
      uuid:""
    };
  },

  methods: {
    list() {
      axios
      .get("/company/weekends")
      .then((response) => {
        if (response) {
          
          var weekends = response.data.data;
          this.weekends=[]

          for(var weekend of weekends){
            var id=weekend.id
            var uuid=weekend.uuid
            var day=weekend.day
            var note=weekend.note
            var rate=weekend.rate
            var action =
              "<a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
              '"' +
              uuid +
              '"' +
              ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

            this.weekends.push([id,day,note,rate,action]);

          }

 
        }
      })
      .catch((error) => {
        console.log(error);
        
      });
    },
    add() {
      axios
      .post("/company/weekends", {
        note: this.note,
        day: this.day,
        rate: this.rate,
      })
      .then((response) => {
        if (response) {
 
           
       
         this.rate

         this.holiday_name="";
         this.note="";
            this.list();
            $("#addModal .close").click()
        }
      })
      .catch((error) => {
        this.errors = error.response.data.errors;
      });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
    this.uuid=uuid
  

    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>
    

<style>

</style>