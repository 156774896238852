


const DELIVERY = "Delivery"
const CARRIER = "Carrier"
const COLLATION = "Collation"
const CASH_AND_CARRY = "Cash and carry"

//  const filebaseurl="http://hrmback.com/" 
  const filebaseurl="https://api.prohrm.net/public/" 
    
    
export default {
  DELIVERY: DELIVERY,
  CARRIER: CARRIER,
  COLLATION: COLLATION,
  CASH_AND_CARRY: CASH_AND_CARRY,
  filebaseurl:filebaseurl
}