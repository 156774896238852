<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Positions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Grade"
                />
              </div>
            </div>
           
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add {{ title }}</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="positions" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>{{ title }} Name</th>
                <th>Department</th>
                <th>Grade</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Department<span class="text-danger">*</span></label
                  >

                  <select
                    v-model="department_id"
                    class="form-control form-select"
                    @change="getdepartmentposition(department_id)"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a Department</option>
                    <option
                      v-for="department in departments"
                      :key="department"
                      :value="department.id"
                    >
                      {{ department.department_name }}
                    </option>
                  </select>

                  <p class="text-danger mt-1" v-if="errors.department_id">
                    p
                    {{ errors.department_id[0] }}
                  </p>
                </div>

                <div class="col-md-4">
                  <label class="col-form-label"
                    >Level<span class="text-danger">*</span></label
                  >

                  <select
                    v-model="level_id"
                    class="form-control form-select"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a level</option>
                    <option
                      v-for="level in levels"
                      :key="level"
                      :value="level.id"
                    >
                      {{ level.level_name }}
                    </option>
                  </select>

                  <p class="text-danger mt-1" v-if="errors.level_id">
                    {{ errors.level_id[0] }}
                  </p>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Assigned Under<span class="text-danger"></span
                  ></label>

                  <select
                    v-model="assign_under"
                    class="form-control form-select"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a position</option>
                    <option
                      v-for="position in departmentPostions"
                      :key="position"
                      :value="position.id"
                    >
                      {{ position.position_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.position_name = null"
                      v-model="position_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.position_name">
                      {{ errors.position_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Code</label>
                    <input
                      type="text"
                      @input="errors.code = null"
                      v-model="code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="col-form-label">Required</label>
                    <input
                      type="text"
                      @input="errors.code = null"
                      v-model="code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div
                    style="height: 300px; overflow: auto"
                    class="table-responsive m-t-15"
                  >
                    <table class="table table-striped custom-table">
                      <thead
                        style="
                          position: sticky;
                          top: -3px;
                          background-color: white;
                        "
                      >
                        <tr>
                          <th>Comapany Module</th>
                          <th width="200px">Parent</th>
                          <th class="text-center">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="modulec in modules" :key="modulec">
                          <td>{{ modulec.company_module.module_name }}</td>
                          <td
                            width="200px"
                            v-if="modulec.company_module.parent_id != null"
                          >
                            {{ modulec.company_module.parent.module_name }}
                          </td>
                          <td v-else></td>

                          <td class="text-center">
                            <input
                              v-model="selectedModulesID"
                              :value="modulec.company_module.id"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 mt-4">
                  <div
                    style="height: 300px; overflow: auto"
                    class="table-responsive m-t-15"
                  >
                    <table class="table table-striped custom-table">
                      <thead
                        style="
                          position: sticky;
                          top: -3px;
                          background-color: white;
                        "
                      >
                        <tr>
                          <th>Employee Module</th>
                          <th width="200px">Parent</th>
                          <th class="text-center">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="modulee in employee_modules" :key="modulee">
                          <td>{{ modulee.employee_module.module_name }}</td>
                          <td v-if="modulee.employee_module.parent_id != null">
                            {{ modulee.employee_module.parent.module_name }}
                          </td>
                          <td v-else></td>

                          <td class="text-center">
                            <input
                              v-model="selectedEmployeeModulesID"
                              :value="modulee.employee_module.id"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Department<span class="text-danger">*</span></label
                  >

                  <select
                    v-model="department_id"
                    class="form-control form-select"
                    @change="getdepartmentposition(department_id)"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a Department</option>
                    <option
                      v-for="department in departments"
                      :key="department"
                      :value="department.id"
                    >
                      {{ department.department_name }}
                    </option>
                  </select>

                  <p class="text-danger mt-1" v-if="errors.department_id">
                    p
                    {{ errors.department_id[0] }}
                  </p>
                </div>

                <div class="col-md-4">
                  <label class="col-form-label"
                    >Level<span class="text-danger">*</span></label
                  >

                  <select
                    v-model="level_id"
                    class="form-control form-select"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a level</option>
                    <option
                      v-for="level in levels"
                      :key="level"
                      :value="level.id"
                    >
                      {{ level.level_name }}
                    </option>
                  </select>

                  <p class="text-danger mt-1" v-if="errors.level_id">
                    {{ errors.level_id[0] }}
                  </p>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Assigned Under<span class="text-danger"></span
                  ></label>

                  <select
                    v-model="assign_under"
                    class="form-control form-select"
                    style="width: 100%"
                  >
                    <option disabled value="null">Select a position</option>
                    <option
                      v-for="position in departmentPostions"
                      :key="position"
                      :value="position.id"
                    >
                      {{ position.position_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.position_name = null"
                      v-model="position_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.position_name">
                      {{ errors.position_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Code</label>
                    <input
                      type="text"
                      @input="errors.code = null"
                      v-model="code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div
                    style="height: 300px; overflow: auto"
                    class="table-responsive m-t-15"
                  >
                    <table class="table table-striped custom-table">
                      <thead
                        style="
                          position: sticky;
                          top: -3px;
                          background-color: white;
                        "
                      >
                        <tr>
                          <th>Comapany Module</th>
                          <th width="200px">Parent</th>
                          <th class="text-center">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="modulec in modules" :key="modulec">
                          <td>{{ modulec.company_module.module_name }}</td>
                          <td
                            width="200px"
                            v-if="modulec.company_module.parent_id != null"
                          >
                            {{ modulec.company_module.parent.module_name }}
                          </td>
                          <td v-else></td>

                          <td class="text-center">
                            <input
                              v-model="selectedModulesID"
                              :value="modulec.company_module.id"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 mt-4">
                  <div
                    style="height: 300px; overflow: auto"
                    class="table-responsive m-t-15"
                  >
                    <table class="table table-striped custom-table">
                      <thead
                        style="
                          position: sticky;
                          top: -3px;
                          background-color: white;
                        "
                      >
                        <tr>
                          <th>Employee Module</th>
                          <th width="200px">Parent</th>
                          <th class="text-center">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="modulee in employee_modules" :key="modulee">
                          <td>{{ modulee.employee_module.module_name }}</td>
                          <td v-if="modulee.employee_module.parent_id != null">
                            {{ modulee.employee_module.parent.module_name }}
                          </td>
                          <td v-else></td>

                          <td class="text-center">
                            <input
                              v-model="selectedEmployeeModulesID"
                              :value="modulee.employee_module.id"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update"
                  class="btn btn-primary submit-btn"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import Api from "@/other/Api.js";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Position",
      errors: {},
      departments: [],
      levels: [],
      department_id: null,
      level_id: null,
      department: "",
      positions: [],
      modules: [],

      employee_modules: [],
      selectedModulesID: [],
      selectedEmployeeModulesID: [],
      departmentPostions: [],
      assign_under: null,
      position: {},
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/positions")
        .then((response) => {
          if (response) {
            var positions = response.data.data;
            this.positions = [];

            for (var position of positions) {
              var id = position.id;
              var uuid = position.uuid;

              var position_name = position.position_name;

              var department = position.company_department.department_name;

              var level = position.level.level_name;

              var action =
                "<a href='#' onclick='edit(" +
                '"' +
                id +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.positions.push([
                id,
                position_name,
                department,
                level,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            this.departments = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getlevel() {
      axios
        .get("/company/levels")
        .then((response) => {
          if (response) {
            this.levels = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getdepartmentposition(department_id) {
      axios
        .get(Api.company.departmentPostion + department_id)
        .then((response) => {
          if (response) {
            this.departmentPostions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    add() {
      axios
        .post("/company/positions", {
          department_id: this.department_id,
          level_id: this.level_id,
          assign_under: this.assign_under,
          position_name: this.position_name,
          code: this.code,
          modules: this.selectedModulesID,
          employeemodules: this.selectedEmployeeModulesID,
        })
        .then((response) => {
          if (response) {
            this.department_id = null;
            this.level_id = null;
            this.position_name = "";
            this.code = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/positions/" + uuid, {
          position_name: this.position.position_name,
          code: this.position.code,
          department_id: this.position.department_id,
          level_id: this.position.level_id,
          modules: this.selectedModulesID,
          employeemodules: this.selectedEmployeeModulesID,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/positions/" + id)
        .then((response) => {
          if (response) {
            this.position = response.data.data;

            this.getdepartmentposition(this.position.company_department_id);
            this.department_id = this.position.company_department_id;
            this.level_id = this.position.level_id;
            this.assign_under = this.position.assigned_under;
            this.position_name = this.position.position_name;
            this.code = this.position.code;

            this.selectedModulesID = this.position.modules;
            this.selectedEmployeeModulesID = this.position.employeemodules;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    this.getdepartment();
    this.getlevel();

    this.modules = JSON.parse(
      localStorage.getItem("user")
    ).user.price.price.modules;
    this.employee_modules = JSON.parse(
      localStorage.getItem("user")
    ).user.price.price.employee_modules;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
