<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Sortlisted</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Shortlist candidates
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Applicant Name" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Department" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
          
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-print"></i> Print</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="applications" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                        <th>Applicant Name</th>
                        <th>Job Title/Position</th>
                        <th>Department</th>
                        <th>Created at</th>

                        <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Applicant name</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="applicant_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Requested Job<span class="text-danger">*</span></label
                    >
                    
                        <select
                            v-model="published_job_id"
                            class="form-control form-select"
                            style="width: 100%"
                          >
                            <option disabled value="">
                              Select published Job
                            </option>
                            <option
                              v-for="job in publishedjobs"
                              :key="job"
                              :value="job.id"
                            >
                              {{ job.position.position_name }}
                              ({{ job.department.department_name }})
                            </option>
                          </select>
                      <p class="text-danger mt-1" v-if="errors.day">
                        {{ errors.day[0] }}
                      </p>
                    


                  </div>
                </div>
            
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">Cover Letter </label>
                    <ckeditor
                            style="height: 100px !important"
                            :editor="editor"
                            v-model="cover_leter"
                            :config="editorConfig"
                          ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>


                <div class="col-lg-12">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="col-form-label" >Document Name </label>
                              <input
                                v-model="doc_name"
                                type="text"
                                class="form-control"
                              />
                              <sapn id="applicant_name" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label class="col-form-label"  >Documents </label>
                              <!-- <div class="input-group">
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="exampleInputFile"
                            />
                            <label
                              class="custom-file-label"
                              for="exampleInputFile"
                              >Choose file</label
                            >
                          </div>
                        </div> -->
                              <input
                                class="form-control mt-2"
                                @change="onFileSelect"
                                type="file"
                              />
                            </div>
                          </div>
                          <div class="col-lg-1 mt-3">
                            <button
                              @click.prevent="adddocument"
                              type="button"
                              class="btn btn-primary  mt-4"
                            >
                              +
                            </button>
                          </div>

                          <div
                            style="max-height: 200px; overflow: auto"
                            class="col-lg-12"
                          >
                            <table
                              v-if="documents.length > 0"
                              class="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>Document name</th>

                                  <th>File</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(doc, index) in documents"
                                  :key="doc"
                                >
                                  <td>{{ doc.doc_name }}</td>

                                  <td>
                                    <a >
                                      <img
                                        @click.prevent="modalchange(doc.path)"
                                        v-if="doc.type == 'application/pdf'"
                                        style="height: 30px; width: 30px"
                                        src="/assets/images/resource/pdf.png"
                                        alt=""
                                      />

                                      <img
                                        v-if="
                                          doc.type == 'image/png' ||
                                          doc.type == 'image/jpeg'
                                        "
                                        style="height: 30px; width: 30px"
                                        :src="doc.path"
                                        alt=""
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <i
                                      class="far fa-trash-alt"
                                      @click="deleteDocument(index)"
                                    ></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="aplyjob" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department.department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} ID(Max 2 Digit)</label
                    >
                    <input
                      i
                      type="text"
                      @input="errors.code = null"
                      v-model="department.code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div id="viewModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Checkout Application</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                 
                    <label class="col-form-label"
                      >Applicant name :
                      </label
                    >
                    <p>{{ applicant_name }}</p>
                    <label class="col-form-label"
                      >Requested Job :
                      </label
                    >
                  <p >  
                {{ publishedjob }} 
                



                  </p>

                  <label class="col-form-label"
                      >Cover letter :
                      </label
                    >

                    <p>{{ cover_leter }}</p>

                    
                  <label class="col-form-label"
                      >Department :
                      </label
                    >

                    <p>{{ department.department_name }}</p>

                    <h4 style="font-weight:bold" >Documents</h4>

                    <div  v-if="documents.length > 0" > 
                    <div  v-for="(doc) in documents"
                    :key="doc" >

                    <span>Document name : {{ doc.doc_name }}</span>
                    <div class="text-center" >
                      <img 
                                        v-if="
                                          doc.type == 'image/png' ||
                                          doc.type == 'image/jpeg'
                                        "
                                        style="height: 100%x; width: 100%"
                                        :src="doc.path"
                                        alt=""
                                      />
                    


                    </div>
          

                    </div>
                      





                    </div>
                              

              
                </div>
          
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
      
      <script>

import $ from "jquery";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Constant from "@/other/Constant";

import DataTable from "datatables.net-vue3";
 import DataTablesCore from "datatables.net-bs5";


DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
        title:"Job Application",
      isbig: false,
      istwo: true,
      pdfurl:'https://assabuj.com/others/cv.pdf',

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
        // The configuration of the editor.
      },
      errors: {},
      applications: [],
      publishedjobs: [],
      documents: [],
      old_documents: [],
      new_documents: [],
      file: "",

      // input field
 
      applicant_name: "",
      published_job_id: "",
      cover_leter: "",
      department:{},

      publishedjob: "",
      uuid:""
    };
  },

  methods: {
    list() {
        axios
        .get("company/jobapplication/shortlist")
        .then((response) => {
          var applications = response.data.data;

          for (var application of applications) {
            var id = application.id;
            var uuid = application.uuid;
            var applicant_name = application.applicant_name;
            var position =
              application.employee_job_demand.position.position_name;
            var department =
              application.employee_job_demand.department.department_name;
            var created_at = application.created_at;

            var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#viewModal'><i class='fas fa-eye'></i></a>   <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' </a> ";

            this.applications.push([
              id,
              applicant_name,
              position,
              department,
              created_at,
              action,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    aplyjob() {
      var formData = new FormData();
      formData.append("applicant_name", this.applicant_name);
      formData.append("cover_letter", this.cover_leter);
      formData.append("published_job_id", this.published_job_id);

      for (var doc of this.new_documents) {
        formData.append("doc_names[]", doc.doc_name);
        formData.append("files[]", doc.file);
        formData.append("types[]", doc.type);
        formData.append("document_types[]", doc.document_type);
      }

    
      axios
        .post("company/job_appliocations", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);

          this.list();
          $("#addModal .close").click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getjob(job){
        this.publishedjob=job
      },

    handleClick(uuid) {
      this.uuid=uuid
      this.getApplication(uuid);

      // this.$router.push({ name: "jobdetails", query: { uuid: id } });
    },
    getApplication(uuid) {
      this.applicant_name = "";
      this.title = "Update";
      this.documents = [];

      axios
        .get("company/job_appliocations/" + uuid)
        .then((response) => {
          if (response) {
            this.applicant_name = response.data.data.applicant_name;
            this.published_job_id = response.data.data.employee_job_demand_id;
            for(var job of this.publishedjobs){
              if(job.id==this.published_job_id){
                this.publishedjob=job.position.position_name
                this.department=job.department
              }
              
            }
            this.cover_leter = response.data.data.cover_leter;
            var files = response.data.data.files;

            if (files.length > 0) {
              for (var file of files) {
                var doc = {
                  doc_name: file.file_info.document_name,
                  file: "",
                  type: file.file_info.type,
                  document_type: file.file_info.document_type,
                  path: Constant.filebaseurl + file.path,
                };
                this.documents.push(doc);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },

    addToSortedList() {
      axios.post("company/jobapplication/addtoshort/"+this.uuid).then((response) => {
        if (response) {

          alert(response.data.message)
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },

    getPublishedJob() {
      this.publishedjobs = [];
      axios

        .get("company/publishedjob")
        .then((response) => {
          this.publishedjobs = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onFileSelect(event) {
      this.file = event.target.files[0];
    },
    adddocument() {
      if (this.file) {
        var doc = {
          doc_name: this.doc_name,
          file: this.file,
          type: this.file.type,
          path: URL.createObjectURL(this.file),
          document_type: "Applicant document",
        };
        this.new_documents.push(doc);
        this.documents.push(doc);
      }
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.getPublishedJob();
    window.handleClick = this.handleClick;
    this.list();
  },
};
</script>
      
      
            
         <style>
@import "datatables.net-bs5";
</style>