<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Service Rules
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Created Date"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Approved Date"
                />
              </div>
            </div>
           
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="fas fa-plus"></i> Set {{ title }}
            </a>
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable
            :data="serviceRules"
            class="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Title</th>
                <th>Created on</th>
                <th>Approved on</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add service Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Rule Title<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.descripton = null"
                      v-model="descripton"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.descripton">
                      {{ errors.descripton[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Created on<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.creating_date = null"
                      v-model="creating_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.creating_date">
                      {{ errors.creating_date[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Approved on<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.appreve_date = null"
                      v-model="appreve_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.appreve_date">
                      {{ errors.appreve_date[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Approval Document</label>
                    <div class="custom-file">
                      <input
                        type="file"
                        @change="onFileSelect"
                        class="form-control"
                        id="exampleInputFile"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Details<span class="text-danger">*</span></label
                    >
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="sr_details"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.sr_details">
                      {{ errors.sr_details[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add service Modal -->

    <!-- Edit service Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Rule Title<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.descripton = null"
                      v-model="descripton"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.descripton">
                      {{ errors.descripton[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Created on<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.creating_date = null"
                      v-model="creating_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.creating_date">
                      {{ errors.creating_date[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Approved on<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.appreve_date = null"
                      v-model="appreve_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.appreve_date">
                      {{ errors.appreve_date[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Approval Document</label>
                    <div class="custom-file">
                      <input
                        type="file"
                        @change="onFileSelect"
                        class="form-control"
                        id="exampleInputFile"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Details<span class="text-danger">*</span></label
                    >
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="sr_details"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.sr_details">
                      {{ errors.sr_details[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update"
                  class="btn btn-primary submit-btn"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit service Modal -->

    <!-- View service Modal -->
    <div id="viewModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rules and Regulations</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>

          <div class="card-body">
            <div class="role-card">
              <div style="width: 100%; font-weight: bold">
                <p>Created Date: <span>{{ serviceRule.creating_date }}</span></p>
                <p>Approved Date: <span>{{ serviceRule.appreve_date }}</span></p>
              </div>
              <h2>{{ serviceRule.descripton }}</h2>
            
              <p>
               {{ serviceRule.sr_details }}
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /View service Modal -->

    <!-- Delete Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Modal -->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Service Rule",
      errors: {},
      levels: [],
      serviceRules: [],
      serviceRule: {},
      department: {},
      level: {
        uuid: "",
        level_name: "",
        code: "",
      },
      description: "",
      creating_date: "",
      appreve_date: "",
      sr_details: "",

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
      },
      uuid: "",
      file: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/serviceRule")
        .then((response) => {
          if (response) {
            this.serviceRules = [];

            var serviceRules = response.data.data.rule;

            for (var serviceRule of serviceRules) {
              var uuid = serviceRule.uuid;

              var descripton = serviceRule.descripton;
              var creating_date = serviceRule.creating_date;
              var appreve_date = serviceRule.appreve_date;

              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a> " +
                "<a href='#' onclick='viewDetails(" +
                '"' +
                uuid +
                '"' +
                ")' data-bs-toggle='modal' data-bs-target='#viewModal'><i class='ms-3 fas fa-eye'></i></a> " +
                "<a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' data-bs-toggle='modal' data-bs-target='#delete_client'><i class='ms-3 far fa-trash-alt'></i></a>";

              this.serviceRules.push([
                descripton,
                creating_date,
                appreve_date,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    add() {
      axios
        .post("/company/serviceRule", {
          descripton: this.descripton,
          creating_date: this.creating_date,
          appreve_date: this.appreve_date,
          sr_details: this.sr_details,
        })
        .then((response) => {
          if (response) {
            this.descripton = "";
            this.creating_date = "";
            this.appreve_date = "";
            this.sr_details = "";

            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    update(uuid) {
      axios
        .put("/company/levels/" + uuid, {
          level_name: this.level.level_name,
          code: this.level.code,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/levels/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal .close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/levels/" + id)
        .then((response) => {
          if (response) {
            this.level = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
