<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Register Company</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Clients
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus mb-0">
                <input type="text" class="form-control floating" />
                <label class="focus-label">Client ID</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus mb-0">
                <input type="text" class="form-control floating" />
                <label class="focus-label">Client Name</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus mb-0">
                <select class="select floating">
                  <option>Select Company</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
                <label class="focus-label">Company</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <a href="#" class="btn btn-success btn-search"
                ><i class="fas fa-search me-2"></i> Search
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a href="clients" class="grid-icon"><i class="fas fa-th"></i></a>
            <a href="clients_list" class="list-icon active"
              ><i class="fas fa-bars"></i
            ></a>
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_client"
              ><i class="fas fa-plus"></i> Add Client</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="companies" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Category</th>
                <th>Address</th>
                <th>Email</th>
                <th>Status</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="add_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input class="form-control floating" type="email" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" type="password" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control" type="password" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input class="form-control floating" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="Barry" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Cuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="barrycuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="barrycuda@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="CLT-0001"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      value="9876543210"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Global Technologies"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      name: "",
      companies: [],
    };
  },

  methods: {
    getcompanies() {
      axios
        .get("/superadmin/companies")
        .then((response) => {
          if (response) {
            var companies = response.data.company;
            for (var company of companies) {
              var uuid = company.uuid;
              var logourl =
                company.logo != null
                  ? Constant.filebaseurl + company.logo.file.path
                  : "";

              // logourl=Constant.filebaseurl+company.logo.file.path;

              var comapny_name =
                '     <h2 class="table-avatar"> <a href="clients_profile" class="avatar"><img src="' +
                logourl +
                '" style="width: 70px !important;" alt=""></a> </h2>' +
                JSON.parse(company.basic_information).company_name;
              var company_catgory = null;
              var company_address =
                company_address != null
                  ? JSON.parse(company.company_address).address_line_1
                  : null;

              var company_email = JSON.parse(
                company.basic_information
              ).sigin_email;
              var company_status = company.status;
              var company_price = company.price.price.name;
              var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")'  ><i class=' ms-3 far fa-eye'></i></a>";

              this.companies.push([
                comapny_name,
                company_catgory,
                company_address,
                company_email,
                company_status,
                company_price,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleClick(uuid) {


     this.$router.push({ name: "admin-companyprofile", query: { uuid: uuid } });
    },

    
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    this.getcompanies();
    window.handleClick = this.handleClick;
  },
};
</script>


      
   <style>
@import "datatables.net-bs5";
</style>