<template>
   <aside class="main-sidebar sidebar-light-primary elevation-2">
    <!-- Brand Logo -->
    <a href="" class="brand-link text-center">
      <img
        class="pb-3"
        src="/assets/img/logo_color.png"
        width="140px"
        height="100px"
        alt=""
      />
    </a>

    <!-- 
    <div class="user-panel  py-3  d-flex">
        <div class="image">
          <img height="70px" width="50px" style="border-radius: 50%;" :src="logourl" class="ml-3" alt="User Image">
        </div>
        <div class="info ml-2">
          <a href="#" style="color:gray" class="d-block">{{name}}</a>
        </div>
       
      </div>
   -->

    <!-- Sidebar -->
    <div class="sidebar mt-2">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'employee-dashboard' }">
              <i class="nav-icon fas fa-tachometer-alt primary_color"></i>
              <p>Dashboard</p>
            </router-link>
          </li>

          <li class="nav-item  menu-open ">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                how_to_reg
              </span>

              <p>
                Attendance & Leave
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-leaves' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>
                  Leaves
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-attendance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>
                  Attendance
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-visiting-plan' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>
                  Visiting Plan
                </router-link>
              </li>
             
           
           
          
             
            </ul>
          </li>
       
          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Finance & Assets
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-salary' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Salary
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-bills' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Bills
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-loans' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Loans
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-assets' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Assets
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-provident-fund' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Provident Fund
                </router-link>
              </li>
         
             

            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Project & Task
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-projects' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Projects
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-tasks' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Tasks
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-performance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Performance Report
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-special-performance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Special Performance
                </router-link>
              </li>
         
             

            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Administrative
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-attendance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Attendance
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-leave-application' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Leave Applications
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-job-demand' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Demand Job
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-performance-indicator' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Performace Indicator
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-performance-apprisal' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Performance Appraisal
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'employee-admin-visiting' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Visiting
                </router-link>
              </li>
         
             

            </ul>
          </li>


          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'employee-Service-Role' }">
              <i class="nav-icon fas fa-user-cog "></i>
              <p>Service Roles</p>
            </router-link>
          </li>


          
       
          
    
      
          


        
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
    department: {},
    day:null,
      uuid:""
    };
  },

  methods: {
    list() {
      axios
      .get("/company/weekends")
      .then((response) => {
        if (response) {
          
          var weekends = response.data.data;
          this.weekends=[]

          for(var weekend of weekends){
            var id=weekend.id
            var uuid=weekend.uuid
            var day=weekend.day
            var note=weekend.note
            var rate=weekend.rate
            var action =
              "<a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
              '"' +
              uuid +
              '"' +
              ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

            this.weekends.push([id,day,note,rate,action]);

          }

 
        }
      })
      .catch((error) => {
        console.log(error);
        
      });
    },
    add() {
      axios
      .post("/company/weekends", {
        note: this.note,
        day: this.day,
        rate: this.rate,
      })
      .then((response) => {
        if (response) {
 
           
       
         this.rate

         this.holiday_name="";
         this.note="";
            this.list();
            $("#addModal .close").click()
        }
      })
      .catch((error) => {
        this.errors = error.response.data.errors;
      });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
    this.uuid=uuid
  

    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    $(".nav-item").click(function () {
      $(this).toggleClass(" menu-open");
    });
  },
};
</script>
    
    
<style>
</style>