<template>


<div class="page-wrapper">
            
            <!-- Page Content -->
            <div class="content container-fluid pb-0">

                <!-- Page Header -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="page-head-box">
                            <h3>Profile</h3>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="dashboard">Dashboard</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
                
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="profile-view">
                                    <div class="profile-img-wrap">
                                        <div class="profile-img">
                                            <a href="">
                                                <img :src="logourl" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="profile-basic">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="profile-info-left">
                                                    <h3 class="user-name m-t-0">{{basic_information.company_name}}</h3>
                                                
                                                    <small class="text-muted">Technology</small> 
                                                    <div class="staff-id">Company ID : CLT-0001</div>
                                                    <div class="staff-msg"><a href="apps-chat" class="btn btn-custom">Send Message</a></div>
                                                </div>
                                            </div>
                                            <div class="col-md-7">
                                                <ul class="personal-info">
                                                    <li>
                                                        <span class="title">Phone:</span>
                                                        <span class="text"><a href="">9876543210</a></span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Email:</span>
                                                        <span class="text"><a href="">{{basic_information.sigin_email}}</a></span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Birthday:</span>
                                                        <span class="text">2nd August</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Address:</span>
                                                        <span class="text">5754 Airport Rd, Coosada, AL, 36020</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Price Plan:</span>
                                                        <span class="text">{{ priceplan}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card tab-box mt-3">
                    <div class="row user-tabs">
                        <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
                            <ul class="nav nav-tabs nav-tabs-bottom">
                                <li class="nav-item col-sm-3"><a class="nav-link active" data-bs-toggle="tab" href="#myprojects">Projects</a></li>
                                <li class="nav-item col-sm-3"><a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12"> 
                        <div class="tab-content profile-tab-content">
                            
                            <!-- Projects Tab -->
                            <div id="myprojects" class="tab-pane fade show active">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="dropdown profile-action">
                                                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                                <h4 class="project-title"><a href="project_view">Office Management</a></h4>
                                                <small class="block text-ellipsis m-b-15">
                                                    <span class="text-xs">1</span> <span class="text-muted">open tasks, </span>
                                                    <span class="text-xs">9</span> <span class="text-muted">tasks completed</span>
                                                </small>
                                                <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. When an unknown printer took a galley of type and
                                                    scrambled it...
                                                </p>
                                                <div class="pro-deadline m-b-15">
                                                    <div class="sub-title">
                                                        Deadline:
                                                    </div>
                                                    <div class="text-muted">
                                                        17 Apr 2023
                                                    </div>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Project Leader :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Team :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sarwar Jahan Morshed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"></a>
                                                        </li>
                                                        <li class="dropdown avatar-dropdown">
                                                            <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <div class="avatar-group">
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg">
                                                                    </a>
                                                                </div>
                                                                <div class="avatar-pagination">
                                                                    <ul class="pagination">
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                                <span aria-hidden="true">«</span>
                                                                                <span class="visually-hidden">Previous</span>
                                                                            </a>
                                                                        </li>
                                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Next">
                                                                                <span aria-hidden="true">»</span>
                                                                            <span class="visually-hidden">Next</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li> 
                                                    </ul>
                                                </div>
                                                <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                                                <div class="progress progress-xs mb-0">
                                                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="dropdown profile-action">
                                                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                                <h4 class="project-title"><a href="project_view">Project Management</a></h4>
                                                <small class="block text-ellipsis m-b-15">
                                                    <span class="text-xs">2</span> <span class="text-muted">open tasks, </span>
                                                    <span class="text-xs">5</span> <span class="text-muted">tasks completed</span>
                                                </small>
                                                <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. When an unknown printer took a galley of type and
                                                    scrambled it...
                                                </p>
                                                <div class="pro-deadline m-b-15">
                                                    <div class="sub-title">
                                                        Deadline:
                                                    </div>
                                                    <div class="text-muted">
                                                        17 Apr 2023
                                                    </div>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Project Leader :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Team :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sarwar Jahan Morshed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"></a>
                                                        </li>
                                                        <li class="dropdown avatar-dropdown">
                                                            <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <div class="avatar-group">
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg">
                                                                    </a>
                                                                </div>
                                                                <div class="avatar-pagination">
                                                                    <ul class="pagination">
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                                <span aria-hidden="true">«</span>
                                                                                <span class="visually-hidden">Previous</span>
                                                                            </a>
                                                                        </li>
                                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Next">
                                                                                <span aria-hidden="true">»</span>
                                                                            <span class="visually-hidden">Next</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                                                <div class="progress progress-xs mb-0">
                                                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="dropdown profile-action">
                                                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                                <h4 class="project-title"><a href="project_view">Video Calling App</a></h4>
                                                <small class="block text-ellipsis m-b-15">
                                                    <span class="text-xs">3</span> <span class="text-muted">open tasks, </span>
                                                    <span class="text-xs">3</span> <span class="text-muted">tasks completed</span>
                                                </small>
                                                <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. When an unknown printer took a galley of type and
                                                    scrambled it...
                                                </p>
                                                <div class="pro-deadline m-b-15">
                                                    <div class="sub-title">
                                                        Deadline:
                                                    </div>
                                                    <div class="text-muted">
                                                        17 Apr 2023
                                                    </div>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Project Leader :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Team :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sarwar Jahan Morshed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"></a>
                                                        </li>
                                                        <li class="dropdown avatar-dropdown">
                                                            <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <div class="avatar-group">
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg">
                                                                    </a>
                                                                </div>
                                                                <div class="avatar-pagination">
                                                                    <ul class="pagination">
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                                <span aria-hidden="true">«</span>
                                                                                <span class="visually-hidden">Previous</span>
                                                                            </a>
                                                                        </li>
                                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Next">
                                                                                <span aria-hidden="true">»</span>
                                                                            <span class="visually-hidden">Next</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                                                <div class="progress progress-xs mb-0">
                                                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="dropdown profile-action">
                                                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                                <h4 class="project-title"><a href="project_view">Hospital Administration</a></h4>
                                                <small class="block text-ellipsis m-b-15">
                                                    <span class="text-xs">12</span> <span class="text-muted">open tasks, </span>
                                                    <span class="text-xs">4</span> <span class="text-muted">tasks completed</span>
                                                </small>
                                                <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. When an unknown printer took a galley of type and
                                                    scrambled it...
                                                </p>
                                                <div class="pro-deadline m-b-15">
                                                    <div class="sub-title">
                                                        Deadline:
                                                    </div>
                                                    <div class="text-muted">
                                                        17 Apr 2023
                                                    </div>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Project Leader :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="project-members m-b-15">
                                                    <div>Team :</div>
                                                    <ul class="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sarwar Jahan Morshed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"></a>
                                                        </li>
                                                        <li class="dropdown avatar-dropdown">
                                                            <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <div class="avatar-group">
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg">
                                                                    </a>
                                                                    <a class="avatar avatar-xs" href="#">
                                                                        <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg">
                                                                    </a>
                                                                </div>
                                                                <div class="avatar-pagination">
                                                                    <ul class="pagination">
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                                <span aria-hidden="true">«</span>
                                                                                <span class="visually-hidden">Previous</span>
                                                                            </a>
                                                                        </li>
                                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                        <li class="page-item">
                                                                            <a class="page-link" href="#" aria-label="Next">
                                                                                <span aria-hidden="true">»</span>
                                                                            <span class="visually-hidden">Next</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                                                <div class="progress progress-xs mb-0">
                                                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Projects Tab -->
                            
                            <!-- Task Tab -->
                            <div id="tasks" class="tab-pane fade">
                                <div class="project-task">
                                    <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                                        <li class="nav-item"><a class="nav-link active" href="#all_tasks" data-bs-toggle="tab" aria-expanded="true">All Tasks</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#pending_tasks" data-bs-toggle="tab" aria-expanded="false">Pending Tasks</a></li>
                                        <li class="nav-item"><a class="nav-link" href="#completed_tasks" data-bs-toggle="tab" aria-expanded="false">Completed Tasks</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane show active" id="all_tasks">
                                            <div class="task-wrapper">
                                                <div class="task-list-container">
                                                    <div class="task-list-body">
                                                        <ul id="task-list">
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="completed task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label">Doctor available module</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Private chat module</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient Profile add</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="task-list-footer">
                                                        <div class="new-task-wrapper">
                                                            <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                                                            <span class="error-message hidden">You need to enter a task first</span>
                                                            <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                                                            <span class="btn" id="close-task-panel">Close</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="pending_tasks">
                                            <div class="task-wrapper">
                                                <div class="task-list-container">
                                                    <div class="task-list-body">
                                                        <ul class="task-lists" id="task-list">
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Private chat module</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient Profile add</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="task-list-footer">
                                                        <div class="new-task-wrapper">
                                                            <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                                                            <span class="error-message hidden">You need to enter a task first</span>
                                                            <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                                                            <span class="btn" id="close-task-panel">Close</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="completed_tasks">
                                            <div class="task-wrapper">
                                                <div class="task-list-container">
                                                    <div class="task-list-body">
                                                        <ul class="completes-task-list" id="task-list">
                                                            <li class="completed task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="completed task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="completed task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li class="completed task">
                                                                <div class="task-container">
                                                                    <span class="task-action-btn task-check">
                                                                        <span class="action-circle large complete-btn" title="Mark Complete">
                                                                            <i class="material-icons">check</i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="task-label" contenteditable="true">Private chat module</span>
                                                                    <span class="task-action-btn task-btn-right">
                                                                        <span class="action-circle large" title="Assign">
                                                                            <i class="material-icons">person_add</i>
                                                                        </span>
                                                                        <span class="action-circle large delete-btn" title="Delete Task">
                                                                            <i class="material-icons">delete</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="task-list-footer">
                                                        <div class="new-task-wrapper">
                                                            <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                                                            <span class="error-message hidden">You need to enter a task first</span>
                                                            <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                                                            <span class="btn" id="close-task-panel">Close</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Task Tab -->
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Page Content -->
            
        </div>
  
</template>

<script>
// import $ from "jquery";
import axios from "axios";
import Constant from "@/other/Constant";

export default {
  data() {
    return {
      errors: {},
      profile: {},
      logourl: "",
      basic_information:{},
      priceplan:""
      

  
    };
  },
  methods: {

    getProfile(uuid) {
      if (uuid) {
        axios
          .get("superadmin/companies/profile/" + uuid)
          .then((response) => {
            if (response) {
              this.profile = response.data.company;
              this.priceplan=this.profile.price.price.name
              if (this.profile.basic_information != null) {
                this.basic_information = JSON.parse(
                  this.profile.basic_information
                );
              }
              if (this.profile.logo != null) {
                this.logourl =Constant.filebaseurl+ this.profile.logo.file.path;
              }

              if (this.profile.authorised_person_details != null) {
                this.auth_person = JSON.parse(
                  this.profile.authorised_person_details
                );
              }
              if (this.profile.company_address != null) {
        
                this.company_address = JSON.parse(
                  this.profile.company_address
                );
              }
              if (this.profile.trading_hour != null) {
        
                        this.company_hour = JSON.parse(
                        this.profile.trading_hour
        );
      }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted: function () {
    this.uuid = this.$route.query.uuid;
    if(this.uuid){
        this.getProfile(this.uuid);

        
    }
  },
};
</script>