<template>
  <div class="wrapper">
    <div id="add_schedule" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Schedule</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option value="">Development</option>
                      <option value="1">Finance</option>
                      <option value="2">Finance and Management</option>
                      <option value="3">Hr & Finance</option>
                      <option value="4">ITech</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Employee Name <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option value="1">Sabuj Ahmed</option>
                      <option value="2">Assaduzzaman</option>
                      <option value="3">Pankaz Debnath</option>
                      <option value="4">Ejaz Wahid</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Shifts <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option value="1">10'o clock Shift</option>
                      <option value="2">10:30 shift</option>
                      <option value="3">Daily Shift</option>
                      <option value="4">New Shift</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Min Start Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Start Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Max Start Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Min End Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >End Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Max End Time <span class="text-danger">*</span></label
                    >
                    <div class="input-group time timepicker">
                      <input class="form-control" /><span
                        class="input-group-text"
                        ><i class="far fa-clock"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Break Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Accept Extra Hours </label>
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="customSwitch1"
                        checked=""
                      />
                      <label
                        class="form-check-label"
                        for="customSwitch1"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Publish </label>
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="customSwitch2"
                        checked=""
                      />
                      <label
                        class="form-check-label"
                        for="customSwitch2"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Schedule Modal -->

    <!-- Edit Schedule Modal -->
    <div id="edit_schedule" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Schedule</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option selected value="">Development</option>
                      <option value="1">Finance</option>
                      <option value="2">Finance and Management</option>
                      <option value="3">Hr & Finance</option>
                      <option value="4">ITech</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Employee Name <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option selected value="1">Sabuj Ahmed</option>
                      <option value="2">Assaduzzaman</option>
                      <option value="3">Pankaz Debnath</option>
                      <option value="4">Ejaz Wahid</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Shifts <span class="text-danger">*</span></label
                    >
                    <select class="select">
                      <option value="">Select</option>
                      <option value="1">10'o clock Shift</option>
                      <option value="2">10:30 shift</option>
                      <option value="3">Daily Shift</option>
                      <option selected value="4">New Shift</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Min Start Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="06:11 am" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Start Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="06:30 am" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Max Start Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="08:12 am" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Min End Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="09:12 pm" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >End Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="09:30 pm" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Max End Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="09:45 pm" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Break Time <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="45" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="custom-control form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="customCheck1"
                    />
                    <label class="form-check-label" for="customCheck1"
                      >Recurring Shift</label
                    >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Repeat Every</label>
                    <select class="select">
                      <option value="">1</option>
                      <option value="1">2</option>
                      <option value="2">3</option>
                      <option value="3">4</option>
                      <option selected value="4">5</option>
                      <option value="3">6</option>
                    </select>
                    <label class="col-form-label">Week(s)</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group wday-box">
                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="monday"
                        class="days recurring"
                        checked=""
                        onclick="return false;"
                      /><span class="checkmark">M</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="tuesday"
                        class="days recurring"
                        checked=""
                        onclick="return false;"
                      /><span class="checkmark">T</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="wednesday"
                        class="days recurring"
                        checked=""
                        onclick="return false;"
                      /><span class="checkmark">W</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="thursday"
                        class="days recurring"
                        checked=""
                        onclick="return false;"
                      /><span class="checkmark">T</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="friday"
                        class="days recurring"
                        checked=""
                        onclick="return false;"
                      /><span class="checkmark">F</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="saturday"
                        class="days recurring"
                        onclick="return false;"
                      /><span class="checkmark">S</span></label
                    >

                    <label class="checkbox-inline"
                      ><input
                        type="checkbox"
                        name="week_days[]"
                        value="sunday"
                        class="days recurring"
                        onclick="return false;"
                      /><span class="checkmark">S</span></label
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >End On <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="custom-control form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="customCheck2"
                    />
                    <label class="form-check-label" for="customCheck2"
                      >Indefinite</label
                    >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Accept Extra Hours </label>
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="customSwitch3"
                        checked=""
                      />
                      <label
                        class="form-check-label"
                        for="customSwitch3"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Publish </label>
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="customSwitch4"
                        checked=""
                      />
                      <label
                        class="form-check-label"
                        for="customSwitch4"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Daily Scheduling</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Daily Scheduling
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Content Starts -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-sm-6 col-md-2">
          <div class="form-group form-focus select-focus">
            <select class="form-select" style="height: 50px">
              <option>All Department</option>
              <option value="1">Finance</option>
              <option value="2">Finance and Management</option>
              <option value="3">Hr & Finance</option>
              <option value="4">ITech</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="form-group form-focus focused">
            <div class="cal-icon">
              <input class="form-control floating datetimepicker" type="text" />
            </div>
            <label class="focus-label">From</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="form-group form-focus focused">
            <div class="cal-icon">
              <input class="form-control floating datetimepicker" type="text" />
            </div>
            <label class="focus-label">To</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <a href="#" class="btn btn-success btn-search"
            ><i class="fas fa-search me-2"></i> Search
          </a>
        </div>
        <div class="col-sm-6 col-md-2">
          <a
            href="#"
            class="btn btn-success btn-add-emp w-100"
            data-bs-toggle="modal"
            data-bs-target="#add_schedule"
          >
            Assign Shifts</a
          >
        </div>
        <div class="col-sm-6 col-md-2">
          <a href="shift_list" class="btn btn-success btn-add-emp w-100"
            >Shifts</a
          >
        </div>
      </div>
      <!-- Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table datatable">
              <thead>
                <tr>
                  <th>Scheduled Shift</th>
                  <th>Fri 21</th>
                  <th>Sat 22</th>
                  <th>Sun 23</th>
                  <th>Mon 24</th>
                  <th>Tue 25</th>
                  <th>Wed 26</th>
                  <th>Thu 27</th>
                  <th>Fri 28</th>
                  <th>Sat 29</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                      /></a>
                      <a href="profile"
                        >Sarwar Jahan Morshed <span>Web Designer</span></a
                      >
                    </h2>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                      /></a>
                      <a href="profile"
                        >Sabuj Ahmed <span>Web Developer</span></a
                      >
                    </h2>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                      /></a>
                      <a href="profile"
                        >Assaduzzaman <span>Android Developer</span></a
                      >
                    </h2>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                      /></a>
                      <a href="profile"
                        >Pankaz Debnath <span>IOS Developer</span></a
                      >
                    </h2>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                      /></a>
                      <a href="profile">Ejaz Wahid <span>Team Leader</span></a>
                    </h2>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                      /></a>
                      <a href="profile"
                        >Pankaz Debnath <span>Web Developer</span></a
                      >
                    </h2>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>

                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                      /></a>
                      <a href="profile"
                        >Safwan Chowdhory <span>Web Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <h2>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_schedule"
                          style="border: 2px dashed #b8b8b8"
                        >
                          <span class="username-info m-b-10"
                            >6:30 am - 9:30 pm ( 14 hrs 15 mins)</span
                          >
                          <span class="userrole-info"
                            >Web Designer - SMARTHR</span
                          >
                        </a>
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="user-add-shedule-list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_schedule"
                      >
                        <span><i class="fa fa-plus"></i></span>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /Content End -->
    </div>
    <!-- /Page Content -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
