<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Provident Fund</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Provident Fund
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_pf"
              ><i class="fas fa-plus"></i> Add Provident Fund</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="weekends" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Employee Id</th>
                <th>Employee Name</th>
                <th>Department</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table datatable mb-0">
              <thead>
                <tr>
                  <th>Employee Id</th>
                  <th>Employee Name</th>
                  <th>Department</th>
                  <th>Status</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <p class="text-bold">1001</p>
                    </h2>
                  </td>
                  <td>Sarwar Jahan Morshed</td>
                  <td>Software Development</td>
                  <td>
                    <span class="badge badge-success">Assigned</span>
                  </td>

                  <td class="ico-sec">
                    <a
                      href="#"
                      
                      data-bs-toggle="modal"
                      data-bs-target="#addModal"
                    >
                      <i class="fas fa-eye"></i>
                    </a>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
    </div>
    <!-- /Page Content -->

    <!-- Add PF Modal -->
    <div id="add_pf" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Provident Fund</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Department<span class="text-danger">*</span></label>
                    <select class="form-control form-select">
                      <option>Select Department</option>
                      <option>sales</option>
                      <option>Marketing</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Employee Name</label>
                    <select class="form-control form-select">
                      <option value="3">Sarwar Jahan Morshed (FT-0001)</option>
                      <option value="23">Sabuj Ahmed (FT-0002)</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Job Age</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Activation Date</label>
                    <input class="form-control" type="date" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Assign PF Rule</label>
                    <select class="form-control form-select">
                      <option value="3">something 1</option>
                      <option value="23">something 2</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee Address</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee contact number</label>
                    <input class="form-control" type="number" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee NID</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Upload Nominee NID</label>
                    <input class="form-control" type="file" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Upload Nominee Photo</label>
                    <input class="form-control" type="file" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Assign</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add PF Modal -->

    <!-- Edit PF Modal -->
    <div id="edit_pf" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Provident Fund</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Department<span class="text-danger">*</span></label>
                    <select class="form-control form-select">
                      <option>Select Department</option>
                      <option>sales</option>
                      <option>Marketing</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Employee Name</label>
                    <select class="form-control form-select">
                      <option value="3">Sarwar Jahan Morshed (FT-0001)</option>
                      <option value="23">Sabuj Ahmed (FT-0002)</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Job Age</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Activation Date</label>
                    <input class="form-control" type="date" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Assign PF Rule</label>
                    <select class="form-control form-select">
                      <option value="3">something 1</option>
                      <option value="23">something 2</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee Address</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee contact number</label>
                    <input class="form-control" type="number" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Nominee NID</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Upload Nominee NID</label>
                    <input class="form-control" type="file" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Upload Nominee Photo</label>
                    <input class="form-control" type="file" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit PF Modal -->

    <!-- view Modal -->

    <div id="viewmodal" class="modal custom-modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Provident Fund Details</h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body p-5">
        <!-- First Row -->
        <div class="row">
          <div class="col-sm-7">
            <p><strong>Employee Name:</strong> Sarwar Jahan Morshed</p>
          </div>
          <div class="col-sm-5">
            <p><strong>Activation Date:</strong> 2024-08-21</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-7">
            <p><strong>Department:</strong> Sales</p>
          </div>
          <div class="col-sm-5">
            <p><strong>Job Age:</strong> 5 Years</p>
          </div>
        </div>

        <!-- Second Row -->
        <div class="row" style="border-bottom: 1px solid #e9ecef; padding-bottom: 10px;">
          <div class="col-sm-12">
            <p><strong>Assigned PF Rule:</strong> something 1</p>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-7">
            <p><strong>Nominee Name:</strong> Sarwar Jahan Morshed</p>
          </div>
          <div class="col-sm-5">
            <p><strong>Nominee Number:</strong> +8801234567890</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-7">
            <p><strong>Nominee Address:</strong>  1234567890123</p>
          </div>
          <div class="col-sm-5">
            <p><strong>Nominee NID:</strong> 1234567890123</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-7">
            <p>
              <strong>Nominee NID Document:</strong>
              <a href="#" class="btn btn-link">View Document</a>
            </p>
          </div>
          <div class="col-sm-5">
            <p>
              <strong>Nominee Photo:</strong>
              <a href="#" class="btn btn-link">View Photo</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    <!-- /view Modal -->

    <!-- Delete PF Modal -->
    <div class="modal custom-modal fade" id="delete_pf" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Provident Fund</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete PF Modal -->
  </div>
</template>

<script>
import axios from "axios";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            this.weekends = [];

            var action =
              "<a href='#'  data-bs-toggle='modal' data-bs-target='#edit_pf'><i class='fas fa-pen'></i></a> <a href='#'  data-bs-toggle='modal' data-bs-target='#viewmodal'><i class='ms-3 fas fa-eye'></i></a>  ";

            this.weekends.push(
              ["FC1001", "Abu Saeed Sabuj", "IT", "Assigned", action],
              ["FC1001", "Abu Saeed Sabuj", "IT", "Assigned", action]
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
