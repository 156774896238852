<template>

    <div>

        <TopbarView/>
        <sidebar-view/>
        <router-view/>


    </div>
    
  
</template>

<script>
import TopbarView from '@/components/admin/partial/TopbarView.vue'
import SidebarView from '@/components/sidebar/AdminSidebarView.vue'
export default {
components:{
    TopbarView,
    SidebarView
},

}
</script>

<style>

</style>