<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4 ">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Employee</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                 <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Payslip
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  
  
                  <input type="text" class="form-control" placeholder="Employee ID" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  
  
                  <input type="text" class="form-control" placeholder="Employee Name" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0 ">
                  <select class="form-control form-select">
                    <option>Select Position</option>
                    <option>Global Technologies</option>
                    <option>Delta Infotech</option>
                  </select>
            
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
              
                  <i class="fas fa-search mt-3 secondary_color "></i> 
                
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-download"></i> Download </a
              >
            </div>
          </div>
        </div>

        <div id="paylist" style="width: 600px; padding: 20px;position: absolute;top:  -850px;" >


          <table class="w-100 table-bordered" >
        <tr>
          <th class="p-2" > SL</th>

          <th class="p-2" >Employee name</th>
          <th class="p-2">Position</th>
          <th class="p-2">Account  Number</th>
          <th class="p-2" >Payable</th>
        </tr>
        <tr v-for="payslip in payslips" :key="payslip.id" >
          <td class="p-2" >{{ payslip.id }}</td>

          <td class="p-2" >{{ JSON.parse(payslip.payslip_info).employee_name }}</td>
          <td class="p-2" >{{ JSON.parse(payslip.payslip_info).position }}</td>


          <td class="p-2" >23242343432</td>



          <td class="p-2" >{{JSON.parse(payslip.payslip_info).total_earing - JSON.parse(payslip.payslip_info).totaldeduction +parseInt(JSON.parse(payslip.payslip_info).total_other)}}</td>


         
        </tr>
       </table>


        </div>
      <!-- /Search Filter -->
      

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="employees" class="table table-striped">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Employee ID</th>
                <th>Email</th>
                <th>Join date</th>
                <th>Position</th>
                <th>Basic</th>
                <th>Payslip</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Download</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>

              <div class="row">

                <div class="col-lg-6 col-md-3">
                <div class="form-group mb0 ">
                  <select v-model="selected_month" class="form-control form-select">
                    <option disabled value="null">Select a month</option>
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>

                    
                  



                  </select>
            
                </div>
              </div>

              <div class="col-lg-6 col-md-3">
                <div class="form-group mb0 ">
                  <select v-model="selected_year" class="form-control form-select">
                    <option disabled value="null">Select a year</option>
                    <option>2024</option>
                    <option>2023</option>
                    <option>2022</option>
                    <option >2021</option>
                    <option>2020</option>


             
                  </select>
            
                </div>
              </div>


              </div>
             
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="download" class="btn btn-primary submit-btn">Download</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
    
    <script>
import { Validator } from "@/other/Validator";
import $ from "jquery";

import axios from "axios";
import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import Api from "@/other/Api";
import jsPDF from "jspdf";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      employees: [],
      educations: [],
      departments: [],
      positions: [],
      newpostiions: [],
      levels: [],
      works: [],
      cers: [],
      tras: [],
      errors: "",
      department_id: null,
      position_id: null,
      level_id: null,
      assigned_under: null,
      grades: [],
      grade_id: null,
      selected_year:null,
      selected_month:null,
      payslips:""
    };
  },

  methods: {
    getemployees() {
      axios
        .get(Api.company.emoloyee_list)
        .then((response) => {
          if (response) {
            var employees = response.data.data;

            for (var employee of employees) {
              var logourl =
                employee.logo != null
                  ? Constant.filebaseurl + employee.logo.file.path
                  : "";
              var employee_id = employee.e_id;
              var uuid = employee.uuid;
              var employee_name =
                '<h2 class="table-avatar"> <a href="clients_profile" class="avatar"><img src="' +
                logourl +
                '" style="width: 70px !important;" alt=""></a> </h2>' +
                JSON.parse(employee.basic_information).first_name +
                JSON.parse(employee.basic_information).last_name;
              var email = JSON.parse(employee.basic_information).email;
              var joindate = "1-1-2024";
              var basic =
                employee.grade.payroll != null
                  ? employee.grade.payroll.basic
                  : "Unassigned";

              var position =
                employee.position != null
                  ? employee.position.position_name
                  : "Unassigned";
              position =
                '<span class="role-info role-bg-one">' + position + "</span>";

              var payslip =
              "   <a href='#' onclick='show_payslip(" +
                '"' +
                uuid +
                '"' +
                ")'  ><span class='role-info role-bg-two'>Payslips</span></a>";
              var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")'  ><i class=' ms-3 far fa-eye'></i></a>";

              this.employees.push([
                employee_name,
                employee_id,
                email,
                joindate,
                position,
                basic,
                payslip,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download() {
      axios.get("company/payslip/"+this.selected_month+"/"+this.selected_year).then((response) => {

        if(response){
          this.payslips=response.data.data

          var pdf2 = new jsPDF("a4", "pt", "a4");
          
      pdf2.html(document.getElementById("paylist")).then(() => {
        pdf2.save("payslip_list");
      }); 

        }

      }).catch((error) => {
        console.log(error);
      });
    },

    handleClick(uuid) {
      this.$router.push({
        name: "company-employee-profile",
        query: { uuid: uuid },
      });
    },
    show_payslip(uuid) {
      this.$router.push({
        name: "payslip-list",
        query: { uuid: uuid },
      });
    },

    addeducation() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "degree", value: this.degree, type: "required" },
        { field: "field", value: this.field, type: "required" },
        { field: "institute", value: this.institute, type: "required" },
        { field: "passing", value: this.passing, type: "required" },
        { field: "result", value: this.result, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var education = {
          degree: this.degree,
          field: this.field,
          institute: this.institute,
          passing: this.passing,
          result: this.result,
        };
        this.educations.push(education);
      }
    },
    addwork() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "cname", value: this.cname, type: "required" },
        { field: "caddress", value: this.caddress, type: "required" },
        { field: "designation", value: this.designation, type: "required" },
        { field: "workfrom", value: this.workfrom, type: "required" },
        { field: "worktill", value: this.worktill, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var work = {
          cname: this.cname,
          caddress: this.caddress,
          designation: this.designation,
          workfrom: this.workfrom,
          worktill: this.worktill,
          responsiblity: this.responsiblity,
        };
        this.works.push(work);
      }
    },
    addcer() {
      var cer = {
        cername: this.cername,
        cerissuedate: this.cerissuedate,
        cerdetails: this.cerdetails,
      };
      this.cers.push(cer);
    },
    addt() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "tname", value: this.tname, type: "required" },
        { field: "torg", value: this.torg, type: "required" },
        { field: "tfrom", value: this.tfrom, type: "required" },
        { field: "ttill", value: this.ttill, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var tra = {
          tname: this.tname,
          torg: this.torg,
          tfrom: this.tfrom,
          ttill: this.ttill,
        };
        this.tras.push(tra);
      }
    },

    addbasicinfo() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "first_name", value: this.first_name, type: "required" },
        { field: "father_name", value: this.father_name, type: "required" },
        { field: "email", value: this.email, type: "required|email" },
        { field: "dob", value: this.dob, type: "required" },
        {
          field: "contact_number",
          value: this.contact_number,
          type: "required",
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        var basic_info = {
          first_name: this.first_name,
          middle_name: this.middle_name,
          last_name: this.last_name,
          father_name: this.father_name,
          mother_name: this.mother_name,
          email: this.email,
          dob: this.dob,
          contact_number: this.contact_number,
          logo: "",
        };

        axios
          .post("/company/employees", {
            basic_information: JSON.stringify(basic_info),
            company_department_id: this.department_id,
            position_id: this.position_id,
            grade_id: this.grade_id,
            assigned_under: this.assigned_under,
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
              this.getemployees();
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    addeducationall() {
      if (this.educations.length == 0) {
        alert("Add education first");
      } else {
        axios
          .post("/company/employees", {
            educations: JSON.stringify(this.educations),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    addworkall() {
      if (this.works.length == 0) {
        alert("Add a work first");
      } else {
        axios
          .put("/employee/profiles/3", {
            user_id: "3",
            expreriences: JSON.stringify(this.works),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            this.departments = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getposition() {
      axios
        .get("/company/positions")
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    departmentpostions() {
      axios
        .get("/company/positions/department/" + this.department_id)
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    levelpostions() {
      let level = this.levels.find((el) => el.id === this.level_id);

      var level_name = level.level_name;
      const myArray = level_name.split(" ");
      var levelid = myArray[1] - 1;

      if (levelid > 0) {
        var newlevelname = "Level " + levelid;

        var newlevel = this.levels.find((el) => el.level_name === newlevelname);
        alert(newlevel);
        this.newpostiions = [];
        for (let i = 0; i < this.positions.length; i++) {
          if (this.positions[i].level_id == newlevel.id) {
            this.newpostiions.push(this.positions[i]);
          }
        }
      } else {
        this.newpostiions = [];
      }
    },
    getlevel() {
      axios
        .get("/company/levels")
        .then((response) => {
          if (response) {
            this.levels = response.data.data;

            let position = this.positions.find(
              (el) => el.id === this.position_id
            );

            var level = this.levels.find((el) => el.id === position.level_id);

            var level_name = level.level_name;
            const myArray = level_name.split(" ");
            var levelid = myArray[1] - 1;

            if (levelid > 0) {
              var newlevelname = "Level " + levelid;

              var newlevel = this.levels.find(
                (el) => el.level_name === newlevelname
              );

              this.newpostiions = [];
              for (let i = 0; i < this.positions.length; i++) {
                if (this.positions[i].level_id == newlevel.id) {
                  this.newpostiions.push(this.positions[i]);
                }
              }
            } else {
              this.newpostiions = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addtraall() {
      if (this.tras.length == 0) {
        alert("Add a tranig first");
      } else {
        axios
          .put("/employee/profiles/3", {
            user_id: "3",
            tranings: JSON.stringify(this.tras),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    addvisa() {
      var validator = new Validator();
      var error = validator.validated([
        {
          field: "orgine_country",
          value: this.orgine_country,
          type: "required",
        },
        { field: "passport_no", value: this.passport_no, type: "required" },
        {
          field: "visa_issue_date",
          value: this.visa_issue_date,
          type: "required",
        },
        {
          field: "visa_expri_date",
          value: this.visa_expri_date,
          type: "required",
        },
        {
          field: "visa_issue_in_country",
          value: this.visa_issue_in_country,
          type: "required",
        },
        { field: "visa_category", value: this.visa_category, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var visa = {
          orgine_country: this.orgine_country,
          passport_no: this.passport_no,
          reference_number: this.reference_number,
          visa_issue_date: this.visa_issue_date,
          visa_expri_date: this.visa_expri_date,
          visa_issue_in_country: this.visa_issue_in_country,
          visa_category: this.visa_category,
          logo: "",
        };

        axios
          .put("/employee/profiles/3", {
            user_id: "3",
            visas: JSON.stringify(visa),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
    getgrades() {
      axios
        .get("/company/grades")
        .then((response) => {
          if (response) {
            this.grades = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    this.getemployees();
    this.getdepartment();
    this.getgrades();

    window.handleClick = this.handleClick;
    window.show_payslip = this.show_payslip;
  },
};
</script>
    
    
          
       <style>
@import "datatables.net-bs5";
</style>