<template>


<nav  class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">

       
    



      <li class="nav-item">
        <a class="nav-link" id="pushbtn" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>

      <div class="dropdown">
  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Employee
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li>  <router-link class="dropdown-item" :to="{ name: 'company-employee' }">
            Employees
          </router-link></li>
    



        
  </ul>
</div>
     
      <div class="dropdown">
  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Attendence
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li> <router-link class="dropdown-item" :to="{ name: 'company-employee-attendance' }">
            Emoloyee Attendance
          </router-link>
     </li>
  
  </ul>
</div>
     
      <div class="dropdown">
  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Leave
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">  <router-link class="dropdown-item" :to="{ name: 'company-leave-application' }">
            Employee leaves
          </router-link></a></li>

  </ul>
</div>
      <div class="dropdown">
  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Job Demand
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li> <router-link class="dropdown-item" :to="{ name: 'company-job-application' }">
            Job Demands
          </router-link>
        </li>

  </ul>
</div>
     
     
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Help</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a id="date" class="nav-link "> Sunday, 12-10-2023</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a id="clock" class="nav-link sc clock">  12:45 PM</a>
      </li>

    </ul>

   
  
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <!-- <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li> -->

     
      <!-- Messages Dropdown Menu -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item">
            
            <div class="media">
             
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            
            <div class="media">
             
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            
            <div class="media">
              
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                  <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
        </div>
      </li> -->
      <!-- Notifications Dropdown Menu -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li> -->
      

      <div class="btn-group ">
        <a class="nav-link " data-bs-toggle="dropdown" href="#">
          <img src="/assets/img/user.jpg" style="height: 40px;width: 40px;margin-top: -10px;border-radius: 50%;">
          <!-- <span class="badge badge-warning navbar-badge">15</span> -->
        </a>
        <div class="dropdown-menu dropdown-menu-end  ">
          <!-- <span class="dropdown-item dropdown-header">15 Notifications</span> -->
          <!-- <a href="#" class="dropdown-item">
            <i class="fas fa-user mr-2"></i> Profile
            
          </a> -->

          <router-link class="dropdown-item" :to="{name: 'company-profile'}"><i class="fas fa-user mr-2"></i>Profile</router-link>





          <a  data-bs-toggle="modal" data-bs-target="#addModal77" href="change_password.php" class="dropdown-item">
              <i class="fas fa-key mr-2"></i> Change Password
          </a>


          

          <a @click.prevent="logout" class="dropdown-item">
             <i class="fas fa-sign-out-alt mr-2"></i> Logout
          </a>

        
          <!-- <a href="https://hrm.nit-bd.com/login" class="dropdown-item">
            <i class="fa-solid fa-arrow-right-from-bracket mr-2"></i> Logout
            
          </a> -->

          <div class="dropdown-divider"></div>
        
        
        </div>

        
</div>

    </ul>

    <div class="cprogress">
      <div class="progress-stick" ></div>
    
    </div>


 
  </nav>



   <!-- Add Client Modal -->
   <div id="addModal77" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change Password</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
           
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Old Password <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"  
                      class="form-control"
                      v-model="oldpassword"
                      @input="errors.oldpassword=null"
                    />
                    <p class="text-danger mt-1" v-if="errors.oldpassword" >{{ errors.oldpassword[0] }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >New Password <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      min="0"
                      class="form-control"

                      v-model="newpassword"
                      @input="errors.newpassword=null"
                    />
                    <p class="text-danger mt-1" v-if="errors.newpassword" >{{ errors.newpassword[0] }}</p>
                  
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  @click.prevent="changepassword"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->
 
</template>

<script>
     // import $ from "jquery";
     import axios from "axios";
     import Api from "@/other/Api";

     export default {
       data() {
         return {

           errors: {},
           message:""
      
         };
       },
       methods: {
       
         changepassword() {
           axios
             .post(Api.company.change_password,{
               oldpassword: this.oldpassword,
               newpassword: this.newpassword,
              
             })
             .then((response) => {
               if (response) {
        
     
                this.oldpassword="";
                this.newpassword="";

                this.$emit("get_message", response.data.message);

                
                  
               }
             })
             .catch((error) => {
               this.errors = error.response.data.errors;
               this.message = error.response.data.message;
             });
         },
         logout(){

          const user = JSON.parse(localStorage.getItem("user"));

          if(user){
            localStorage.removeItem("user");

            this.$router.push({ name: "login" });

          }





         }
       
    
       
       },
       mounted: function () {
   
       },
     };
     </script>

<style>
</style>