<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Welcome Fox Technologies</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <router-link :to="{ name: 'company-dashboard' }">
                  <li class="breadcrumb-item">
                    <a href="dashboard">Dashboard</a>
                  </li>
                </router-link>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="text-center w-100 p-3">
                <h3 class="bl-text mb-1">
                  {{ dashboard_count.employee_count }}
                </h3>
                <h2>Employees</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="text-center w-100 p-3">
                <h3 class="bl-text mb-1">
                  {{ dashboard_count.department_count }}
                </h3>
                <h2>Departments</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="text-center w-100 p-3">
                <h3 class="bl-text mb-1">
                  {{ dashboard_count.position_count }}
                </h3>
                <h2>Positions</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="text-center w-100 p-3">
                <h3 class="bl-text mb-1">
                  {{ dashboard_count.jobdemand_count }}
                </h3>
                <h2>Job Demands</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-7 d-flex">
          <div class="card card-table flex-fill">
            <div class="card-header">
              <h3 class="card-title mb-0">Toadays Employee Attendance</h3>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-nowrap custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Department</th>
                      <th>Punch In</th>
                      <th>Punch Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="employee in employees.slice(0, 5)"
                      :key="employee"
                    >
                      <td>
                        <a href="invoice_view">{{ employee.e_id }}</a>
                      </td>
                      <td>
                        <h2>
                          <a href="#"
                            >{{
                              JSON.parse(employee.basic_information).first_name
                            }}
                            {{
                              JSON.parse(employee.basic_information).last_name
                            }}</a
                          >
                        </h2>
                      </td>
                      <td>{{ employee.position.position_name }}</td>
                      <td>{{ employee.company_department.department_name }}</td>
                      <td
                        v-if="employee.employee_attendance_activity.length > 0"
                      >
                        <span
                          v-if="
                            JSON.parse(
                              employee.employee_attendance_activity[0]
                                .activities
                            ).find((a) => a.Date == currentDate)
                          "
                        >
                          {{
                            JSON.parse(
                              employee.employee_attendance_activity[0]
                                .activities
                            )
                              .find((a) => a.Date == currentDate)
                              .punchin.pop()
                          }}
                        </span>
                      </td>

                      <td
                        v-if="employee.employee_attendance_activity.length > 0"
                      >
                        <span
                          v-if="
                            JSON.parse(
                              employee.employee_attendance_activity[0]
                                .activities
                            ).find(
                              (a) =>
                                a.Date == currentDate && a.punchout.length > 0
                            )
                          "
                        >
                          {{
                            JSON.parse(
                              employee.employee_attendance_activity[0]
                                .activities
                            )
                              .find((a) => a.Date == currentDate)
                              .punchout.pop()
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <a href="invoices.html">View all </a>
            </div>
          </div>
        </div>
        <div class="col-md-5 d-flex">
          <div class="card card-table flex-fill">
            <div class="card-header">
              <h3 class="card-title mb-0">Leave Applications</h3>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table custom-table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="leave in leaves.slice(0, 5)" :key="leave">
                      <td>
                        <a href="invoice_view">{{ leave.employee.e_id }}</a>
                      </td>
                      <td>
                        <h2>
                          <a href="#"
                            >{{
                              JSON.parse(leave.employee.basic_information)
                                .first_name
                            }}
                            {{
                              JSON.parse(leave.employee.basic_information)
                                .last_name
                            }}</a
                          >
                        </h2>
                      </td>
                      <td>{{ leave.from }}</td>
                      <td>{{ leave.to }}</td>
                      <td v-if="leave.status == '1'">Approved</td>
                      <td v-else>Pending</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <a href="payments">View all payments</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row jb-dashboard">
            <div class="col-md-5 w-100 text-center">
              <div class="card height-100 mb-5 flex-fill">
                <div class="card-header d-flex justify-content-between">
                  <h3 class="card-title">Department Demographics</h3>

                  <div class="dropdown text-right">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Select Department
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a class="dropdown-item" href="#">Last 6 months</a>
                      <a class="dropdown-item" href="#">Last 3 months</a>
                      <a class="dropdown-item" href="#">Last 1 months</a>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <p class="text-left">Top 3 Department</p>

                      <div id="softwareChart"></div>
                    </div>

                    <div class="col-lg-6">
                      <p class="text-left">Top 10 Positions</p>

                      <div
                        v-for="topten in top_ten_positions"
                        :key="topten"
                        class="dash-position-container"
                      >
                        <p>{{ topten.id }}. {{ topten.position_name }}</p>

                        <div>
                          <div class="dash-position-vacancy">
                            {{ topten.total_employee }}
                          </div>
                          <div class="dash-position-requied">
                            {{ topten.required }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div id="line-charts"></div> -->
                </div>
              </div>
            </div>

            <div class="col-md-7 text-center">
              <div class="card height-100 flex-fill">
                <div class="card-header">
                  <h3 class="card-title">Employee Demographics</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4 border-right">
                      <p class="text-left">Gender</p>
                      <div id="maleChart"></div>

                      <div id="femaleChart"></div>
                    </div>

                    <div class="col-lg-4 border-right ps-3">
                      <p class="text-left">Age</p>

                      <div id="age-chart"></div>
                    </div>

                    <div class="col-lg-4 ps-3">
                      <p class="text-left">Education</p>

                      <div id="edu-chart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <div class="row jb-dashboard">
            <div class="col-md-6 text-center">
              <div class="card flex-fill">
                <div class="card-header">
                  <h3 class="card-title">Employee Demographics</h3>
                </div>
                <div class="card-body">
                  <div id="bar-charts"></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="card flex-fill">
                <div class="card-header">
                  <h3 class="card-title">Sales Overview</h3>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Last 6 months
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a class="dropdown-item" href="#">Last 6 months</a>
                      <a class="dropdown-item" href="#">Last 3 months</a>
                      <a class="dropdown-item" href="#">Last 1 months</a>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div id="line-charts"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3 d-flex">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="">
                <h2>New Employees</h2>
                <h3 class="bl-text">112</h3>
              </div>
              <h6>+10%</h6>
            </div>
            <div class="" id="newTicketChart"></div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="">
                <h2>Earnings</h2>
                <h3 class="org-text">$1,42,300</h3>
              </div>
              <h6>+10%</h6>
            </div>
            <div class="" id="solvedTicketChart"></div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="">
                <h2>Expenses</h2>
                <h3 class="red-text">$8,500</h3>
              </div>
              <h6>+10%</h6>
            </div>
            <div class="" id="openTicketChart"></div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex">
          <div class="card flex-fill tickets-card">
            <div class="card-header">
              <div class="">
                <h2>Profit</h2>
                <h3 class="grn-text">$1,12,000</h3>
              </div>
              <h6 class="red-text">-10%</h6>
            </div>
            <div class="" id="pendingTicketChart"></div>
          </div>
        </div>
      </div>

      <!-- Statistics Widget -->
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-5 d-flex">
          <div class="card project-card flex-fill">
            <h4>Statistics</h4>
            <div class="row">
              <div class="col-md-6">
                <div class="card st-card st-c1">
                  <div class="stats-info">
                    <div class="text-start">
                      <p>Today</p>
                      <h5>3.45 / 8 hrs</h5>
                    </div>

                    <div class="progress">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        style="width: 31%"
                        aria-valuenow="31"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card st-card st-c2">
                  <div class="stats-info">
                    <div class="text-start">
                      <p>This Week</p>
                      <h5>3.45 / 8 hrs</h5>
                    </div>

                    <div class="progress">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        style="width: 47%"
                        aria-valuenow="47"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card st-card st-c3">
                  <div class="stats-info">
                    <div class="text-start">
                      <p>This Month</p>
                      <h5>3.45 / 8 hrs</h5>
                    </div>

                    <div class="progress">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        style="width: 87%"
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card st-card st-c4">
                  <div class="stats-info">
                    <div class="text-start">
                      <p>Remaining</p>
                      <h5>3.45 / 8 hrs</h5>
                    </div>

                    <div class="progress">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        style="width: 87%"
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xl-7 d-flex">
          <div class="card project-card flex-fill">
            <h4>Projects</h4>
            <div class="row">
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
                <div id="radialBarChart"></div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="task-box color-one">
                      <div class="task-media">
                        <div class="task-icon">
                          <img
                            src="/assets/img/icons/icon-01.png"
                            alt="Icons"
                          />
                        </div>
                        <div class="task-info">
                          <h5>Pending Tasks</h5>
                          <h2>55</h2>
                        </div>
                      </div>

                      <div class="task-redirect">
                        <div class="redirect-icon">
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <div class="task-box color-two">
                      <div class="task-media">
                        <div class="task-icon">
                          <img
                            src="/assets/img/icons/icon-02.png"
                            alt="Icons"
                          />
                        </div>
                        <div class="task-info">
                          <h5>Completed Tasks</h5>
                          <h2>55</h2>
                        </div>
                      </div>

                      <div class="task-redirect">
                        <div class="redirect-icon">
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="task-box color-three">
                      <div class="task-media">
                        <div class="task-icon">
                          <img
                            src="
                                                        /assets/img/icons/icon-03.png"
                            alt="Icons"
                          />
                        </div>
                        <div class="task-info">
                          <h5>Total Projects</h5>
                          <h2>55</h2>
                        </div>
                      </div>

                      <div class="task-redirect">
                        <div class="redirect-icon">
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Statistics Widget -->

      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="card card-table flex-fill">
            <div class="card-header">
              <h3 class="card-title mb-0">Clients</h3>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <a href="#" class="avatar"
                            ><img
                              alt=""
                              src="
                                                          /assets/img/profiles/avatar-19.jpg"
                          /></a>
                          <a href="client_profile"
                            >Barry Cuda <span>CEO</span></a
                          >
                        </h2>
                      </td>
                      <td>barrycuda@example.com</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-dot-circle-o text-success"></i>
                            Active
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <a href="#" class="avatar"
                            ><img
                              alt=""
                              src="
                                                          /assets/img/profiles/avatar-19.jpg"
                          /></a>
                          <a href="client_profile"
                            >Tressa Wexler <span>Manager</span></a
                          >
                        </h2>
                      </td>
                      <td>tressawexler@example.com</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-dot-circle-o text-danger"></i>
                            Inactive
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <a href="client_profile" class="avatar"
                            ><img
                              alt=""
                              src="
                                                          /assets/img/profiles/avatar-07.jpg"
                          /></a>
                          <a href="client_profile"
                            >Ruby Bartlett <span>CEO</span></a
                          >
                        </h2>
                      </td>
                      <td>rubybartlett@example.com</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-dot-circle-o text-danger"></i>
                            Inactive
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <a href="client_profile" class="avatar"
                            ><img
                              alt=""
                              src="
                                                          /assets/img/profiles/avatar-06.jpg"
                          /></a>
                          <a href="client_profile">
                            Faysal Mahmud <span>CEO</span></a
                          >
                        </h2>
                      </td>
                      <td>mistytison@example.com</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-dot-circle-o text-success"></i>
                            Active
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <a href="client_profile" class="avatar"
                            ><img
                              alt=""
                              src="
                                                          /assets/img/profiles/avatar-14.jpg"
                          /></a>
                          <a href="client_profile">
                            Daniel Deacon <span>CEO</span></a
                          >
                        </h2>
                      </td>
                      <td>danieldeacon@example.com</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-dot-circle-o text-danger"></i>
                            Inactive
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <a href="clients">View all clients</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex">
          <div class="card card-table flex-fill">
            <div class="card-header">
              <h3 class="card-title mb-0">Recent Projects</h3>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th>Progress</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h2><a href="project_view">Office Management</a></h2>
                        <small class="block text-ellipsis">
                          <span>1</span>
                          <span class="text-muted">open tasks, </span>
                          <span>9</span>
                          <span class="text-muted">tasks completed</span>
                        </small>
                      </td>
                      <td>
                        <div class="progress progress-xs progress-striped">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            data-bs-toggle="tooltip"
                            title="65%"
                            style="width: 65%"
                          ></div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2><a href="project_view">Project Management</a></h2>
                        <small class="block text-ellipsis">
                          <span>2</span>
                          <span class="text-muted">open tasks, </span>
                          <span>5</span>
                          <span class="text-muted">tasks completed</span>
                        </small>
                      </td>
                      <td>
                        <div class="progress progress-xs progress-striped">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            data-bs-toggle="tooltip"
                            title="15%"
                            style="width: 15%"
                          ></div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2><a href="project_view">Video Calling App</a></h2>
                        <small class="block text-ellipsis">
                          <span>3</span>
                          <span class="text-muted">open tasks, </span>
                          <span>3</span>
                          <span class="text-muted">tasks completed</span>
                        </small>
                      </td>
                      <td>
                        <div class="progress progress-xs progress-striped">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            data-bs-toggle="tooltip"
                            title="49%"
                            style="width: 49%"
                          ></div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          <a href="project_view">Hospital Administration</a>
                        </h2>
                        <small class="block text-ellipsis">
                          <span>12</span>
                          <span class="text-muted">open tasks, </span>
                          <span>4</span>
                          <span class="text-muted">tasks completed</span>
                        </small>
                      </td>
                      <td>
                        <div class="progress progress-xs progress-striped">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            data-bs-toggle="tooltip"
                            title="88%"
                            style="width: 88%"
                          ></div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    Projects
                    <tr>
                      <td>
                        <h2><a href="project_view">Digital Marketplace</a></h2>
                        <small class="block text-ellipsis">
                          <span>7</span>
                          <span class="text-muted">open tasks, </span>
                          <span>14</span>
                          <span class="text-muted">tasks completed</span>
                        </small>
                      </td>
                      <td>
                        <div class="progress progress-xs progress-striped">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            data-bs-toggle="tooltip"
                            title="100%"
                            style="width: 100%"
                          ></div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <a href="projects.html">View all projects</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import ApexCharts from "apexcharts";

export default {
  data() {
    return {
      dashboard_count: {},
      startOfMonth: "",
      endOfMonth: "",
      currentDate: "",
      currentMonth: "",
      currentMonthName: "",
      currentYear: "",
      selectedYear: "",
      selectedMonth: "",
      currentTime: "",
      employees: [],
      shortedEmployees: [],
      leaves: [],
      top3Departments: [],
      top_ten_positions: [],
      employee_demo: {},

    };
  },

  methods: {
    reload_js(src) {
      $("#bar-charts").empty();
      $("#line-charts").empty();
      $("#newTicketChart").empty();
      $("#solvedTicketChart").empty();
      $("#openTicketChart").empty();
      $("#pendingTicketChart").empty();
      $("#femaleChart").empty();
      $("#age-chart").empty();
      $("#femaleChart").empty();
      $("#edu-chart").empty();
      $("#maleChart").empty();
      $("#femaleChart").empty();
      $("#softwareChart").empty();

      $('script[src="' + src + '"]').remove();
      $("<script>").attr("src", src).appendTo("body");
    },
    get_dashboard_count() {
      axios
        .get("/company/dashboard/count")
        .then((response) => {
          this.dashboard_count = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gettopthree() {
      axios
        .get("/company/dashboard/department/top3")
        .then((response) => {
          this.top3Departments = response.data.data;
          for (var department of this.top3Departments) {
            this.depart_radial_chart(
              department.filled_rate,
              department.department_name,
              1,
              "softwareChart"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAttendance() {
      axios
        .get(
          "company/attendance/activity/bycompany/" +
            this.user_id +
            "/" +
            this.currentMonthName +
            "/" +
            this.selectedYear
        )
        .then((response) => {
          if (response) {
            this.employees = response.data.data;

            this.employees.sort(function (a, b) {
              var milia = "";
              var milib = "";

              if (a.employee_attendance_activity.length > 0) {
                for (
                  var i = 0;
                  i < a.employee_attendance_activity.length;
                  i++
                ) {
                  var activities = JSON.parse(
                    a.employee_attendance_activity[i].activities
                  );

                  for (var j = 0; j < activities.length; j++) {
                    if (activities[j].Date == moment().format("D/MM/YYYY")) {
                      var lastpunchina = activities[j].punchin.pop();

                      var lastpunchouta = activities[j].punchout.pop();

                      if (lastpunchouta != "undefined") {
                        var starta = moment(lastpunchina, "hh:mm:ss A");
                        var enda = moment(lastpunchouta, "hh:mm:ss A");

                        if (starta <= enda) {
                          milia = moment.duration(enda).asMilliseconds();
                        } else {
                          milia = moment.duration(starta).asMilliseconds();
                        }
                      } else {
                        milia = moment
                          .duration(moment(lastpunchina, "hh:mm:ss A"))
                          .asMilliseconds();
                      }
                    }
                  }
                }
              }

              if (b.employee_attendance_activity.length > 0) {
                for (
                  var k = 0;
                  k < b.employee_attendance_activity.length;
                  k++
                ) {
                  var activitiesb = JSON.parse(
                    b.employee_attendance_activity[k].activities
                  );

                  for (var l = 0; l < activitiesb.length; l++) {
                    // var date =  activities[j].Date

                    if (activitiesb[l].Date == moment().format("D/MM/YYYY")) {
                      console.log("ckeck");

                      var lastpunchinb = activitiesb[l].punchin.pop();

                      var lastpunchoutb = activitiesb[l].punchout.pop();

                      if (lastpunchinb != "undefined") {
                        var startb = moment(lastpunchinb, "hh:mm:ss A");
                        var endb = moment(lastpunchoutb, "hh:mm:ss A");

                        if (startb <= endb) {
                          milib = moment.duration(endb).asMilliseconds();
                        } else {
                          milib = moment.duration(startb).asMilliseconds();
                        }
                      } else {
                        milib = moment
                          .duration(moment(lastpunchinb, "hh:mm:ss A"))
                          .asMilliseconds();
                      }
                    }
                  }
                }
              }

              if (milia < milib) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          console.log(this.employees);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getemployeeleave() {
      axios
        .get("company/leaves")
        .then((response) => {
          if (response) {
            this.leaves = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    depart_radial_chart(value, name, length, id) {
      var options = {
        series: [value],
        chart: {
          height: 180,
          type: "radialBar",
          dropShadow: {
            enabled: true,
            blur: 2,
            color: "#000",
            opacity: 0.25,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                color: "#2E2A77",
                fontSize: "14px",
              },
              value: {
                color: "#FF7849",
                fontSize: "10px",
                show: true,
                offsetY: 10,
              },
            },
          },
        },
        fill: {
          opacity: 1.5,
          colors: ["#FF7849", "#FF7849"],
          type: "gradient",
          gradient: {
            gradientToColors: ["#FF7849", "#FF7849"],
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 2,
            stops: [0, 50, 100],
            inverseColors: false,
          },
        },
        labels: [name],
      };
      for (var i = 0; i < length; i++) {
        var chart = new ApexCharts(document.querySelector("#" + id), options);
        chart.render();
      }
    },

    gender_radial_chart(value, name, length, id) {
      var options = {
        series: [value],
        chart: {
          height: 250,
          type: "radialBar",
          dropShadow: {
            enabled: true,
            blur: 2,
            color: "#000",
            opacity: 0.25,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                color: "#2E2A77",
                fontSize: "16px",
              },
              value: {
                color: "#FF7849",
                fontSize: "12px",
                show: true,
                offsetY: 10,
              },
            },
          },
        },
        fill: {
          opacity: 1.5,
          colors: ["#FF7849", "#FF7849"],
          type: "gradient",
          gradient: {
            gradientToColors: ["#FF7849", "#FF7849"],
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 2,
            stops: [0, 50, 100],
            inverseColors: false,
          },
        },
        labels: [name],
      };
      for (var i = 0; i < length; i++) {
        var chart = new ApexCharts(document.querySelector("#" + id), options);
        chart.render();
      }
    },

    gettoptenposition() {
      axios
        .get("/company/dashboard/position/top10")
        .then((response) => {
          if (response) {
            this.top_ten_positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    get_employee_demo() {
      axios
        .get("company/dashboard/employee_demo")
        .then((response) => {
          if (response) {
            this.employee_demo = response.data.data;

            this.gender_radial_chart(
              this.employee_demo.gender.male_rate,
              "Male",
              1,
              "maleChart"
            );
            this.gender_radial_chart(
              this.employee_demo.gender.female_rate,
              "Female",
              1,
              "femaleChart"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    current() {
      this.startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
      this.endOfMonth = parseInt(moment().endOf("month").format("DD"));
      this.currentDate = moment().format("D/MM/YYYY");
      this.currentMonth = moment().format("MM");
      this.currentMonthName = moment().format("MMMM");
      this.currentYear = moment().format("YYYY");
      this.selectedYear = this.currentYear;
      this.selectedMonth = this.currentMonthName;
      this.currentTime = moment().format("hh:mm:ss A");
    },
  },

  mounted() {
    this.reload_js("/assets/js/chart.js");
    this.reload_js("/assets/js/app.js");
    this.get_dashboard_count();
    this.current();
    this.getAttendance();
    this.getemployeeleave();
    this.gettopthree();
    this.gettoptenposition();
    this.get_employee_demo();
  },
};
</script>

<style></style>
