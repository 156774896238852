<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid pb-0">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Employee Profile</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="dashboard">Dashboard</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="card mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="profile-view">
                <div class="profile-img-wrap">
                  <div class="profile-img">
                    <a href="">
                      <img :src="logourl" alt="" />
                    </a>
                  </div>
                </div>
                <div class="profile-basic">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="profile-info-left">
                        <h3 class="user-name m-t-0">
                          Md. Sarwar jahan Morshed
                        </h3>

                        <small class="text-muted"
                          >COO & Principal Data Scientist</small
                        >
                        <div class="staff-id">Employee ID : CLT-0001</div>
                        <div class="staff-id">Department : Data Science</div>

                        <div class="staff-msg">
                          <a href="apps-chat" class="btn btn-custom">Update</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="personal-info">
                        <li>
                          <span class="title">Phone:</span>
                          <span class="text">+8801987654312</span>
                        </li>
                        <li>
                          <span class="title">Email:</span>
                          <span class="text">jahan@gmail.com</span>
                        </li>
                        <li>
                          <span class="title">Birthday:</span>
                          <span class="text">2nd August 1980</span>
                        </li>

                        <li>
                          <span class="title">Gender:</span>
                          <span class="text">Male</span>
                        </li>
                        <li>
                          <span class="title">Religion:</span>
                          <span class="text">Islam</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card tab-box mt-3">
        <div class="row user-tabs">
          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
            <ul class="nav nav-tabs nav-tabs-bottom">
              <li class="nav-item col-sm-2">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#myprojects"
                  >Basic Information</a
                >
              </li>
              <li class="nav-item col-sm-2">
                <a class="nav-link" data-bs-toggle="tab" href="#tasks"
                  >Employment Details</a
                >
              </li>
              <li class="nav-item col-sm-2">
                <a class="nav-link" data-bs-toggle="tab" href="#education"
                  >Education Details</a
                >
              </li>
              <li class="nav-item col-sm-2">
                <a class="nav-link" data-bs-toggle="tab" href="#experience"
                  >Work Experience</a
                >
              </li>
              <li class="nav-item col-sm-2">
                <a class="nav-link" data-bs-toggle="tab" href="#Training"
                  >Traning</a
                >
              </li>
              <li class="nav-item col-sm-2">
                <a class="nav-link" data-bs-toggle="tab" href="#visa"
                  >Visa and work permit</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content profile-tab-content">
            <!-- Projects Tab -->
            <div id="myprojects" class="tab-pane fade show active">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Father's Name:</span>
                              <span class="text">Jahan Morshed</span>
                            </li>
                            <li>
                              <span class="title">Father's NID:</span>
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title">Mather's Name:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>
                            <li>
                              <span class="title">Mather's NID:</span>
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title">Present Address:</span>
                              <span class="text"
                                >hcvjx hfvdfhv fhvudhfvu dfvudf fdfvd fvdfvd
                                dfvd</span
                              >
                            </li>

                            <li>
                              <span class="title">Permanent Address:</span>
                              <span class="text"
                                >Maleidjifv dfvid vdufvdi uvhdfuv vud fdvfvdf
                                fvdfv dfcdsfv</span
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Blood Group:</span>
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title"
                                >Emargency Contact Person:</span
                              >
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title">Emargency Contact:</span>
                              <span class="text">+8801987654312</span>
                            </li>

                            <li>
                              <span class="title">Nomeniee Name:</span>
                              <span class="text">jahan@gmail.com</span>
                            </li>
                            <li>
                              <span class="title">Nomeniee Relation:</span>
                              <span class="text">2nd August 1980</span>
                            </li>

                            <li>
                              <span class="title"
                                >Nomeniee Contact Number:</span
                              >
                              <span class="text">Male</span>
                            </li>

                            <li>
                              <span class="title">Nomeniee's NID:</span>
                              <span class="text">O (+ve)</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Projects Tab -->

            <!-- Task Tab -->
            <div id="tasks" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Department:</span>
                              <span class="text">Data Science</span>
                            </li>
                            <li>
                              <span class="title">Designation:</span>
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title">Assigned uder:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Joining Date</span>
                              <span class="text">Jahanara Begum</span>
                            </li>

                            <li>
                              <span class="title">Grade:</span>
                              <span class="text">O (+ve)</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Task Tab -->

            <!-- Education Tab -->
            <div id="education" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Degree</th>
                            <th>Major</th>
                            <th>Institute</th>
                            <th>Passing Year</th>
                            <th>Result/Grade</th>
                            <th>Certificate (PDF)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- Example row -->
                          <tr>
                            <td>SSC</td>
                            <td>Science</td>
                            <td>ABC School</td>
                            <td>2020</td>
                            <td>3.8</td>
                            <td class="text-center">
                              <a href="path_to_certificate.pdf" target="_blank">
                                <i
                                  class="fas fa-file-pdf"
                                  style="font-size: 24px; color: #2e2a77"
                                ></i>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>HSC</td>
                            <td>Computer Science Science Science</td>
                            <td>ABC University University University</td>
                            <td>2020</td>
                            <td>3.8</td>
                            <td class="text-center">
                              <a href="path_to_certificate.pdf" target="_blank">
                                <i
                                  class="fas fa-file-pdf"
                                  style="font-size: 24px; color: #2e2a77"
                                ></i>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>Bachelor of Science</td>
                            <td>Computer Science</td>
                            <td>ABC University</td>
                            <td>2020</td>
                            <td>3.8</td>
                            <td class="text-center">
                              <a href="path_to_certificate.pdf" target="_blank">
                                <i
                                  class="fas fa-file-pdf"
                                  style="font-size: 24px; color: #2e2a77"
                                ></i>
                              </a>
                            </td>
                          </tr>
                          <!-- Add more rows as needed -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Education Tab -->

            <!-- Experience Tab -->
            <div id="experience" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <div class="timeline">
                        <!-- Experience 1 -->
                        <div class="timeline-item">
                          <div class="timeline-date">Jan 2020 - Present</div>
                          <div class="timeline-content">
                            <h4 class="timeline-title">
                              Software Engineer
                            </h4>
                            <p>
                              <strong>DataFluent Analytics</strong>
                            </p>
                            
                            <p>
                              <strong>Address:</strong> 123 Street, City,
                              California, USA
                            </p>
                            <p>
                              <strong>Responsibility:</strong> 
                              
                              <ol>
                                <li>Leading the software development team and managing project timelines.</li>
                                <li>Developing and maintaining software applications.</li>
                                <li>Collaborating with cross-functional teams to understand business requirements and develop solutions.</li>
                              
                              </ol>
                            </p>
                            <p><strong>Experience Certificate:</strong> <a href="#">View Certificate</a></p>
                          </div>
                        </div>
                        <!-- /Experience 1 -->

                        <!-- Experience 2 -->
                        <div class="timeline-item">
                          <div class="timeline-date">Jan 2022 - Dec 2021</div>
                          <div class="timeline-content">
                            <h4 class="timeline-title">
                              Senior Developer
                            </h4>
                            <p>
                              <strong>DataFluent Analytics</strong>
                            </p>
                            <p>
                              <strong>Address:</strong> 456 Avenue, Another
                              City, New York, USA
                            </p>
                            <p>
                              <strong>Responsibility:</strong> 
                              
                              <ol>
                                <li>Leading the software development team and managing project timelines.</li>
                                <li>Developing and maintaining software applications.</li>
                                <li>Collaborating with cross-functional teams to understand business requirements and develop solutions.</li>
                              
                              </ol>
                            </p>

                            <p><strong>Experience Certificate:</strong> <a href="#">View Certificate</a></p>
                            
                          </div>
                        </div>
                        <!-- /Experience 2 -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- /Experience Tab -->

            <!-- Training Tab -->
            <div id="Training" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <div class="timeline">
                        <!-- Training 1 -->
  <div class="timeline-item">
    <div class="timeline-date">Jan 2022 - Mar 2022</div>
    <div class="timeline-content">
      <h4 class="timeline-title">Advanced Data Science</h4>
      <p><strong>Organization:</strong> ABC Institute</p>
      <p><strong>Country:</strong> USA</p>
      <p><strong>Training Certificate:</strong> <a href="#">View Certificate</a></p>
    </div>
  </div>

  <!-- Training 2 -->
  <div class="timeline-item">
    <div class="timeline-date">Apr 2023 - Jun 2023</div>
    <div class="timeline-content">
      <h4 class="timeline-title">Machine Learning Fundamentals</h4>
      <p><strong>Organization:</strong> XYZ Academy</p>
      <p><strong>Country:</strong> Canada</p>
      <p><strong>Training Certificate:</strong> <a href="#">View Certificate</a></p>
    </div>
  </div>
</div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- /Training Tab -->


            <!-- visa Tab -->
            <div id="visa" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-5">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Orgine Country:</span>
                              <span class="text">Data Science</span>
                            </li>
                            <li>
                              <span class="title">Passport No:</span>
                              <span class="text">O (+ve)</span>
                            </li>

                            <li>
                              <span class="title">Reference Number:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>

                            <li>
                              <span class="title">Visa Issue Date:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>
                            
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class="personal-info">
                          <li>
                              <span class="title">Visa Expiry Date:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>
                            <li>
                              <span class="title">Visa Issue in Country:</span>
                              <span class="text">Jahanara Begum</span>
                            </li>

                            <li>
                              <span class="title">Visa Category:</span>
                              <span class="text">O (+ve)</span>
                            </li>
                            <li>
                              <span class="title">Image:</span>
                              <span><a href="#">View Certificate</a></span>

                        
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /visa Tab -->



          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>

<script>
// import $ from "jquery";
import axios from "axios";
import Constant from "@/other/Constant";

export default {
  data() {
    return {
      errors: {},
      profile: {},
      logourl: "",
      basic_information: {},
      priceplan: "",
    };
  },
  methods: {
    getProfile(uuid) {
      if (uuid) {
        axios
          .get("superadmin/companies/profile/" + uuid)
          .then((response) => {
            if (response) {
              this.profile = response.data.company;
              this.priceplan = this.profile.price.price.name;
              if (this.profile.basic_information != null) {
                this.basic_information = JSON.parse(
                  this.profile.basic_information
                );
              }
              if (this.profile.logo != null) {
                this.logourl =
                  Constant.filebaseurl + this.profile.logo.file.path;
              }

              if (this.profile.authorised_person_details != null) {
                this.auth_person = JSON.parse(
                  this.profile.authorised_person_details
                );
              }
              if (this.profile.company_address != null) {
                this.company_address = JSON.parse(this.profile.company_address);
              }
              if (this.profile.trading_hour != null) {
                this.company_hour = JSON.parse(this.profile.trading_hour);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted: function () {
    this.uuid = this.$route.query.uuid;
    if (this.uuid) {
      this.getProfile(this.uuid);
    }
  },
};
</script>

<style>

</style>
