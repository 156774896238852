<template>
    <div class="wrapper">

<!-- Page Content -->
<div class="content-wrapper p-4">

    <!-- Page Header -->
    <div class="row">
        <div class="col-md-12">
            <div class="page-head-box">
                <h3>Training Approval</h3>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Trainers</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- /Page Header -->

    <!-- Search Filter -->
    <div class="row filter-row">
        <div class="col-md-8">

        <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        
        </div>
        <div class="col-md-4">
            <!-- <div class="add-emp-section">
                <a href="#" class="btn btn-success btn-add-emp" data-bs-toggle="modal" data-bs-target="#add_trainer"><i class="fas fa-plus"></i> Add New</a>
            </div> -->
        </div>
    </div>
    <!-- /Search Filter -->
    
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-striped custom-table mb-0 datatable">
                    <thead>
                        <tr>
                            <th style="width: 30px;">#</th>
                            <th>Employee Name </th>
                            <th>Contact Number </th>
                            <th>Email </th>
                            <th>Training Title </th>
                            <th>Status </th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                                <h2 class="table-avatar">
                                    <a href="profile" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                    <a href="profile">Sarwar Jahan Morshed </a>
                                </h2>
                            </td>
                            <td>9876543210</td>
                            <td>sarwarmj@dfa.com</td>
                            <td>Lorem ipsum dollar</td>
                            <td>
                                <div class="dropdown action-label">
                                    <a class="role-info role-bg-two dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-dot-circle-o text-danger"></i> Reject
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end ico-sec">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#edit_type"><i class="fas fa-eye"></i></a>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#delete_type"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>
                                <h2 class="table-avatar">
                                    <a href="profile" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"></a>
                                    <a href="profile">Pankaz Debnath </a>
                                </h2>
                            </td>
                            <td>9876543120</td>
                            <td>p.debnath@dfa.com</td>
                            <td>Git is a version control ..</td>
                            <td>
                                <div class="dropdown action-label">
                                    <a class="role-info role-bg-two dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-dot-circle-o text-success"></i> Approve
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end ico-sec">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#edit_type"><i class="fas fa-eye"></i></a>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#delete_type"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>
                                <h2 class="table-avatar">
                                    <a href="profile" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"></a>
                                    <a href="profile">Ejaz Wahid </a>
                                </h2>
                            </td>
                            <td>9876543210</td>
                            <td>e.wahid@dfa.com</td>
                            <td>Angular is a complete JavaScript...</td>
                            <td>
                                <div class="dropdown action-label">
                                    <a class="role-info role-bg-two dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end ico-sec">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#edit_type"><i class="fas fa-eye"></i></a>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#delete_type"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>
                                <h2 class="table-avatar">
                                    <a href="profile" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"></a>
                                    <a href="profile">Assaduzzaman </a>
                                </h2>
                            </td>
                            <td>9876543210</td>
                            <td>asad@dfa.com</td>
                            <td>Fast and secure RESTful..</td>
                            <td>
                                <div class="dropdown action-label">
                                    <a class="role-info role-bg-two dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end ico-sec">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#edit_type"><i class="fas fa-eye"></i></a>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#delete_type"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>
                                <h2 class="table-avatar">
                                    <a href="profile" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                    <a href="profile">Sabuj Ahmed </a>
                                </h2>
                            </td>
                            <td>9876543210</td>
                            <td>s.ahmed@dfa.com</td>
                            <td>Develop in Swift curriculum. ...</td>
                            <td>
                                <div class="dropdown action-label">
                                    <a class="role-info role-bg-two dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end ico-sec">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#edit_type"><i class="fas fa-eye"></i></a>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#delete_type"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- /Page Content -->

<!-- Add Trainers List Modal -->
<div id="add_trainer" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Trainer</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">Last Name</label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">Role <span class="text-danger">*</span></label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">Email <span class="text-danger">*</span></label>
                                <input class="form-control" type="email">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">Phone </label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="col-form-label">Status</label>
                                <select class="select">
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Description <span class="text-danger">*</span></label>
                                <textarea class="form-control" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary cancel-btn" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button class="btn btn-primary submit-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Add Trainers List Modal -->

<!-- Edit Trainers List Modal -->
<div id="edit_type" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Training Details</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="col-form-label">Training title <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" value="John">
                            </div>
                        </div>
                      
                      
                    
                     
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Description <span class="text-danger">*</span></label>
                                <textarea class="form-control" rows="4">Lorem ipsum ismap</textarea>
                            </div>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary cancel-btn" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button class="btn btn-primary submit-btn">Confirm</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Edit Trainers List Modal -->

<!-- Delete Trainers List Modal -->
<div class="modal custom-modal fade" id="delete_type" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-header">
                    <h3>Delete Trainers List</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                    <div class="row">
                        <div class="col-6">
                            <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                        </div>
                        <div class="col-6">
                            <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Trainers List Modal -->

</div>

</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
// components: { DataTable },

data() {
return {
title: "Weekend",
weekends: [],
errors: {},
department: {},
day:null,
uuid:""
};
},

methods: {
list() {
axios
.get("/company/weekends")
.then((response) => {
 if (response) {
   
   var weekends = response.data.data;
   this.weekends=[]

   for(var weekend of weekends){
     var id=weekend.id
     var uuid=weekend.uuid
     var day=weekend.day
     var note=weekend.note
     var rate=weekend.rate
     var action =
       "<a href='#' onclick='handleClick(" +
       '"' +
       uuid +
       '"' +
       ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
       '"' +
       uuid +
       '"' +
       ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

     this.weekends.push([id,day,note,rate,action]);

   }


 }
})
.catch((error) => {
 console.log(error);
 
});
},
add() {
axios
.post("/company/weekends", {
 note: this.note,
 day: this.day,
 rate: this.rate,
})
.then((response) => {
 if (response) {

    

  this.rate

  this.holiday_name="";
  this.note="";
     this.list();
     $("#addModal .close").click()
 }
})
.catch((error) => {
 this.errors = error.response.data.errors;
});
},
update(uuid) {
axios
 .put("/company/departments/" + uuid, {
   department_name: this.department.department_name,
   d_id: this.department.d_id,
 })
 .then((response) => {
   if (response) {
     this.list();

     $("#editModal .close").click();
   }
 })
 .catch((error) => {
   console.log(error);
 });
},

delete_single() {
axios
 .delete("/company/departments/" + this.uuid)
 .then((response) => {
   if (response) {
     $("#deleteModal.close").click();
     this.list();
   }
 })
 .catch((error) => {
   console.log(error);
 });
},
edit(id) {
axios
 .get("/company/departments/" + id)
 .then((response) => {
   if (response) {
     this.department = response.data.data;
   }
 })
 .catch((error) => {
   console.log(error);
 });
},
deletePopUp(uuid) {
this.uuid=uuid


},
},

mounted: function () {
this.name = JSON.parse(localStorage.getItem("user")).user.name;
window.edit = this.edit;
window.deletePopUp = this.deletePopUp;
this.list();
},
};
</script>


   
<style>
@import "datatables.net-bs5";
</style>