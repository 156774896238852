<template>
  <div
    class="account-page"
   
  >
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div style="position: absolute; top: 60px; right: 0">
        <span style="color: #2e2a77; margin-top: 100px; margin-right: 100px">
          <i class="fa fa-question"></i> Help</span
        >
      </div>

      <div class="account-content sdfds">
        <div class="container">
          <h2 class="account-title text-center mt-5">Welcome to PROHRM !</h2>

          <div class="account-box register mt-5">
            <div class="account-wrapper">
              <p class="account-subtitle">Register to access Dashboard</p>
              <label
                >Select a plan : <span class="error">{{ price_error }}</span>
              </label>

              <div class="mt-4">
                <span
                  @click="pricebtntoggle($event, price.id)"
                  class="price-btn price-btn-outline"
                  v-for="price in prices"
                  :key="price.id"
                  >{{ price.name }}</span>
                  
              </div>

              <!-- Account Form -->
              <form class="mt-4">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input
                        @input="validator.clear('company_name')"
                        id="company_name"
                        v-model="company_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter your company name"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group mb0">
                      <label> Country</label>

                      <select
                        @change="validator.clear('country_id')"
                        id="country_id"
                        v-model="country_id"
                        class="form-control form-select"
                      >
                        <option disabled value="">Country</option>
                        <option
                          v-for="country in countries"
                          :key="country.id"
                          :value="country.id"
                        >
                          {{ country.country_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Authrity Person Name</label>
                  <input
                    @input="validator.clear('auth_name')"
                    v-model="auth_name"
                    type="text"
                    class="form-control"
                    id="auth_name"
                    placeholder="Enter authrity person name"
                  />
                  <div class="invalid-feedback">Please Enter Email</div>
                </div>

                <div class="form-group">
                  <label>Company Email</label>
                  <input
                    @input="validator.clear('email')"
                    id="email"
                    v-model="email"
                    type="text"
                    class="form-control"
                    placeholder="Enter authrity person name"
                  />
                  <div class="invalid-feedback">Please Enter Email</div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <label for="userpassword" class="form-label"
                            >Password</label
                          >
                        </div>
                      </div>
                      <div class="position-relative">
                        <input
                          @input="validator.clear('password')"
                          v-model="password"
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="Enter password"
                        />

                        <span
                          class="fa fa-eye-slash"
                          id="toggle-password"
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <label for="userpassword" class="form-label"
                            >Comfirm Password</label
                          >
                        </div>
                      </div>
                      <div class="position-relative">
                        <input
                          @input="validator.clear('confirm_password')"
                          v-model="confirm_password"
                          type="password"
                          class="form-control"
                          id="confirm_password"
                          placeholder="Confirm password"
                        />

                        <span
                          class="fa fa-eye-slash"
                          id="toggle-password"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group text-center">
                  <div id="loader-small">
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                  </div>
                  <button
                    @click.prevent="register"
                    class="btn btn-primary account-btn"
                  >
                    Register
                  </button>
                </div>

                <div class="account-footer">
                  <p>
                    If you have an account yet?
                   <router-link :to="{ name: 'login' }">Login</router-link>
                  </p>
                </div>
              </form>
              <!-- /Account Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->

    <!-- JAVASCRIPT -->
  </div>
</template>
  
  <script>
import { Validator } from "@/other/Validator";
import $ from "jquery";

import Notify from "@/other/Notify";

import axios from "axios";

export default {
  name: "LoginView",

  data() {
    return {
      prices: [],
      price_id: "",
      price_error: "",
      error_input: "",
      validator: new Validator(),
      country_id: "",
      email: "",
      countries: [],
    };
  },

  methods: {
    pricebtntoggle(event, id) {
      let button = event.target;
      var buttons = document.getElementsByClassName("price-btn");

      for (var i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove("price-btn-fill");
      }

      button.classList.toggle("price-btn-fill");

      this.price_id = id;
      this.price_error = "";
    },
    getCountry() {
      axios
        .get("public/countries")
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    register() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "country_id", value: this.country_id, type: "required" },

        { field: "email", value: this.email, type: "required|email" },
        { field: "company_name", value: this.company_name, type: "required" },
        { field: "auth_name", value: this.auth_name, type: "required" },

        {
          field: "password",
          value: this.password,
          type: "required|length",
          size: 8,
        },
        {
          field: "confirm_password",
          value: this.confirm_password,
          type: "required|length",
          size: 8,
        },
      ]);

      if (this.price_id == "") {
        this.price_error = "Please select price plan";
        error = true;
      }

      if (error) {
        console.log(error);
      } else {
        var loader = document.getElementById("loader-small");

        loader.style.display = "block";

        axios
          .post("register", {
            name: this.auth_name,
            company_name: this.company_name,
            country_id: this.country_id,
            email: this.email,
            password: this.password,
            confirm_password: this.confirm_password,
            priceid: this.price_id,
          })
          .then((response) => {
            console.log(response.data);
            var loader = document.getElementById("loader-small");

            loader.style.display = "none";
            Notify.updateNotification("Company created successfully");

            this.$router.push({ name: "verification" , query: { email: this.email }});
          })
          .catch((error) => {

            var loader = document.getElementById("loader-small");

            loader.style.display = "none";

            
            if (error.response.data.errors.password) {
              $("#password").addClass('error-input')
              $("#password").val("")

              $("#password").attr("placeholder", error.response.data.errors.password[0]);
              
              
            }

            if (error.response.data.errors.confirm_password) {
              $("#confirm_password").css("display", "block");
              $("#confirm_password").html(
                error.response.data.errors.confirm_password[0]
              );
            }
            if (error.response.data.errors.email) {
              $("#email").addClass('error-input')
              $("#email").val("")

              $("#email").attr("placeholder", error.response.data.errors.email[0]);
            }
            if (error.response.data.errors.name) {
              $("#first").css("display", "block");
              $("#first").html(error.response.data.errors.name[0]);
            }
          });
      }
    },

    getprice() {
      axios
        .get("/public/prices")
        .then((response) => {
          if (response) {
            this.prices = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
  },
  mounted() {
    this.getprice();
    this.getCountry();
  },
};
</script>
  
  <style>
</style>