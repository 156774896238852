<template>
  <aside class="main-sidebar sidebar-light-primary elevation-2">
    <!-- Brand Logo -->
    <a href="" class="brand-link text-center">
      <img
        class="pb-3"
        src="/assets/img/logo_color.png"
        width="140px"
        height="100px"
        alt=""
      />
    </a>

    <!-- 
    <div class="user-panel  py-3  d-flex">
        <div class="image">
          <img height="70px" width="50px" style="border-radius: 50%;" :src="logourl" class="ml-3" alt="User Image">
        </div>
        <div class="info ml-2">
          <a href="#" style="color:gray" class="d-block">{{name}}</a>
        </div>
       
      </div>
   -->

    <!-- Sidebar -->
    <div class="sidebar mt-2">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'company-dashboard' }">
              <i  class="icon icon-dashboard nav-icon primary_color  mr-2"></i>

              <p>Dashboard</p>
            </router-link>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-admin nav-icon primary_color  mr-2"></i>

              <p>
                Administrative

                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-employee' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Employees
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-department' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Departments
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-grade' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Grades
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-position' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Positions
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-level' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Levels
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-assets' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Assets
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-add-leave' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Leave
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-service-rule' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Service
                  Rule
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-assign-resource' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Assets
                  Management
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-fingerprint nav-icon primary_color  mr-2"></i>

              <p>
                Attendance & Leave
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-leave-application' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Employee
                  leaves
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-employee-attendance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Employee
                  Attendance
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-holiday' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Holidays
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-weekend' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Weekends
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-timesheet' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Timesheet
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-shift' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Shift &
                  Schedule
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-overtime' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Overtime
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-onboard nav-icon primary_color  mr-2"></i>

              <p>
                Employee Onboarding
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-jobdemand' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Job Demands
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-job-application' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Job
                  Application
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-shortlisted' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Shortlist
                  Candidates
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-interview-schedules' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Interview
                  Schedule
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-interview-result' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Interview
                  Results
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-final-candidate' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Final
                  Candidates
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-assign-resource' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Assign
                  Resource
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-payroll nav-icon primary_color  mr-2"></i>

              <p>
                Basic Payroll
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-employee-payslip' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Payslip
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-payroll' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Payroll
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-advanced-payroll nav-icon primary_color  mr-2"></i>

              <p>
                Advanced Payroll
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-requested-loan' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Requested
                  Loans
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-advanced-payment' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Advanced
                  Payment
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-provident-fund' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Provident
                  Fund
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'company-pf-rule' }">
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>PF Rule
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-performance nav-icon primary_color  mr-2"></i>

              <p>
                Performance
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-performance-indicator' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Performance
                  indicator
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-performance-appraisal' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Performance
                  appraisal
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-special-performance' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Special
                  Performance
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'company-promotion' }">
              <i  class="icon icon-transition nav-icon primary_color  mr-2"></i>

              <p>
                Carrer Transition
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-promotion' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Promotion
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-resignation' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Resignation
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-termination' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Termination
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i  class="icon icon-certificate nav-icon primary_color  mr-2"></i>

              <p>
                Training
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-traing-list' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Traing List
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-trainers' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Trainers
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'company-training-approval' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Training
                  Approval
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import Constant from "@/other/Constant";
import $ from "jquery";
export default {
  data() {
    return {
      name: "",
      modules: [],
      modules_name: [],
      logourl: "",
    };
  },

  methods: {
    userInfo() {},
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    var logo = JSON.parse(localStorage.getItem("user")).user.logo;

    if (logo != null) {
      this.logourl =
        Constant.filebaseurl +
        JSON.parse(localStorage.getItem("user")).user.logo.file.path;
    }

    this.modules = JSON.parse(
      localStorage.getItem("user")
    ).user.price.price.modules;
    for (let i = 0; i < this.modules.length; i++) {
      this.modules_name.push(this.modules[i].company_module.module_name);
    }

    $(".nav-item").click(function () {
      $(this)
        .toggleClass("menu-open")
        .children(".nav-treeview")
        .click(function () {
          return false;
        });

      $(this).children(".nav-treeview").toggleClass("animate-in");
    });

   $("#pushbtn").click(function () {

    $("body").toggleClass("sidebar-collapse");
   


    });

  },
};
</script>


<style>
</style>