<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Timesheet</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Timesheet
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">

        <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Project Name"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        
        
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_todaywork"
              style="width: auto"
              ><i class="fas fa-plus"></i> Add Today Work</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Date</th>
                  <th>Projects</th>
                  <th class="text-center">Assigned Hours</th>
                  <th class="text-center">Hours</th>
                  <th class="d-none d-sm-table-cell">Description</th>
                  <th class="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                      /></a>
                      <a href="profile"
                        >Sarwar Jahan Morshed <span>Web Designer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Office Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">7</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Design, implementation, evaluation, and maintenance of the
                    process of work within ...
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                      /></a>
                      <a href="profile"
                        >Sabuj Ahmed <span>Web Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Project Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    processes, methods, skills, knowledge and experience to
                    achieve specific project ...
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                      /></a>
                      <a href="profile"
                        >Assaduzzaman <span>Android Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Video Calling App</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Select the contact you wish to call. Tap the video icon....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                      /></a>
                      <a href="profile"
                        >Pankaz Debnath <span>IOS Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Hospital Administration</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Responsible for organizing and overseeing the health
                    services and....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                      /></a>
                      <a href="profile">Ejaz Wahid <span>Team Leader</span></a>
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Office Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">7</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    maintenance of the process of work within.....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                      /></a>
                      <a href="profile"
                        >Pankaz Debnath <span>Web Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Project Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    manage staff and budgets, communicate between
                    departments....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                      /></a>
                      <a href="profile"
                        >Safwan Chowdhory <span>Web Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Video Calling App</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Ensure adequate patient care amongst other....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                      /></a>
                      <a href="profile"
                        >Alamin Hossain <span>Team Leader</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Hospital Administration</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    built, staffed, and equipped for the diagnosis of
                    disease....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                      /></a>
                      <a href="profile">Sabbir Khan <span>Team Leader</span></a>
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Ware house developement</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">9</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Manufacturers, importers, exporters, wholesalers...
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-04.jpg"
                      /></a>
                      <a href="profile"
                        >Kh Hridoy <span>Android Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Office Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Generate invoices, flow chart of business....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-03.jpg"
                      /></a>
                      <a href="profile"
                        >Arshed Ahmed <span>Android Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Project Management</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Trend, forcasting, cumulative graph....
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-08.jpg"
                      /></a>
                      <a href="profile"
                        >Sufatullah <span>Android Developer</span></a
                      >
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td>
                    <h2>Video Calling App</h2>
                  </td>
                  <td class="text-center">20</td>
                  <td class="text-center">12</td>
                  <td class="d-none d-sm-table-cell col-md-4">
                    Cone app as IMO.
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_todaywork"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_workdetail"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Today Work Modal -->
    <div id="add_todaywork" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Today Work details</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Project <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Office Management</option>
                    <option>Project Management</option>
                    <option>Video Calling App</option>
                    <option>Hospital Administration</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-4">
                  <label>Deadline <span class="text-danger">*</span></label>
                  <div class="cal-icon">
                    <input
                      class="form-control"
                      type="text"
                      value="5 May 2023"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group col-sm-4">
                  <label>Total Hours <span class="text-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    value="100"
                    readonly
                  />
                </div>
                <div class="form-group col-sm-4">
                  <label
                    >Remaining Hours <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" value="60" readonly />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label>Date <span class="text-danger">*</span></label>
                  <div class="cal-icon">
                    <input class="form-control datetimepicker" type="text" />
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>Hours <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control"></textarea>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Today Work Modal -->

    <!-- Edit Today Work Modal -->
    <div id="edit_todaywork" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Work Details</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Project <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Office Management</option>
                    <option>Project Management</option>
                    <option>Video Calling App</option>
                    <option>Hospital Administration</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-4">
                  <label>Deadline <span class="text-danger">*</span></label>
                  <div class="cal-icon">
                    <input
                      class="form-control"
                      type="text"
                      value="5 May 2023"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group col-sm-4">
                  <label>Total Hours <span class="text-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    value="100"
                    readonly
                  />
                </div>
                <div class="form-group col-sm-4">
                  <label
                    >Remaining Hours <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" value="60" readonly />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label>Date <span class="text-danger">*</span></label>
                  <div class="cal-icon">
                    <input
                      class="form-control datetimepicker"
                      value="03/03/2023"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>Hours <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" value="9" />
                </div>
              </div>
              <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</textarea
                >
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Today Work Modal -->

    <!-- Delete Today Work Modal -->
    <div class="modal custom-modal fade" id="delete_workdetail" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Work Details</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Today Work Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
