<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Overtime</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Overtime
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Overtime Statistics -->
      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 d-flex">
          <div class="stats-info ot-div flex-fill">
            <h4>12</h4>
            <h6>Overtime Employee</h6>
            <span>this month</span>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 d-flex">
          <div class="stats-info ot-div flex-fill">
            <h4>118</h4>
            <h6>Overtime Hours</h6>
            <span>this month</span>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 d-flex">
          <div class="stats-info ot-div flex-fill">
            <h4>23</h4>
            <h6>Pending Request</h6>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 d-flex">
          <div class="stats-info ot-div flex-fill">
            <h4>5</h4>
            <h6>Rejected</h6>
          </div>
        </div>
      </div>
      <!-- /Overtime Statistics -->

      <!-- Search Filter -->
      <div class="row filter-row mt-4">
        <div class="col-md-8">

        <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="Date"
                  class="form-control"
                  placeholder="Date"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>

        
        
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_overtime"
              style="width: auto"
              ><i class="fas fa-plus"></i> Add Overtime</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>OT Date</th>
                  <th class="text-center">OT Hours</th>
                  <th>OT Type</th>
                  <th>Description</th>
                  <th class="text-center">Status</th>
                  <th>Approved by</th>
                  <th class="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <h2 class="table-avatar blue-link">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                      /></a>
                      <a href="profile">Sarwar Jahan Morshed</a>
                    </h2>
                  </td>
                  <td>8 Mar 2023</td>
                  <td class="text-center">2</td>
                  <td>Normal day OT 1.5x</td>
                  <td>Lorem ipsum dollar</td>
                  <td class="text-center">
                    <div class="action-label">
                      <span class="role-info role-bg-one">
                        <i class="far fa-dot-circle"></i> New
                      </span>
                    </div>
                  </td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar avatar-xs"
                        ><img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          alt=""
                      /></a>
                      <a href="#">Sabuj Ahmed</a>
                    </h2>
                  </td>
                  <td class="text-end ico-sec d-flex justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_overtime"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_overtime"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Overtime Modal -->
    <div id="add_overtime" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Overtime</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label
                  >Select Employee <span class="text-danger">*</span></label
                >
                <select class="select">
                  <option>-</option>
                  <option>Sarwar Jahan Morshed</option>
                  <option>Sabuj Ahmed</option>
                  <option>Assaduzzaman</option>
                </select>
              </div>
              <div class="form-group">
                <label>Overtime Date <span class="text-danger">*</span></label>
                <div class="cal-icon">
                  <input class="form-control datetimepicker" type="text" />
                </div>
              </div>
              <div class="form-group">
                <label>Overtime Hours <span class="text-danger">*</span></label>
                <input class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control"></textarea>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Overtime Modal -->

    <!-- Edit Overtime Modal -->
    <div id="edit_overtime" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Overtime</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label
                  >Select Employee <span class="text-danger">*</span></label
                >
                <select class="select">
                  <option>-</option>
                  <option>Sarwar Jahan Morshed</option>
                  <option>Sabuj Ahmed</option>
                  <option>Assaduzzaman</option>
                </select>
              </div>
              <div class="form-group">
                <label>Overtime Date <span class="text-danger">*</span></label>
                <div class="cal-icon">
                  <input class="form-control datetimepicker" type="text" />
                </div>
              </div>
              <div class="form-group">
                <label>Overtime Hours <span class="text-danger">*</span></label>
                <input class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control"></textarea>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Overtime Modal -->

    <!-- Delete Overtime Modal -->
    <div class="modal custom-modal fade" id="delete_overtime" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Overtime</h3>
              <p>Are you sure want to Cancel this?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Overtime Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
