<template>
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Attendance</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Attendance
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-md-4 d-flex">
          <div class="card punch-status flex-fill">
            <div class="card-body">
              <h5 class="card-title">Timesheet</h5>

              <div class="punch-finger">
                <i class="fas fa-fingerprint"></i>
                <div class="punch-det">
                  <h6>{{ punch_type }} at</h6>
                  <p>{{ punch_time }}</p>
                </div>
              </div>

              <div class="punch-info">
                <div class="punch-hours">
                  
                  <span v-if="!leave" style="font-size: 90%" id="clock2">12:50</span>
                  <span v-if="leave" style="font-size: 90%" >Leave</span>

                </div>
              </div>
              <div v-if="!leave" class="punch-btn-section">
                <span>Punch in</span>
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    @change="addAttendance"
                    v-model="punchbtn"
                    name="onoffswitch"
                    class="onoffswitch-checkbox"
                    id="switch_annual"
                    checked
                  />
                  <label class="onoffswitch-label" for="switch_annual">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
                <span>Punch out</span>
              </div>
              <div v-if="!leave" class="statistics">
                <ul>
                  <li>
                    <div class="stats-box">
                      <p>Works</p>
                      <h6>{{ works_hours }}</h6>
                    </div>
                  </li>
                  <li>
                    <div class="stats-box">
                      <p>Breaks</p>
                      <h6>{{ break_hours }}</h6>
                    </div>
                  </li>
                  <li>
                    <div class="stats-box">
                      <p>Overtime</p>
                      <h6>{{ over_time }}</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div  class="col-md-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h5 class="card-sub-title">Statistics</h5>

              <div class="row">
                <div class="col-md-6">
                  <div class="card st-card st-c1">
                    <div class="stats-info">
                      <div>
                        <p>Today</p>
                        <h5>{{ works_hours2 }} / 8 hrs</h5>
                      </div>

                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 31%"
                          aria-valuenow="31"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card st-card st-c2">
                    <div class="stats-info">
                      <div>
                        <p>This Week</p>
                        <h5>{{week_hours}}/ 8 hrs</h5>
                      </div>

                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 47%"
                          aria-valuenow="47"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card st-card st-c3">
                    <div class="stats-info">
                      <div>
                        <p>This month</p>
                        <h5>{{ week_hours }} / 240 hrs</h5>
                      </div>

                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 87%"
                          aria-valuenow="87"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card st-card st-c4">
                    <div class="stats-info">
                      <div>
                        <p>Remaining</p>
                        <h5>{{ remain_hours }} / 8 hrs</h5>
                      </div>

                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 87%"
                          aria-valuenow="87"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card st-card st-c5">
                    <div class="stats-info">
                      <div>
                        <p>Overtime</p>
                        <h5>{{ over_time }}</h5>
                      </div>

                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style="width: 57%"
                          aria-valuenow="57"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 d-flex">
          <div class="card recent-activity flex-fill">
            <div class="card-body">
              <h5 class="card-title">Today Activity</h5>
              <ul class="res-activity-list">
                <div v-for="(s,i) in single_activity.punchin" :key="s" >

                  
                <li>
                  <p class="mb-0">Punch In at</p>
                  <p class="res-activity-time">
                    <i class="far fa-clock"></i>
                    {{ s }}
                  </p>
                </li>
                <li v-if="single_activity.punchout[i]" >
                  <p class="mb-0">Punch Out at</p>
                  <p class="res-activity-time">
                    <i class="far fa-clock"></i>
                     {{ single_activity.punchout[i] }}
                  </p>
                </li>
               

                </div>

                
              
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-sm-3">
          <div class="form-group form-focus mb-0">
            <div class="cal-icon">
              <input type="text" class="form-control floating datetimepicker" />
            </div>
            <label class="focus-label">Date</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group form-focus select-focus mb-0">
            <select class="select floating">
              <option>-</option>
              <option>Jan</option>
              <option>Feb</option>
              <option>Mar</option>
              <option>Apr</option>
              <option>May</option>
              <option>Jun</option>
              <option>Jul</option>
              <option>Aug</option>
              <option>Sep</option>
              <option>Oct</option>
              <option>Nov</option>
              <option>Dec</option>
            </select>
            <label class="focus-label">Select Month</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group form-focus select-focus mb-0">
            <select class="select floating">
              <option>-</option>
              <option>2023</option>
              <option>2018</option>
              <option>2017</option>
              <option>2016</option>
              <option>2015</option>
            </select>
            <label class="focus-label">Select Year</label>
          </div>
        </div>
        <div class="col-sm-3">
          <a href="#" class="btn btn-success btn-search"
            ><i class="fas fa-search me-2"></i> Search
          </a>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table
              class="table table-striped custom-table datatable att-emp-table"
            >
              <thead>
                <tr>
             
                  <th>Date</th>
                  <th>Punch In</th>
                  <th>Punch Out</th>
                  <th>Production</th>
                  <th>Break</th>
                  <th>Overtime</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in activities" :key="activity">
                
                  <td>{{ activity.Date }}</td>
                  <td>{{ activity.punchin[0] }}</td>
                  <td>{{ activity.punchout[activity.punchout.length - 1] }}</td>
                  <td>{{works_hours2}} hrs</td>
                  <td>{{ break_hours }} hrs</td>
                  <td>{{ over_time }}</td>
                </tr>
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      startOfMonth: "",
      endOfMonth: "",
      currentDate: "",
      currentMonth: "",
      currentMonthName: "",
      currentYear: "",
      selectedYear: "",
      selectedMonth: "",
      currentTime: "",
      punchbtn: false,

      punchin: [],

      punchout: [],

      activities: [],

      activities_from_server: [],

      filtered_activity_data: {},
      punch_status: "",

      punch_type: "",

      punch_time: "",
      break_hours: 0,
      works_hours:0,
      works_hours2:0,
      week_hours:0,
      remain_hours:0,
      over_time:0,
      sum_duration: 0,
      sum_durationw: 0,
      single_activity: {},
      leave:false
    };
  },
  methods: {
    current() {
      this.startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
      this.endOfMonth = parseInt(moment().endOf("month").format("DD"));
      this.currentDate = moment().format("D/MM/YYYY");
      this.currentMonth = moment().format("MM");
      this.currentMonthName = moment().format("MMMM");
      this.currentYear = moment().format("YYYY");
      this.selectedYear = this.currentYear;
      this.selectedMonth = this.currentMonthName;
      this.currentTime = moment().format("hh:mm:ss A");
    },

    addAttendance() {
      axios
        .get(
          "/employee/attendance/activity/" +
            this.currentMonthName +
            "/" +
            this.selectedYear
        )
        .then((response) => {
          if (response) {
            this.activities = JSON.parse(response.data.data.activities);
    

      
            

            if (
              this.activities.find(
                (el) => el.Date === moment().format("D/MM/YYYY")
              )
            ) {
              this.activities = this.activities.map((item) => {
                if (item.Date === moment().format("D/MM/YYYY")) {
                  if (this.punchbtn == true) {
                   

                    item.punchin.push(moment().format("hh:mm:ss A"));
                    item.works_hours = this.works_hours2;
                    item.break_hours = this.break_hours;
                    item.status = "punchin";

                    this.punch_type = "Punch In";
                    this.punch_time = moment().format("hh:mm:ss A");
                  } else {
                  

                    item.punchout.push(moment().format("hh:mm:ss A"));
                    item.status = "punchout";
                    item.works_hours = this.works_hours2;
                    item.break_hours = this.break_hours;
                    this.punch_type = "Punch Out";
                    this.punch_time = moment().format("hh:mm:ss A");
                  }
                }
                return item;
              });

              axios
                .post(
                  "/employee/attendance/activity/" +
                    this.currentMonthName +
                    "/" +
                    this.selectedYear,
                  { activities: JSON.stringify(this.activities) }
                )
                .then((response) => {
                  if (response) {
                    this.getAttendance()


                  }
                })
                .catch((error) => {
                  console.log(error.response.data);
                });

              console.log(this.activities);
            } else {
              if (this.punchbtn == true) {
                this.punchin.push(moment().format("hh:mm:ss A"));
                this.punch_status = "punchin";
                this.punch_type = "Punch In";
                this.punch_time = moment().format("hh:mm:ss A");



              } else {
                this.punchout.push(moment().format("hh:mm:ss A"));

                this.punch_status = "punchout";
                this.punch_type = "Punch Out";
                this.punch_time = moment().format("hh:mm:ss A");
              }

              var activitydata = {
                Date: moment().format("D/MM/YYYY"),
                day : moment().format("D"),

                punchin: this.punchin,

                punchout: this.punchout,

                status: this.punch_status,

                works_hours:this.works_hours2,
                break_hours:this.break_hours,
              
                over_time:this.over_time,

              };
              this.activities.push(activitydata);

              axios
                .post(
                  "/employee/attendance/activity/" +
                    this.currentMonthName +
                    "/" +
                    this.selectedYear,
                  { activities: JSON.stringify(this.activities) }
                )
                .then((response) => {
                  if (response) {
                    this.getAttendance()
                  }
                })
                .catch((error) => {
                  console.log(error.response.data);
                });

              console.log(this.activities);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (this.punchbtn == true) {
              this.punchin.push(moment().format("hh:mm:ss A"));
              this.punch_status = "punchin";
            } else {
              this.punchout.push(moment().format("hh:mm:ss A"));
              this.punch_status = "punchout";
            }

            var activitydata = {
              Date: moment().format("D/MM/YYYY"),
              day : moment().format("D"),


              punchin: this.punchin,

              punchout: this.punchout,

              status: this.punch_status,
              works_hours:this.works_hours2,
              break_hours:this.break_hours,
              over_time:this.over_time,
            };
            this.activities.push(activitydata);

            var month = moment().format("MMMM");
            var year = moment().format("YYYY");

            axios
              .post("/employee/attendance/activities", {
                month: month,
                year: year,
                activities: JSON.stringify(this.activities),
              })
              .then((response) => {
                if (response) {
                  this.getAttendance()

                }
              })
              .catch((error) => {
                console.log(error.response.data);
              });
          }
        });
    },

    getAttendance() {

      axios
        .get(
          "/employee/attendance/activity/" +
            this.currentMonthName +
            "/" +
            this.selectedYear
        )
        .then((response) => {
          if (response) {
            this.activities = JSON.parse(response.data.data.activities);

            if (
              this.activities.find(
                (el) => el.Date === moment().format("D/MM/YYYY")
              )
            ) {
              this.activities = this.activities.map((item) => {
                if (item.Date === moment().format("D/MM/YYYY")) {
                  this.single_activity = item;

                  if(item.status=="leave"){
                    this.leave=true;


                  }

                  if (item.status == "punchin") {
                    this.sum_duration=0;
                    this.sum_durationw=0
                    this.punch_type = "Punch In";
                    this.punch_time = item.punchin[item.punchin.length - 1];
                    this.punchbtn = true;
                    if (item.punchout.length > 0) {
                      for (var a in item.punchout) {
                        var index = a;
                        var sum = parseInt(index) + 1;

                        var end = moment(item.punchin[sum], "hh:mm:ss A");
                        var start = moment(item.punchout[a], "hh:mm:ss A");

                        var endw = moment(item.punchout[a], "hh:mm:ss A");
                        var startw = moment(item.punchin[a], "hh:mm:ss A");
          

                         var duration = moment.duration(end.diff(start));
                         var durationw = moment.duration(endw.diff(startw));
                      
                         this.sum_duration=this.sum_duration+duration
                         this.sum_durationw=this.sum_durationw+durationw
                         var milliseconds = this.sum_duration;
                         var millisecondsw = this.sum_durationw;
                        this.break_hours= moment.utc(milliseconds).format('HH:mm:ss');
                        this.works_hours= moment.utc(millisecondsw).format('HH:mm:ss');
                        this.works_hours2= moment.utc(millisecondsw).format('H.m');
                   
                         item.works_hours=this.works_hours2
                         item.break_hours=this.break_hours
                         this.remain_hours=8-this.works_hours2
                         if(this.works_hours2>8){
                           this.over_time=this.works_hours2-8
                         }

                   


                      }
                    }
                  } else if (item.status == "punchout") {
                    this.sum_duration=0;
                    this.sum_durationw=0


                    this.punchbtn = false;
                    this.punch_type = "Punch Out";
                    this.punch_time = item.punchout[item.punchout.length - 1];
                    if (item.punchin.length > 0) {
                      for (var a2 in item.punchout) {
                        var index2 = a2;
                        
                        var sum2 = parseInt(index2) + 1;


                        if(typeof item.punchin[sum2] != 'undefined') {

                          var end2 = moment(item.punchin[sum2], "hh:mm:ss A");
                        var start2 = moment(item.punchout[a2], "hh:mm:ss A");
                      
                      

                         var duration2 = moment.duration(end2.diff(start2));


                         this.sum_duration=this.sum_duration+duration2

                         var milliseconds2 = this.sum_duration;

                        this.break_hours= moment.utc(milliseconds2).format('HH:mm:ss');

                        

                       

                   }

                   var endw2 = moment(item.punchout[a2], "hh:mm:ss A");
                   var startw2 = moment(item.punchin[a2], "hh:mm:ss A");
                   var durationw2 = moment.duration(endw2.diff(startw2));
                   this.sum_durationw=this.sum_durationw+durationw2
                   var millisecondsw2 = this.sum_durationw;
                   this.works_hours= moment.utc(millisecondsw2).format('HH:mm:ss');
                   this.works_hours2= moment.utc(millisecondsw2).format('H.m');



                   item.works_hours=this.works_hours2
                   item.break_hours=this.break_hours
                   this.remain_hours=8-this.works_hours2
                   if(this.works_hours2>8){
                           this.over_time=this.works_hours2-8
                   }



           
                      }
                    }
                  }
                }
                return item;
              });


            
              
            } else {
              this.status = "Absent";
            }
            
            for (var i in this.activities) {
               var sum =0.0;

              sum=sum+parseFloat(this.activities[i].works_hours);

              this.week_hours=sum

              
            }

            axios.post(
                  "/employee/attendance/activity/" +
                    this.currentMonthName +
                    "/" +
                    this.selectedYear,
                  { activities: JSON.stringify(this.activities) }
                ).then((response) => {
                  if (response) {
                  console.log(response.data);
                  }
                }).catch((error) => {
                  console.log(error.response.data);
                })

          }
        })
        .catch((error) => {
          if (error.response) {
            this.status = "Absent";
          }
        });
    },
  },
  mounted: function () {
    this.current();
    this.getAttendance();
  },
};
</script>
