<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Leave Applications
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div> -->
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="leaves" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Employee Name</th>
                <th>From</th>
                <th>Till</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Leave Details</h5>
            <p></p>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div>
                <p><strong> Leave Type : </strong> {{ type }}</p>

                <p><strong> From : </strong> {{ datefrom }}</p>
                <p><strong> To : </strong> {{ dateto }}</p>

                <p><strong> Reason : </strong> {{ leave.reason }}</p>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="approve" class="btn btn-primary submit-btn">
                  Approve
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department.department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} ID(Max 2 Digit)</label
                    >
                    <input
                      i
                      type="text"
                      @input="errors.code = null"
                      v-model="department.code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
      
      <script>
import axios from "axios";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import moment from "moment";
import $ from "jquery";
import Api from "@/other/Api";
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Emaployee Leave",
      errors: {},
      leaves: [],
      positionid: JSON.parse(localStorage.getItem("user")).user.position.id,

      job_demand: "",
      uuid: "",
      department: "",
      leave: {},
      employee: {},
      basic_information: "",
      monthname: "",
      year: "",
      datefrom: "",
      dateto: "",
      employeeid: "",
      attandances: "",
      onlydatefrom: "",
      onlydateto: "",
      onlymonthfrom: "",
      type: "",
      reason: "",
    };
  },

  methods: {
    list() {
      axios
        .get(Api.company.leaves_employee + this.positionid)
        .then((response) => {
          if (response) {
            var leaves = response.data.data;
            for (var leave of leaves) {
              // var uuid=leave.uuid;
              var id = leave.id;
              var emaployee_name =
                JSON.parse(leave.employee.basic_information).first_name +
                " " +
                JSON.parse(leave.employee.basic_information).last_name;
              var from = leave.from;
              var to = leave.to;
              var status =
                leave.status == null
                  ? "Pending"
                  : leave.status == 1
                  ? "Approved"
                  : "Rejected";
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                id +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#addModal'><i class='fas fa-eye'></i></a> </a>";

              this.leaves.push([id, emaployee_name, from, to, status, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleClick(id) {
      this.getLeaveDetails(id);
    },

    add() {
      axios
        .post("/company/grades", {
          grade_name: this.grade_name,
          code: this.code,
        })
        .then((response) => {
          if (response) {
            this.grade_name = "";
            this.code = "";
            this.getgrades();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },

    getLeaveDetails(id) {
      axios
        .get("/employee/leaves/" + id)
        .then((response) => {
          if (response) {
            this.leave = response.data.data;
            this.type = this.leave.leave.type;
            this.reason = this.leave.leave.reason;
            this.employee = response.data.data.employee;
            this.employeeid = this.employee.id;
            this.basic_information =
              response.data.data.employee.basic_information;
            this.monthname = moment(this.leave.from).format("MMMM");
            this.year = moment(this.leave.from).format("YYYY");
            this.datefrom = moment(this.leave.from).format("D/MM/YYYY");
            this.dateto = moment(this.leave.to).format("D/MM/YYYY");
            this.onlydatefrom = moment(this.leave.from).format("D");
            this.onlydateto = moment(this.leave.to).format("D");
            this.onlymonthfrom = moment(this.leave.from).format("MM");

            this.getAttendance();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAttendance() {
      axios
        .get(
          "/employee/attendances/activity/getbymonthid/" +
            this.monthname +
            "/" +
            this.year +
            "/" +
            this.employeeid
        )
        .then((response) => {
          if (response) {
            this.attendances = JSON.parse(response.data.data.activities);

            this.uuid = response.data.data.uuid;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    giveattendance() {
      if (this.attendances) {


        for (
            let index = this.onlydatefrom;
            index <= this.onlydateto;
            index++
          ) {
            var date = index + "/" + this.onlymonthfrom + "/" + this.year;

            var activitydata = {
                Date: date,
                day : index,

                punchin:"", 

                punchout: "",

                status: "leave",

                works_hours:"",
                break_hours:"",
              
                over_time:"",

              };

             this.attendances.push(activitydata);


          }


      }

      axios
        .post("/employee/attendances/activity/updateBySenior/" +
            this.monthname +
            "/" +
            this.year +
            "/" +
            this.uuid, {
         
            activities: JSON.stringify(this.attendances),
        })
        .then((response) => {
          if (response) {
            alert(response.data.message);
          }
        })
        .catch((error) => {
         console.log(error);
        });
    },
   

    approve() {
      axios
        .get("employee/leave/approve/"+this.leave.id)
        .then((response) => {
          if (response) {
            alert(response.data.message);
            this.giveattendance();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });

    },
    reject() {
      axios
        .put("/company/jobdemands/approval/"+this.uuid,{status:0})
        .then((response) => {
          if (response) {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },



  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    window.handleClick = this.handleClick;
  },
};
</script>
      
      
            
         <style>
@import "datatables.net-bs5";
</style>