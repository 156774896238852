<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Bills</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Bills
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <!-- <th style="width: 30px;">#</th> -->
                  <th>Visiting ID</th>
                  <th>Visiting Date</th>
                  <th>Approve Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1001</td>

                  <td>2023-02-20</td>

                  <td>2023-02-20</td>

                  <td>
                    <p style="color: green; font-weight: 600">pendding</p>
                  </td>

                  <td class="ico-sec">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#addModal"><i class="fas fa-eye"></i></a>
                      <a href="#" data-bs-toggle="modal" data-bs-target="#addModal"><i class="fas fa-file-invoice-dollar"></i></a>

                                                                                          
                  </td>
                </tr>
                <tr>
                  <td>1002</td>
                  <td>2023-02-20</td>
                  <td>2023-02-20</td>

                  <td>
                    <p style="color: green; font-weight: 600">Approved</p>
                  </td>
                  <td class="ico-sec">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#addModal"><i class="fas fa-eye"></i></a>
                      <a href="#" data-bs-toggle="modal" data-bs-target="#addModal"><i class="fas fa-file-invoice-dollar"></i></a>

                                                                                          
                  </td>

                  <!-- <td class="ico-sec">
                    <a
                      href="#"
                      class="btn btn-primary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#addModal"
                    >
                      View
                    </a>
                    <a
                      href="#"
                      class="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#addModal"
                    >
                      Submit Bill
                    </a>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Bill</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form id="billDetailsForm">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="billNumber">Bill Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="billNumber"
                      value="12345"
                      readonly
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="billDate">Bill Date</label>
                    <input
                      type="date"
                      class="form-control"
                      id="billDate"
                      value="2024-05-01"
                      readonly
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="billDate">Visiting Date</label>
                    <input
                      type="date"
                      class="form-control"
                      id="billDate"
                      value="2024-05-01"
                      readonly
                    />
                  </div>
                </div>
                <!-- <div class="form-group">
                <label for="billDescription">Description</label>
                <textarea class="form-control" id="billDescription" rows="3" readonly>Sample description of the bill</textarea>
            </div> -->
                <div class="col-md-12">
                  <table class="table-bordered mt-4 table">
                    <thead class="thead-light">
                      <tr style="padding: 20px">
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Transport Type</th>

                        <th scope="col">Upload Document</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="time"
                            class="form-control"
                            id="fromTime"
                            value="09:00"
                            readonly
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            class="form-control"
                            id="toTime"
                            value="17:00"
                            readonly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="transportationType"
                            value="Car"
                            readonly
                          />
                        </td>

                        <td class="text-center">
                          <label for="fileUpload" class="upload-icon">
                            <input
                              type="file"
                              id="fileUpload"
                              style="display: none"
                            />
                            <i class="fas fa-upload"></i>
                            <i
                              class="fas fa-spinner fa-spin upload-loader"
                              style="display: none"
                            ></i>
                          </label>
                        </td>

                        <td>
                          <input
                            type="number"
                            class="form-control"
                            id="billAmount"
                            required
                          />
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          <input
                            type="time"
                            class="form-control"
                            id="fromTime"
                            value="09:00"
                            readonly
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            class="form-control"
                            id="toTime"
                            value="17:00"
                            readonly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="transportationType"
                            value="Car"
                            readonly
                          />
                        </td>

                        <td>
                          <label for="fileUpload" class="upload-icon">
                            <input
                              type="file"
                              id="fileUpload"
                              style="display: none"
                            />
                            <i class="fas fa-upload"></i>
                            <i
                              class="fas fa-spinner fa-spin upload-loader"
                              style="display: none"
                            ></i>
                          </label>
                        </td>

                        <td>
                          <input
                            type="number"
                            class="form-control"
                            id="billAmount"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="col-md-12"
                  style="margin-top: 20px; margin-bottom: 10px"
                >
                  <div class="form-group row justify-content-end">
                    <label for="totalAmount" class="col-sm-3 col-form-label"
                      >Total Amount:
                    </label>
                    <div class="col-sm-4">
                      <input
                        type="number"
                        class="form-control"
                        id="totalAmount"
                        value="100"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-12"
                  style="margin-top: 20px; margin-bottom: 10px"
                >
                  <div class="form-group row justify-content-end">
                    <label for="totalAmount" class="col-sm-3 col-form-label"
                      >Amount in Words:
                    </label>
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        id="amountInWords"
                        value="One hundred taka"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 text-end">
                  <button type="submit" class="btn btn-primary">
                    Submit Bill
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="Barry" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Cuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="barrycuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="barrycuda@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="CLT-0001"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      value="9876543210"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Global Technologies"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      name: "",
      items: [],
      errors: {},
      modules: [],
      employeemodules: [],
      selectedModulesID: [],
      selectedEmployeeModulesID: [],
      prices: [],
    };
  },

  methods: {
    addItem() {
      var item = {
        item: this.item,
        quantity: this.quantity,
        amount: this.amount,
        total: this.quantity * this.amount,
      };
      this.items.push(item);
    },

    //Adding price to database

    addPrice() {
      axios
        .post("/superadmin/prices", {
          name: this.name,
          price: this.price,
          duration: this.duration,
          // features: JSON.stringify(Object.assign({}, this.features))
          features: JSON.stringify(this.features),
          modules: this.selectedModulesID,
          employeemodules: this.selectedEmployeeModulesID,
        })
        .then((response) => {
          if (response) {
            (this.name = ""),
              (this.price = ""),
              (this.duration = ""),
              (this.features = []);
            $("#addModal .close").click();
            this.getPrice();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getModule() {
      axios
        .get("/superadmin/company_modules")
        .then((response) => {
          if (response) {
            this.modules = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyModule() {
      axios
        .get("/superadmin/employee_modules")
        .then((response) => {
          if (response) {
            this.employeemodules = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrice() {
      axios
        .get("/superadmin/prices")
        .then((response) => {
          if (response) {
            var prices = response.data.data;

            for (var priceplan of prices) {
              var uuid = priceplan.uuid;
              var name = priceplan.name;
              var price = priceplan.price;
              var duration = priceplan.duration;
              var status = "";
              var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")'  ><i class=' ms-3 far fa-eye'></i></a>";

              this.prices.push([name, price, duration, status, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleClick(uuid) {
      this.$router.push({
        name: "admin-companyprofile",
        query: { uuid: uuid },
      });
    },
  },

  mounted: function () {
    this.getModule();
    this.getCompanyModule();
    this.getPrice();
    window.handleClick = this.handleClick;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
