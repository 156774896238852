<template>
    <div class="wrapper">
      <!-- Page Content -->
      <div class="content-wrapper p-4 ">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>{{ title }}</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Interview Schedules
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Job Title" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Candidate Name" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="date" class="form-control" placeholder="Date" />
              </div>
            </div>
           
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-plus"></i> Add Schedule</a
              >
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable
              :data="interview_schedules"
              class="table table-striped custom-table"
            >
              <thead>
                <tr>
                    
                            <th>Job Title</th>

                            <th>Candidate Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Action</th>
                          </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Select job<span class="text-danger">*</span></label
                      >
                      

                        <select
           v-model="job_id" @change="getCandidate(job_id)" class="form-control form-select"
         >
           <option disabled value=null>Select a job</option>
           <option v-for="job in publishedjobs" :key="job" :value="job.id" >{{ job.position.position_name }}</option>

        
         </select>
         <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                        
                     
           
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Select a candidate<span class="text-danger">*</span></label
                      >
                     


                        <select
           v-model="applicent_id" class="form-control form-select"
         >
           <option disabled value=null>Select a candidate</option>
           <option v-for="candidate in shortlist" :key="candidate.id" :value="candidate.id" >{{ candidate.applicant_name }}</option>

         
        
         </select>
         <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                        
                   
           
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Date</label
                      >
                      <input type="date"  @input="errors.date=null" v-model="date" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.date" >{{ errors.date[0] }}</p>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Time</label
                      >
                      <input type="time"  @input="errors.time=null" v-model="time" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.time" >{{ errors.time[0] }}</p>

                    </div>
                  </div>
                 
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="add"
                    class="btn btn-primary submit-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="editModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} name <span class="text-danger">*</span></label
                      >
                      <input
                        id="okk"
                        type="text"
                        @input="errors.department_name = null"
                        v-model="department.department_name"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.department_name">
                        {{ errors.department_name[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} ID(Max 2 Digit)</label
                      >
                      <input i type="text"  @input="errors.code=null" v-model="department.code" class="form-control" />
                      <p class="text-danger mt-1" v-if="errors.code" >{{ errors.code[0] }}</p>

                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="update(department.uuid)"
                    class="btn btn-primary submit-btn"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
  
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a
      
                      data-bs-dismiss="modal"
            
                   
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
      
      <script>
  import axios from "axios";
  //   import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
  import $ from "jquery";
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
        title: "Interview Schedule",
        weekends: [],
        errors: {},
        department: {},
        day:null,
        uuid:"",
        publishedjobs: [],
        shortlist:[],
        job_id:"",
        applicent_id:"",
        interview_schedules:[],
      };
    },
  
    methods: {
  

      getPublishedJob() {
      axios

        .get("company/publishedjob")
        .then((response) => {
          this.publishedjobs = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    list(){
      axios.get("/company/jobapplication/interview/schedule").then((response) => {

       var interview_schedules = response.data.data

       for (var interview_schedule of interview_schedules) {
            // var id = interview_schedule.id;
            var uuid = interview_schedule.uuid;
            var job = interview_schedule.employee_job_demand.position.position_name;
            var candidate = interview_schedule.company_job_application.applicant_name;
            var date =interview_schedule.date;
            var time = interview_schedule.time;

            var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#viewModal'><i class='fas fa-eye'></i></a>   <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#addModal'><i class=' ms-3 fas fa-pen'></i></a>  <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

            this.interview_schedules.push([
           
              job,
              candidate,
              date,
              time,
              action,
            ]);
          }




        
      }).catch((error) => {
        console.log(error);


      });



    },


      add() {
        axios
        .post("/company/jobapplication/interview/schedule", {
          employee_job_demand_id: this.job_id,
          company_job_application_id: this.applicent_id,
          date: this.date,
          time: this.time,
        }) 
        .then((response) => {
          if (response) {
              $("#addModal .close").click()
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
      },
      update(uuid) {
        axios
          .put("/company/departments/" + uuid, {
            department_name: this.department.department_name,
            d_id: this.department.d_id,
          })
          .then((response) => {
            if (response) {
              this.list();
  
              $("#editModal .close").click();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      delete_single() {
        axios
          .delete("/company/departments/" + this.uuid)
          .then((response) => {
            if (response) {
              $("#deleteModal.close").click();
              this.list();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      

      getCandidate() {
        axios
        .get("company/jobapplication/shortlist/"+this.job_id)
        .then((response) => {
          var applications = response.data.data;


          for (var application of applications) {
            var id = application.id;
            var applicant_name = application.applicant_name;
            var position =
              application.employee_job_demand.position.position_name;
         
            var created_at = application.created_at;

    

            this.shortlist.push({
              id,
              applicant_name,
              position,
              created_at,
          });
          }



        })
        .catch((error) => {
          console.log(error);
        });
    },

      edit(id) {
        axios
          .get("/company/departments/" + id)
          .then((response) => {
            if (response) {
              this.department = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deletePopUp(uuid) {
      this.uuid=uuid
    
  
      },

    




    },
  
    mounted: function () {
      this.name = JSON.parse(localStorage.getItem("user")).user.name;
      window.edit = this.edit;
      window.deletePopUp = this.deletePopUp;
      this.getPublishedJob();
      this.list();

    },
  };
  </script>
      
      
            
         <style>
  @import "datatables.net-bs5";
  </style>