<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Job Demands
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Department" />
              </div>
            </div>
            <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Employee Name" />
              </div>
            </div> -->
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
          
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add {{ title }}</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-7">
          <DataTable
            :data="pending_job"
            class="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Department</th>
                <th>Title/Position</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
        <div class="col-md-5">
          <DataTable
            :data="published_job"
            class="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Department</th>
                <th>Title/Position</th>
                <th>Quantity</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Weekend<span class="text-danger">*</span></label
                    >
                   
                      <select v-model="day" class="form-control form-select">
                        <option disabled value="null">Select a day</option>
                        <option>Saturday</option>
                        <option>Sunday</option>
                        <option>Monday</option>
                        <option>Tuesday</option>
                        <option>Wednesday</option>
                        <option>Thursday</option>
                        <option>Friday</option>
                      </select>
                      <p class="text-danger mt-1" v-if="errors.day">
                        {{ errors.day[0] }}
                      </p>
                    
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Note</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="note"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Work Rate (ex:10%) </label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="rate"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="viewModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Job Demand</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Department</label>
                    <div>
                      <select 
                        v-model="job_demand.department_id"
                        @input="clearError('department_id')"                        
                        class="form-control form-select"
                        style="width: 100%"
                        @change="getposition(job_demand.department_id)"
                      >
                        <!-- <option disabled value="null">Select a department</option> -->
                        <option
                          v-for="department in departments"
                          :key="department"
                          :value="department.id"
                        >
                          {{ department.department_name }}
                        </option>
                      </select>
                      <sapn id="department_id" class="error"></sapn>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Position</label>
                    <div>
                      <select
                        v-model="job_demand.position_id"
                        @input="clearError('position_id')"
                        class="form-control form-select"
                        style="width: 100%"
                      >
                        <option disabled value="null">Select a position</option>
                        <option
                          v-for="position in positions"
                          :key="position"
                          :value="position.id"
                        >
                          {{ position.position_name }}
                        </option>
                      </select>
                      <sapn id="position_id" class="error"></sapn>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="col-form-label">No of position</label>
                    <input
                      v-model="job_demand.quantity"
                      @input="clearError('quantity')"
                      type="text"
                      class="form-control"
                    />
                    <sapn id="quantity" class="error"></sapn>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">When to recruite</label>
                    <input
                      v-model="job_demand.recruitement_date"
                      @input="clearError('recruitement_date')"
                      type="date"
                      class="form-control"
                    />
                    <sapn id="recruitement_date" class="error"></sapn>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Salary Range</label>
                    <input
                      v-model="salary_range"
                      @input="errors.salary_range=null"
                      type="text"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.salary_range">
                      {{ errors.salary_range[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Job Type</label>

                    <div>
                      <select
                        v-model="job_type"
                        class="form-control form-select"
                        style="width: 100%"
                      >
                        <option disabled value="">Select a type</option>
                        -->
                        <option value="Entry">Intern</option>
                        -->
                        <option value="Entry">Parttime</option>
                        -->
                        <option value="Intermediate">FullTime</option>
                        -->
                      </select>
                    </div>
                    <sapn id="department_id" class="error"></sapn>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Exprience level</label>

                    <div>
                      <select
                        v-model="exprience_level"
                        class="form-control form-select"
                        style="width: 100%"
                      >
                        <option disabled value="">Select a level</option>
                        -->
                        <option value="Entry">Entry</option>
                        -->
                        <option value="Intermediate">Intermediate</option>
                        -->
                        <option value="Mid">Mid</option>
                        -->
                        <option value="Expart">Expart</option>
                        -->
                      </select>
                    </div>
                    <sapn id="department_id" class="error"></sapn>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Work Place</label>

                    <div>
                      <select
                        v-model="work_place"
                        class="form-control form-select"
                        style="width: 100%"
                      >
                        <option disabled value="">Select a place</option>
                        -->
                        <option value="Onsite">Onsite</option>
                        -->
                        <option value="Remote">Remote</option>
                        -->
                        <option value="Hybrid">Hybrid</option>
                        -->
                      </select>
                    </div>
                    <sapn id="department_id" class="error"></sapn>
                  </div>
                </div>
                <div class="col-lg-12">
                <div class="form-group">
                  <label>Job Discription</label>
                  <ckeditor

                    :editor="editor"
                    v-model="job_demand.job_description"
                
                  ></ckeditor>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="form-group">
                  <label>Qualification</label>
                  <ckeditor
                    style="height: 100px !important"
                    :editor="editor"
                    v-model="job_demand.qualification"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>

              <div class="col-lg-5">
                <div class="form-group">
                  <label>Others</label>
                  <ckeditor
                    style="height: 100px !important"
                    :editor="editor"
                    v-model="others"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>


              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="publishjobdemand"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
      
      <script>
import $ from "jquery";
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Api from "@/other/Api";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Job Demands",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
        // The configuration of the editor.
      },
      job_demands: [],
      published_job: [],
      pending_job: [],

      positions: [],
      departments: [],
      job_demand: "",
      exprience_level: "",
      place: "",
      job_type: "",
      uuid: "",
      errors: {},
      department: {},
      work_place: "",
      day: null,
    };
  },

  methods: {
    list() {
      axios
        .get("/company/jobdemands")
        .then((response) => {
          if (response) {
            var job_demands = response.data.data;

            this.job_demands = [];
            this.pending_job = [];
            this.published_job = [];

            for (var job_demand of job_demands) {
              var id = job_demand.uuid;
              var position_name =
                job_demand.position != null
                  ? job_demand.position.position_name
                  : "";
              var department_name =
                job_demand.department != null
                  ? job_demand.department.department_name
                  : "";

              var quantity = job_demand.quantity;
              var status =
                job_demand.status == null
                  ? "Pending"
                  : job_demand.status == 3
                  ? "Published"
                  : "Rejected";
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                id +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#viewModal'><i class='fas fa-eye'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"';

              this.job_demands.push([
                department_name,
                position_name,
                quantity,
                status,
                action,
              ]);

              if (job_demand.status == null) {
                this.pending_job.push([
                  department_name,
                  position_name,
                  quantity,
                  status,
                  action,
                ]);
              }

              if (job_demand.status === 3) {
                this.published_job.push([
                  department_name,
                  position_name,
                  quantity,
                  status,
                  action,
                ]);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleClick(id) {
      this.getjobdemanddetails(id);
      this.uuid = id;

      // this.$router.push({ name: "jobdetails", query: { uuid: id } });
    },

    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            this.departments = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getjobdemanddetails(uuid) {
      axios
        .get("/company/jobdemands/" + uuid)
        .then((response) => {
          if (response) {
            this.job_demand = response.data.data;
            this.department = response.data.data.department;

            this.getposition(this.job_demand.department_id);
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    publishjobdemand() {
      axios
        .post("/company/jobdemands/published/" + this.uuid, {
          department_id: this.job_demand.department_id,
          position_id: this.job_demand.position_id,
          quantity: this.job_demand.quantity,
          recruitement_date: this.job_demand.recruitement_date,
          salary_range: this.salary_range,
          exprience_level: this.exprience_level,
          job_type: this.job_type,
          work_place: this.work_place,
          job_description: this.job_demand.job_description,
          qualification: this.job_demand.qualification,
          others: this.others,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#viewModal .close").click();

          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    getposition(department_id) {
      axios
        .get(Api.company.departmentPostion + department_id)
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
  },

  mounted: function () {
    this.list();
    this.getdepartment();
    window.handleClick = this.handleClick;
  },
};
</script>
      
      
            
         <style>
@import "datatables.net-bs5";

.ck-content {
  min-height: 150px !important;
}
</style>