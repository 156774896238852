<template>
  <div class="page-wrapper">
    <!-- All Modal -->

    <div id="auth_info_modal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Authorised Person</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>

                    <input class="form-control" type="email" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Designation</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Phone no <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Page Content -->

    <div class="content container-fluid pb-0">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Profile</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="dashboard">Dashboard</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="card mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="profile-view">
                <div class="profile-img-wrap">
                  <div class="profile-img">
                    <a href="">
                      <img :src="logourl" alt="" />
                    </a>
                  </div>
                </div>
                <div class="profile-basic">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="profile-info-left">
                        <h3 class="user-name m-t-0">New employee</h3>

                        <small class="text-muted">Technology</small>
                        <div class="staff-id">Company ID : CLT-0001</div>
                        <div class="staff-msg">
                          <a href="apps-chat" class="btn btn-custom">Update</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="personal-info">
                        <li>
                          <span class="title">Phone:</span>
                          <span class="text"><a href="">9876543210</a></span>
                        </li>
                        <li>
                          <span class="title">Email:</span>
                          <span class="text"
                            ><a href="">{{
                              basic_information.sigin_email
                            }}</a></span
                          >
                        </li>
                        <li>
                          <span class="title">Birthday:</span>
                          <span class="text">2nd August</span>
                        </li>
                        <li>
                          <span class="title">Address:</span>
                          <span class="text"
                            >5754 Airport Rd, Coosada, AL, 36020</span
                          >
                        </li>
                        <li>
                          <span class="title">Price Plan:</span>
                          <span class="text">{{ priceplan }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card tab-box mt-3">
        <div class="row user-tabs">
          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
            <ul class="nav nav-tabs nav-tabs-bottom">
              <li class="nav-item col-sm-3">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#com_profile"
                  >Other info</a
                >
              </li>
              <!-- <li class="nav-item col-sm-3"><a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a></li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content profile-tab-content">
            <div
              id="com_profile"
              class="pro-overview tab-pane fade show active"
            >
              <div class="row">
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">
                        Authorised Person
                        <a
                          href="#"
                          class="edit-icon float-right"
                          data-bs-toggle="modal"
                          data-bs-target="#auth_info_modal"
                          ><i class="fas color-primary fa-pen"></i
                        ></a>
                      </h3>
                      <ul class="personal-info">
                        <li>
                          <div class="title">Name</div>
                          <div
                            v-if="auth_person.auth_first_name != null"
                            class="text"
                          >
                            {{ auth_person.auth_first_name }}
                            {{ auth_person.auth_last_name }}
                          </div>

                          <div v-else class="text">NaN</div>
                        </li>
                        <li>
                          <div class="title">Email</div>
                          <div
                            v-if="auth_person.auth_email != null"
                            class="text"
                          >
                            {{ auth_person.auth_email }}
                          </div>

                          <div v-else class="text">NaN</div>
                        </li>
                        <li>
                          <div class="title">Designation</div>
                          <div
                            v-if="auth_person.auth_designation != null"
                            class="text"
                          >
                            {{ auth_person.auth_designation }}
                          </div>

                          <div v-else class="text">NaN</div>
                        </li>
                        <li>
                          <div class="title">Phone No</div>
                          <div
                            v-if="auth_person.auth_designation != null"
                            class="text"
                          >
                            {{ auth_person.auth_designation }}
                          </div>

                          <div v-else class="text">NaN</div>
                        </li>
                        <li>
                          <div class="title">Company Name</div>
                          <div class="text">
                            {{ basic_information.company_name }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">
                        Address
                        <a
                          href="#"
                          class="edit-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#emergency_contact_modal"
                          ><i class="fa fa-pencil"></i
                        ></a>
                      </h3>
                      <h5 class="section-title">Primary</h5>
                      <ul class="personal-info">
                        <li>
                          <div class="title">Name</div>
                          <div class="text">Afjal Morshed</div>
                        </li>
                        <li>
                          <div class="title">Relationship</div>
                          <div class="text">Father</div>
                        </li>
                        <li>
                          <div class="title">Phone</div>
                          <div class="text">01755636547</div>
                        </li>
                      </ul>
                      <hr />
                      <h5 class="section-title">Secondary</h5>
                      <ul class="personal-info">
                        <li>
                          <div class="title">Name</div>
                          <div class="text">Irfan Jahan Morshed</div>
                        </li>
                        <li>
                          <div class="title">Relationship</div>
                          <div class="text">Brother</div>
                        </li>
                        <li>
                          <div class="title">Phone</div>
                          <div class="text">01685697453</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">Bank information</h3>
                      <ul class="personal-info">
                        <li>
                          <div class="title">Bank name</div>
                          <div class="text">IFIC Bank</div>
                        </li>
                        <li>
                          <div class="title">Bank account No.</div>
                          <div class="text">159843014641</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">
                        Family Informations
                        <a
                          href="#"
                          class="edit-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#family_info_modal"
                          ><i class="fa fa-pencil"></i
                        ></a>
                      </h3>
                      <div class="table-responsive">
                        <table class="table table-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Relationship</th>
                              <th>Date of Birth</th>
                              <th>Phone</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Irfan Jahan Morshed</td>
                              <td>Brother</td>
                              <td>Feb 16th, 1990</td>
                              <td>01685697453</td>
                              <td class="text-end">
                                <div class="dropdown dropdown-action">
                                  <a
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                    class="action-icon dropdown-toggle"
                                    href="#"
                                    ><i class="material-icons">more_vert</i></a
                                  >
                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a href="#" class="dropdown-item"
                                      ><i class="fa fa-pencil m-r-5"></i>
                                      Edit</a
                                    >
                                    <a href="#" class="dropdown-item"
                                      ><i class="fa fa-trash-o m-r-5"></i>
                                      Delete</a
                                    >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">
                        Education Informations
                        <a
                          href="#"
                          class="edit-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#education_info"
                          ><i class="fa fa-pencil"></i
                        ></a>
                      </h3>
                      <div class="experience-box">
                        <ul class="experience-list">
                          <li>
                            <div class="experience-user">
                              <div class="before-circle"></div>
                            </div>
                            <div class="experience-content">
                              <div class="timeline-content">
                                <a href="#/" class="name"
                                  >Khulna University of Engineering &
                                  Technology</a
                                >
                                <div>Bsc Computer Science</div>
                                <span class="time">1998 - 2003</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="experience-user">
                              <div class="before-circle"></div>
                            </div>
                            <div class="experience-content">
                              <div class="timeline-content">
                                <a href="#/" class="name"
                                  >International College of Arts and Science
                                  (PG)</a
                                >
                                <div>Msc Computer Science</div>
                                <span class="time">2004 - 2006</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="card profile-box flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">
                        Experience
                        <a
                          href="#"
                          class="edit-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#experience_info"
                          ><i class="fa fa-pencil"></i
                        ></a>
                      </h3>
                      <div class="experience-box">
                        <ul class="experience-list">
                          <li>
                            <div class="experience-user">
                              <div class="before-circle"></div>
                            </div>
                            <div class="experience-content">
                              <div class="timeline-content">
                                <a href="#/" class="name"
                                  >Web Designer at Zen Corporation</a
                                >
                                <span class="time"
                                  >Jan 2013 - Present (5 years 2 months)</span
                                >
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="experience-user">
                              <div class="before-circle"></div>
                            </div>
                            <div class="experience-content">
                              <div class="timeline-content">
                                <a href="#/" class="name"
                                  >Web Designer at Ron-tech</a
                                >
                                <span class="time"
                                  >Jan 2013 - Present (5 years 2 months)</span
                                >
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="experience-user">
                              <div class="before-circle"></div>
                            </div>
                            <div class="experience-content">
                              <div class="timeline-content">
                                <a href="#/" class="name"
                                  >Web Designer at Dalt Technology</a
                                >
                                <span class="time"
                                  >Jan 2013 - Present (5 years 2 months)</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- /Projects Tab -->

            <!-- Task Tab -->
            <div id="tasks" class="tab-pane fade">
              <div class="project-task">
                <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      href="#all_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="true"
                      >All Tasks</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#pending_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="false"
                      >Pending Tasks</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#completed_tasks"
                      data-bs-toggle="tab"
                      aria-expanded="false"
                      >Completed Tasks</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="all_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label"
                                  >Doctor available module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient Profile add</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="pending_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul class="task-lists" id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient Profile add</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="completed_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul class="completes-task-list" id="task-list">
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient appointment booking</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Appointment booking with payment
                                  gateway</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Patient and Doctor video conferencing</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span
                                    class="action-circle large complete-btn"
                                    title="Mark Complete"
                                  >
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true"
                                  >Private chat module</span
                                >
                                <span class="task-action-btn task-btn-right">
                                  <span
                                    class="action-circle large"
                                    title="Assign"
                                  >
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span
                                    class="action-circle large delete-btn"
                                    title="Delete Task"
                                  >
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea
                              id="new-task"
                              placeholder="Enter new task here. . ."
                            ></textarea>
                            <span class="error-message hidden"
                              >You need to enter a task first</span
                            >
                            <span class="add-new-task-btn btn" id="add-task"
                              >Add Task</span
                            >
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Task Tab -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>

<script>
// import $ from "jquery";
import axios from "axios";
import Constant from "@/other/Constant";

export default {
  data() {
    return {
      errors: {},
      profile: {},
      logourl: "",
      basic_information: {},
      priceplan: "",

      auth_person: {},
      key_person: "",
      levelone: "",
      company_address: {},
      trade_day: "",
      company_categories: "",
      company_sectors: "",
      countries: "",
      company_hour: {},
    };
  },
  methods: {
    getProfile(uuid) {
      if (uuid) {
        axios
          .get("company/profiles/" + uuid)
          .then((response) => {
            if (response) {
              this.profile = response.data.company;
              this.priceplan = this.profile.price.price.name;
              if (this.profile.basic_information != null) {
                this.basic_information = JSON.parse(
                  this.profile.basic_information
                );
              }
              if (this.profile.logo != null) {
                this.logourl =
                  Constant.filebaseurl + this.profile.logo.file.path;
              }

              if (this.profile.authorised_person_details != null) {
                this.auth_person = JSON.parse(
                  this.profile.authorised_person_details
                );
              }
              if (this.profile.company_address != null) {
                this.company_address = JSON.parse(this.profile.company_address);
              }
              if (this.profile.trading_hour != null) {
                this.company_hour = JSON.parse(this.profile.trading_hour);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted: function () {
    this.uuid = JSON.parse(localStorage.getItem("user")).user.uuid;
    if (this.uuid) {
      this.getProfile(this.uuid);
    }
  },
};
</script>
