<template>
    <aside class="main-sidebar sidebar-light-primary elevation-2">
    <!-- Brand Logo -->
    <a href="" class="brand-link text-center">
      <img
        class="pb-3"
        src="/assets/img/logo_color.png"
        width="140px"
        height="100px"
        alt=""
      />
    </a>

    
    <!-- <div class="user-panel  py-3  d-flex">
        <div class="image">
          <img height="70px" width="50px" style="border-radius: 50%;" :src="logourl" class="ml-3" alt="User Image">
        </div>
        <div class="info ml-2">
          <a href="#" style="color:gray" class="d-block">{{name}}</a>
        </div>
       
      </div>
   -->

    <!-- Sidebar -->
    <div class="sidebar mt-2">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'admin-dashboard' }">
              <i class="nav-icon fas fa-tachometer-alt primary_color"></i>
              <p>Dashboard</p>
            </router-link>
          </li>


          <li class="nav-item  ">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                how_to_reg
              </span>

              <p>
                Companies
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-companylist' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>
                  Registerd Companies
                </router-link>
              </li>
            
           
           
          
             
            </ul>
          </li>
       

          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                how_to_reg
              </span>

              <p>
                Price
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-priceplan' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>
                  Price Plan
                </router-link>
              </li>
            
           
           
          
             
            </ul>
          </li>
       
          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Countries
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-country' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Country
                </router-link>
              </li>
            
           
         
             

            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Module
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-company-module' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Company Module
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-employee-module' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Employee Module
                </router-link>
              </li>
              
         
             

            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link">
              <span class="nav-icon material-symbols-outlined primary_color">
                person_search
              </span>
              <p>
                Tax
                <i class="fas fa-angle-left right menuicon"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'admin-taxrule' }"
                >
                  <i class="far fa-circle nav-icon fs80 ml-3 sc"></i>Tax Rule
                </router-link>
              </li>
        
             

            </ul>
          </li>
       
          
    
        




    

          


        
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {};
</script>

<style>
</style>