<template>
    <div class="wrapper">
            
            <!-- Page Content -->
            <div  class="content-wrapper   p-4 ">

                <!-- Page Header -->
                <div class="row ">
                    <div class="col-md-12">
                        <div class="page-head-box  ">
                            <h3>Leads</h3>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="dashboard">Dashboard</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Leads</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table w-100 table-striped table-nowrap custom-table mb-0 datatable">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Lead Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Project</th>
                                        <th>Assigned Staff</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <th class="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"></a>
                                                <a href="#">Ejaz Wahid</a>
                                            </h2>
                                        </td>
                                        <td>e.wahid@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Hospital Administration</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li class="dropdown avatar-dropdown">
                                                    <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <div class="avatar-group">
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg">
                                                            </a>
                                                            <a class="avatar avatar-xs" href="#">
                                                                <img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg">
                                                            </a>
                                                        </div>
                                                        <div class="avatar-pagination">
                                                            <ul class="pagination">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="visually-hidden">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                    <span class="visually-hidden">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li> 
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>10 hours ago</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"></a>
                                                <a href="#">Alamin Hossain</a>
                                            </h2>
                                        </td>
                                        <td>a.hossain@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Video Calling App</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>5 Mar 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                <a href="#">Sabbir Khan</a>
                                            </h2>
                                        </td>
                                        <td>s.khan@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Office Management</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>27 Feb 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"></a>
                                                <a href="#">Ejaz Wahid</a>
                                            </h2>
                                        </td>
                                        <td>e.wahid@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Hospital Administration</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>10 hours ago</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"></a>
                                                <a href="#">Alamin Hossain</a>
                                            </h2>
                                        </td>
                                        <td>a.hossain@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Video Calling App</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>5 Mar 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>06</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                <a href="#">Sabbir Khan</a>
                                            </h2>
                                        </td>
                                        <td>s.khan@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Office Management</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>27 Feb 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>07</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"></a>
                                                <a href="#">Ejaz Wahid</a>
                                            </h2>
                                        </td>
                                        <td>e.wahid@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Hospital Administration</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>10 hours ago</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>08</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"></a>
                                                <a href="#">Alamin Hossain</a>
                                            </h2>
                                        </td>
                                        <td>a.hossain@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Video Calling App</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>5 Mar 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>09</td>
                                        <td>
                                            <h2 class="table-avatar">
                                                <a href="#" class="avatar"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"></a>
                                                <a href="#">Sabbir Khan</a>
                                            </h2>
                                        </td>
                                        <td>s.khan@dfa.com</td>
                                        <td>9876543210</td>
                                        <td><a href="project-view.html">Office Management</a></td>
                                        <td>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" title="Sarwar Jahan Morshed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"><img alt="" src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"></a>
                                                </li>
                                                <li>
                                                    <a href="#" class="all-users">+15</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span class="role-info role-bg-two">Working</span></td>
                                        <td>27 Feb 2023</td>
                                        <td class="text-end ico-sec">
                                            <a href="#"><i class="fas fa-pen"></i></a>
                                            <a href="#"><i class="far fa-trash-alt"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Page Content -->
            
        </div>
     
   </template>
       
       <script>
   import axios from "axios";
   //   import Constant from "@/other/Constant";
 //   import DataTable from "datatables.net-vue3";
 //   import DataTablesCore from "datatables.net-bs5";
   import $ from "jquery";
   
 //   DataTable.use(DataTablesCore);
   
   export default {
     // components: { DataTable },
   
     data() {
       return {
         title: "Weekend",
         weekends: [],
         errors: {},
       department: {},
       day:null,
         uuid:""
       };
     },
   
     methods: {
       list() {
         axios
         .get("/company/weekends")
         .then((response) => {
           if (response) {
             
             var weekends = response.data.data;
             this.weekends=[]
 
             for(var weekend of weekends){
               var id=weekend.id
               var uuid=weekend.uuid
               var day=weekend.day
               var note=weekend.note
               var rate=weekend.rate
               var action =
                 "<a href='#' onclick='handleClick(" +
                 '"' +
                 uuid +
                 '"' +
                 ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                 '"' +
                 uuid +
                 '"' +
                 ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";
 
               this.weekends.push([id,day,note,rate,action]);
 
             }
 
    
           }
         })
         .catch((error) => {
           console.log(error);
           
         });
       },
       add() {
         axios
         .post("/company/weekends", {
           note: this.note,
           day: this.day,
           rate: this.rate,
         })
         .then((response) => {
           if (response) {
    
              
          
            this.rate
 
            this.holiday_name="";
            this.note="";
               this.list();
               $("#addModal .close").click()
           }
         })
         .catch((error) => {
           this.errors = error.response.data.errors;
         });
       },
       update(uuid) {
         axios
           .put("/company/departments/" + uuid, {
             department_name: this.department.department_name,
             d_id: this.department.d_id,
           })
           .then((response) => {
             if (response) {
               this.list();
   
               $("#editModal .close").click();
             }
           })
           .catch((error) => {
             console.log(error);
           });
       },
   
       delete_single() {
         axios
           .delete("/company/departments/" + this.uuid)
           .then((response) => {
             if (response) {
               $("#deleteModal.close").click();
               this.list();
             }
           })
           .catch((error) => {
             console.log(error);
           });
       },
       edit(id) {
         axios
           .get("/company/departments/" + id)
           .then((response) => {
             if (response) {
               this.department = response.data.data;
             }
           })
           .catch((error) => {
             console.log(error);
           });
       },
       deletePopUp(uuid) {
       this.uuid=uuid
     
   
       },
     },
   
     mounted: function () {
       this.name = JSON.parse(localStorage.getItem("user")).user.name;
       window.edit = this.edit;
       window.deletePopUp = this.deletePopUp;
       this.list();
     },
   };
   </script>
       
       
             
          <style>
   @import "datatables.net-bs5";
   </style>