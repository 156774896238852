import { createRouter, createWebHistory } from 'vue-router'
// User


// Auth
import LoginView from '@/components/auth/LoginView.vue'
import RegisterView from '@/components/auth/RegisterView.vue'
import VerificationView from '@/components/auth/VerificationView.vue'

//User
import HomeView from '@/components/HomeView.vue'



// Admin

import AdminDashboardView from '@/components/admin/DashboardView'
import CompanyListView from '@/components/admin/CompanyListView'
import CompanyGridView from '@/components/admin/CompanyGridView'
import CompanyProfileView from '@/components/admin/CompanyProfileView'
import PricePlanView from '@/components/admin/PricePlanView'
import CountryView from '@/components/admin/CountryView'
import CompanyModuleView from '@/components/admin/CompanyModuleView'
import EmployeeModuleView from '@/components/admin/EmployeeModuleView'
import TaxruleView from '@/components/admin/TaxruleView'


// Company
import CompanyDashboardView from '@/components/company/DashboardView'
import EmployeeListView from '@/components/company/EmployeeListView'
import EmployeeProfileView from '@/components/company/EmployeeProfileView'
import DepartmentView from '@/components/company/DepartmentView'
import GradeView from '@/components/company/GradeView'
import PositionView from '@/components/company/PositionView'
import LevelView from '@/components/company/LevelView'
import LeaveApplication from '@/components/company/LeaveApplication'
import EmployeeAttendanceView from '@/components/company/AttendanceView'
import HolidayView from '@/components/company/HolidayView'
import WeekendView from '@/components/company/WeekendView'
import PayrollView from '@/components/company/PayrollView'
import JobDemandListView from '@/components/company/JobDemandListView'
import JobApplicationView from '@/components/company/JobApplicationView'
import InterviewSchedules from '@/components/company/InterviewSchedules'
import ShortListedView from '@/components/company/ShortListedView'
import InterviewResultView from '@/components/company/InterviewResultView'
import FinalCandidateView from '@/components/company/FinalCandidateView'
import AssignResourceView from '@/components/company/AssignResourceView'
import CompanyProfileViewCom from '@/components/company/CompanyProfileView'
import PayslipEmployeeListView from '@/components/company/PayslipEmployeeListView'
import PayslipView from '@/components/company/PayslipView'
import PerformanceIndicatorView from '@/components/company/PerformanceIndicatorView'
import PerformanceAppraisalView from '@/components/company/PerformanceAppraisalView'
import SpecialPerformanceView from '@/components/company/SpecialPerformanceView'
import TrainingListView from '@/components/company/TrainingListView'
import TrainersView from '@/components/company/TrainersView'
import TrainingApprovalView from '@/components/company/TrainingApprovalView'
import TrainingTypeView from '@/components/company/TrainingTypeView'
import ProjectView from '@/components/company/ProjectView'
import TaskView from '@/components/company/TaskView'
import TaskBoardView from '@/components/company/TaskBoardView'
import ClientsView from '@/components/company/ClientsView'
import LeadsView from '@/components/company/LeadsView'
import TicketsView from '@/components/company/TicketsView'
import TimesheetView from '@/components/company/TimesheetView'
import ShiftView from '@/components/company/ShiftView'
import OvertimeView from '@/components/company/OvertimeView'
import PromotionView from '@/components/company/PromotionView'
import ResignationView from '@/components/company/ResignationView'
import TerminationView from '@/components/company/TerminationView'
import AssetsView from '@/components/company/AssetsView'
import RequestedLoanView from '@/components/company/RequestedLoanView'
import AdvancedPaymentView from '@/components/company/AdvancedPaymentView'
import ProvidentFundView from '@/components/company/ProvidentFundView'
import PFRuleView from '@/components/company/PFRuleView'
import ServiceRuleView from '@/components/company/ServiceRuleView'
import CompanyAddLeaveView from '@/components/company/CompanyAddLeaveView'
import PaysliplistView from '@/components/company/PaysliplistView'
import AssetCategoryView from '@/components/company/AssetCategoryView'


// Employee
import EmployeeDashboardView from '@/components/employee/DashboardView'
import ProfileViewEmployee from '@/components/employee/EmployeeProfileView'
import AttendanceViewEmployee from '@/components/employee/EmployeeAttendanceView'
import EmployeeLeavesView from '@/components/employee/EmployeeLeavesView'
import EmployeeSalaryView from '@/components/employee/EmployeeSalaryView'
import EmployeePayslipView from '@/components/employee/EmployeePayslipView'
import EmployeeBillsView from '@/components/employee/EmployeeBillsView'
import EmployeeLoanView from '@/components/employee/EmployeeLoanView'
import EmployeeAssetsView from '@/components/employee/EmployeeAssetsView'
import EmployeeProjectView from '@/components/employee/EmployeeProjectView'
import EmployeeTaskView from '@/components/employee/EmployeeTaskView' 
import PerformanceReportView from '@/components/employee/PerformanceReportView' 
import EmployeeSpecialPerformance from '@/components/employee/EmployeeSpecialPerformance' 

import EmployeeAdminAttendanceView from '@/components/employee/EmployeeAdminAttendanceView' 
import EmployeeAdminLeaveApplicationView from '@/components/employee/EmployeeAdminLeaveApplicationView' 
import EmployeeAdminJobDemandView from '@/components/employee/EmployeeAdminJobDemandView' 
import EmployeeAdminPerformanceIndicatorView from '@/components/employee/EmployeeAdminPerformanceIndicatorView' 
import EmployeeAdminPerformanceAppraisalView from '@/components/employee/EmployeeAdminPerformanceAppraisalView'
import EmployeeVisitingPlanView from '@/components/employee/EmployeeVisitingPlanView'
import EmployeeAdminVisitingView from '@/components/employee/EmployeeAdminVisitingView'
import EmployeeServiceRoleView from '@/components/employee/EmployeeServiceRoleView'
import EmployeeProvidentFundView from '@/components/employee/EmployeeProvidentFundView'



const routes = [
  

  //User
  {
    path: '/',
    name: 'home',
    component: HomeView
  },



   // Auth Route
   {
    path: '/login',
    name: 'login',
    meta: { layout: "auth", login: true},
    component: LoginView
   },

   {
      path: '/register',
      name: 'register',
      meta: { layout: "auth"},
      component: RegisterView
     },

     {
      path: '/verification',
      name: 'verification',
      meta: { layout: "auth"},
      component: VerificationView
     },
  
  

  //Admin Route


  {
    path: '/admin',
    name: 'admin',
    meta: { layout: "admin"},
    children: [
        {
           path: 'dashboard',
           name: 'admin-dashboard',
           component: AdminDashboardView,
        },
     
        {
           path: 'companylist',
           name: 'admin-companylist',
           component: CompanyListView,
        },
     
        {
           path: 'companygrid',
           name: 'admin-companygrid',
           component: CompanyGridView,
        },
     
        {
           path: 'companyprofile',
           name: 'admin-companyprofile',
           component: CompanyProfileView,
        },
     
        {
           path: 'priceplan',
           name: 'admin-priceplan',
           component: PricePlanView,
        },
     
        {
           path: 'country',
           name: 'admin-country',
           component: CountryView,
        },
     
        {
           path: 'company-module',
           name: 'admin-company-module',
           component: CompanyModuleView,
        },
     
        {
           path: 'company-module',
           name: 'admin-employee-module',
           component: EmployeeModuleView,
        },
     
        {
           path: 'taxrule',
           name: 'admin-taxrule',
           component: TaxruleView,
        },

        
     

     
      

      
       
    ]
  },

  // Company Route
  {
    path: '/company',
    name: 'company',
    meta: { layout: "company"},
    children: [
        {
           path: 'dashboard',
           name: 'company-dashboard',
           component: CompanyDashboardView,
        },
        {
           path: 'employee',
           name: 'company-employee',
           component: EmployeeListView,
        },
     
        {
           path: 'employee-profile',
           name: 'company-employee-profile',
           component: EmployeeProfileView,
        },
     
        {
           path: 'department',
           name: 'company-department',
           component: DepartmentView,
        },
     
        {
           path: 'department',
           name: 'company-department',
           component: DepartmentView,
        },
     
     
        {
           path: 'grade',
           name: 'company-grade',
           component: GradeView,
        },
     
        {
           path: 'position',
           name: 'company-position',
           component: PositionView,
        },


        {
         path: 'level',
         name: 'company-level',
         component: LevelView,
        },

         {
         path: 'leave',
         name: 'company-add-leave',
         component: CompanyAddLeaveView,
        },


        {
         path: 'service-rule',
         name: 'company-service-rule',
         component: ServiceRuleView,
        },



        {
         path: 'leave-application',
         name: 'company-leave-application',
         component: LeaveApplication,
        },
        {
         path: 'employee-attendance',
         name: 'company-employee-attendance',
         component: EmployeeAttendanceView,
        },
   
        {
         path: 'holiday',
         name: 'company-holiday',
         component: HolidayView,
        },
   
        {
         path: 'weekend',
         name: 'company-weekend',
         component: WeekendView,
        },
   
        {
         path: 'payroll',
         name: 'company-payroll',
         component: PayrollView,
        },
   
        {
         path: 'jobdemand',
         name: 'company-jobdemand',
         component: JobDemandListView,
        },
   
        {
         path: 'job-application',
         name: 'company-job-application',
         component: JobApplicationView,
        },
   
        {
         path: 'interview-schedules',
         name: 'company-interview-schedules',
         component: InterviewSchedules,
        },
   
   
        {
         path: 'shortlisted',
         name: 'company-shortlisted',
         component: ShortListedView,
        },
   
   
        {
         path: 'interview-result',
         name: 'company-interview-result',
         component: InterviewResultView,
        },
   
        {
         path: 'final-candidate',
         name: 'company-final-candidate',
         component: FinalCandidateView,
        },
   
        {
         path: 'assign-resource',
         name: 'company-assign-resource',
         component: AssignResourceView,
        },
   
   
        {
         path: 'profile',
         name: 'company-profile',
         component: CompanyProfileViewCom,
        },
   
        {
         path: 'employee-payslip',
         name: 'company-employee-payslip',
         component: PayslipEmployeeListView,
        },
   
        {
         path: 'payslip',
         name: 'company-payslip',
         component: PayslipView,
        },
   
        {
         path: 'performance-indicator',
         name: 'company-performance-indicator',
         component: PerformanceIndicatorView,
        },
   
   
        {
         path: 'performance-appraisal',
         name: 'company-performance-appraisal',
         component: PerformanceAppraisalView,
        },
   
        {
         path: 'performance-appraisal',
         name: 'company-performance-appraisal',
         component: PerformanceAppraisalView,
        },
   
        {
         path: 'special-performance',
         name: 'company-special-performance',
         component: SpecialPerformanceView,
        },
   
        {
         path: 'traing-list',
         name: 'company-traing-list',
         component: TrainingListView,
        },
   
   
        {
         path: 'trainers',
         name: 'company-trainers',
         component: TrainersView,
        },
   
        {
         path: 'training-approval',
         name: 'company-training-approval',
         component: TrainingApprovalView,
        },
   
        {
         path: 'training-type',
         name: 'company-training-type',
         component: TrainingTypeView,
        },
   
        {
         path: 'projects',
         name: 'company-projects',
         component: ProjectView,
        },
   
        {
         path: 'tasks',
         name: 'company-tasks',
         component: TaskView,
        },
   
        {
         path: 'task-board',
         name: 'company-task-board',
         component: TaskBoardView,
        },
   
   
        {
         path: 'clients',
         name: 'company-clients',
         component: ClientsView,
        },
        {
         path: 'leads',
         name: 'company-leads',
         component: LeadsView,
        },
   
        {
         path: 'tickets',
         name: 'company-tickets',
         component: TicketsView,
        },
   
        {
         path: 'timesheet',
         name: 'company-timesheet',
         component: TimesheetView,
        },
   
        {
         path: 'shift',
         name: 'company-shift',
         component: ShiftView,
        },
   
        {
         path: 'overtime',
         name: 'company-overtime',
         component: OvertimeView,
        },
        {
         path: 'promotion',
         name: 'company-promotion',
         component: PromotionView,
        }, 
   
        {
         path: 'resignation',
         name: 'company-resignation',
         component: ResignationView,
        },
        {
         path: 'termination',
         name: 'company-termination',
         component: TerminationView,
        },
   
        {
         path: 'assets',
         name: 'company-assets',
         component: AssetsView,
        },

        {
         path: 'assets-category',
         name: 'company-assets-category',
         component: AssetCategoryView,
        },
   
        {
         path: 'requested-loan',
         name: 'company-requested-loan',
         component: RequestedLoanView,
        },
   
        {
         path: 'advanced-payment',
         name: 'company-advanced-payment',
         component: AdvancedPaymentView,
        },
   
        {
         path: 'provident-fund',
         name: 'company-provident-fund',
         component: ProvidentFundView,
        },
        
        {
         path: 'pf-rule',
         name: 'company-pf-rule',
         component: PFRuleView,
        },

        {
         path: 'payslip-list',
         name: 'payslip-list',
         component: PaysliplistView,
        },
   

     
  

     
      

      
       
    ]
  },

  //Employee Route
  
  {
    path: '/employee',
    name: 'employee',
    meta: { layout: "employee"},
    children: [
        {
           path: 'dashboard',
           name: 'employee-dashboard',
           component: EmployeeDashboardView,
        },
     
  
        {
           path: 'profile',
           name: 'employee-profile',
           component: ProfileViewEmployee,
        },
     
  
        {
           path: 'attendance',
           name: 'employee-attendance',
           component: AttendanceViewEmployee,
        },
     
  
  
        {
           path: 'leaves',
           name: 'employee-leaves',
           component: EmployeeLeavesView,
        },

        {
         path: 'visiting-plan',
         name: 'employee-visiting-plan',
         component: EmployeeVisitingPlanView,
      },
     
  
        {
           path: 'salary',
           name: 'employee-salary',
           component: EmployeeSalaryView,
        },
     
        {
           path: 'payslip',
           name: 'employee-payslip',
           component: EmployeePayslipView,
        },
     
  
        {
           path: 'bills',
           name: 'employee-bills',
           component: EmployeeBillsView,
        },
     
        {
           path: 'loans',
           name: 'employee-loans',
           component: EmployeeLoanView,
        },
     
        {
           path: 'assets',
           name: 'employee-assets',
           component: EmployeeAssetsView,
        },

        {
         path: 'provident-fund',
         name: 'employee-provident-fund',
         component: EmployeeProvidentFundView,
        },



        {
           path: 'projects',
           name: 'employee-projects',
           component: EmployeeProjectView,
        },
     
  
        {
           path: 'tasks',
           name: 'employee-tasks',
           component: EmployeeTaskView,
        },
     
        {
           path: 'performance',
           name: 'employee-performance',
           component: PerformanceReportView,
        },
     
        {
           path: 'employee-special-performance',
           name: 'employee-special-performance',
           component: EmployeeSpecialPerformance,
        },

        {
         path: 'service-role',
         name: 'employee-Service-Role',
         component: EmployeeServiceRoleView,
      },
     
     
        {
           path: 'admin-attendance',
           name: 'employee-admin-attendance',
           component: EmployeeAdminAttendanceView,
        },
     
        {
           path: 'admin-leave-attendance',
           name: 'employee-admin-leave-application',
           component: EmployeeAdminLeaveApplicationView,
        },
     
  
        {
           path: 'admin-job-demand',
           name: 'employee-admin-job-demand',
           component: EmployeeAdminJobDemandView,
        },
     
        {
           path: 'admin-performance-indicator',
           name: 'employee-admin-performance-indicator',
           component: EmployeeAdminPerformanceIndicatorView,
        },
     
  

     
        {
           path: 'admin-performance-apprisal',
           name: 'employee-admin-performance-apprisal',
           component: EmployeeAdminPerformanceAppraisalView,
        },


        {
         path: 'admin-visiting-plan',
         name: 'employee-admin-visiting',
         component: EmployeeAdminVisitingView,
      },

      
    
  

     
      

      
       
    ]
  },
  



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

   if (to.matched.some(record => record.meta.login)) {
 
     const user = JSON.parse(localStorage.getItem("user"));
 
     if (user) {
 
 
      if (user.user.roles[0] == "superadmin") {

         next({ name: "admin-dashboard" });        


 



      }else if (user.user.roles[0] == "company") {

         next({ name: "company-dashboard" });        




      }else{



        next({ name: "employee-dashboard" });
      }
 
   
 
     }
     else {
 
       next();
 
 
 
     }
   }

 
   else {
     next()
 
 
 
   }
 
 
 
 
 });
 
 
export default router
