<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="page-title">Projects</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'employee-dashboard' }">
                  <a href="">Dashboard</a>
                </router-link>
              </li>
              <li class="breadcrumb-item active">Projects</li>
            </ul>
          </div>
          <div class="col-auto float-end ms-auto">
            <a
              href="#"
              class="btn add-btn"
              data-bs-toggle="modal"
              data-bs-target="#create_project"
              ><i class="fa fa-plus"></i> Create Project</a
            >
            <div class="view-icons">
              <a href="projects" class="grid-view btn btn-link active"
                ><i class="fa fa-th"></i
              ></a>
              <a href="project_list" class="list-view btn btn-link"
                ><i class="fa fa-bars"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-sm-6 col-md-3">
          <div class="form-group form-focus">
            <input type="text" class="form-control floating" />
            <label class="focus-label">Project Name</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="form-group form-focus">
            <input type="text" class="form-control floating" />
            <label class="focus-label">Employee Name</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="form-group custom-select">
            <select class="select floating">
              <option>Select Role</option>
              <option>Web Developer</option>
              <option>Web Designer</option>
              <option>Android Developer</option>
              <option>Ios Developer</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <a href="#" class="btn btn-success w-100"> Search </a>
        </div>
      </div>
      <!-- Search Filter -->

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown dropdown-action profile-action">
                <a
                  href="#"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_project"
                    ><i class="fa fa-pencil m-r-5"></i> Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_project"
                    ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="project-title">
                <a href="project_view">Office Management</a>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">1</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">9</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                The job can range widely in duties and responsibilities, from
                reception, copy editing and support, to handling a specific type
                of ...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">17 Apr 2023</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Leader :</div>
                <ul class="team-members">
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Sarwar Jahan Morshed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                    /></a>
                  </li>
                  <li class="dropdown avatar-dropdown">
                    <a
                      href="#"
                      class="all-users dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      >+15</a
                    >
                    <div class="dropdown-menu dropdown-menu-right">
                      <div class="avatar-group">
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-pagination">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span class="visually-hidden">Previous</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span class="visually-hidden">Next</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">60%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-bs-toggle="tooltip"
                  title="40%"
                  style="width: 40%"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown dropdown-action profile-action">
                <a
                  href="#"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_project"
                    ><i class="fa fa-pencil m-r-5"></i> Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_project"
                    ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="project-title">
                <a href="project_view">Project Management</a>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">2</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">5</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Requirements are a group of tasks or conditions that must be
                completed to finish the project successfully. They can include
                product ...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">17 Apr 2023</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Leader :</div>
                <ul class="team-members">
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Sarwar Jahan Morshed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                    /></a>
                  </li>
                  <li class="dropdown avatar-dropdown">
                    <a
                      href="#"
                      class="all-users dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      >+15</a
                    >
                    <div class="dropdown-menu dropdown-menu-right">
                      <div class="avatar-group">
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-pagination">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span class="visually-hidden">Previous</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span class="visually-hidden">Next</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">55%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-bs-toggle="tooltip"
                  title="40%"
                  style="width: 40%"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown dropdown-action profile-action">
                <a
                  href="#"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_project"
                    ><i class="fa fa-pencil m-r-5"></i> Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_project"
                    ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="project-title">
                <a href="project_view">Video Calling App</a>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">3</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">3</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Softermii’s team which developed 5+ video calling apps in recent
                years decided to share their knowledge on this topic. Keep
                reading for details...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">17 Apr 2023</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Leader :</div>
                <ul class="team-members">
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Sarwar Jahan Morshed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                    /></a>
                  </li>
                  <li class="dropdown avatar-dropdown">
                    <a
                      href="#"
                      class="all-users dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      >+15</a
                    >
                    <div class="dropdown-menu dropdown-menu-right">
                      <div class="avatar-group">
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-pagination">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span class="visually-hidden">Previous</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span class="visually-hidden">Next</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-bs-toggle="tooltip"
                  title="40%"
                  style="width: 40%"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown dropdown-action profile-action">
                <a
                  href="#"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_project"
                    ><i class="fa fa-pencil m-r-5"></i> Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_project"
                    ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="project-title">
                <a href="project_view">Hospital Administration</a>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">12</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">4</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Highest-level management position in a hospital or hospital
                system. Hospital CEO's must possess the ...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">17 Apr 2023</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Leader :</div>
                <ul class="team-members">
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabbir Khan"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Sarwar Jahan Morshed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Sabuj Ahmed"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Assaduzzaman"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Pankaz Debnath"
                      ><img
                        alt=""
                        src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                    /></a>
                  </li>
                  <li class="dropdown avatar-dropdown">
                    <a
                      href="#"
                      class="all-users dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      >+15</a
                    >
                    <div class="dropdown-menu dropdown-menu-right">
                      <div class="avatar-group">
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                          />
                        </a>
                        <a class="avatar avatar-xs" href="#">
                          <img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-pagination">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span class="visually-hidden">Previous</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span class="visually-hidden">Next</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-bs-toggle="tooltip"
                  title="40%"
                  style="width: 40%"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Create Project Modal -->
    <div id="create_project" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Project</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Project Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Client</label>
                    <select class="select">
                      <option>Global Technologies</option>
                      <option>Delta Infotech</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Start Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>End Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Rate</label>
                    <input placeholder="$50" class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <select class="select">
                      <option>Hourly</option>
                      <option>Fixed</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Priority</label>
                    <select class="select">
                      <option>High</option>
                      <option>Medium</option>
                      <option>Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Add Project Leader</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Team Leader</label>
                    <div class="project-members">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sabbir Khan"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Add Team</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Team Members</label>
                    <div class="project-members">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sarwar Jahan Morshed"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sabuj Ahmed"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Assaduzzaman"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Pankaz Debnath"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          alt=""
                        />
                      </a>
                      <span class="all-team">+2</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <textarea
                  rows="4"
                  class="form-control summernote"
                  placeholder="Enter your message here"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Upload Files</label>
                <input class="form-control" type="file" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Create Project Modal -->

    <!-- Edit Project Modal -->
    <div id="edit_project" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Project</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Project Name</label>
                    <input
                      class="form-control"
                      value="Project Management"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Client</label>
                    <select class="select">
                      <option>Global Technologies</option>
                      <option>Delta Infotech</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Start Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>End Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Rate</label>
                    <input
                      placeholder="$50"
                      class="form-control"
                      value="$5000"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <select class="select">
                      <option>Hourly</option>
                      <option selected>Fixed</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Priority</label>
                    <select class="select">
                      <option selected>High</option>
                      <option>Medium</option>
                      <option>Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Add Project Leader</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Team Leader</label>
                    <div class="project-members">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sabbir Khan"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Add Team</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Team Members</label>
                    <div class="project-members">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sarwar Jahan Morshed"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Sabuj Ahmed"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Assaduzzaman"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Pankaz Debnath"
                        class="avatar"
                      >
                        <img
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                          alt=""
                        />
                      </a>
                      <span class="all-team">+2</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <textarea
                  rows="4"
                  class="form-control"
                  placeholder="Enter your message here"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Upload Files</label>
                <input class="form-control" type="file" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Project Modal -->

    <!-- Delete Project Modal -->
    <div class="modal custom-modal fade" id="delete_project" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Project</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Project Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
