<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Training</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Training
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        

        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Training Type"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_training"
              ><i class="fas fa-plus"></i> Add New</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th style="width: 30px">#</th>
                  <th>Training Type</th>
                  <th>Title</th>
                  <th>Employee</th>
                  <th>Time Duration</th>
                  <th>Description</th>
                  <th>Cost</th>
                  <th>Status</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Git Training</td>
                  <td>Title 1</td>
                  <td>
                    <ul class="team-members">
                      <li>
                        <a
                          href="#"
                          title="Safwan Chowdhory"
                          data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                        /></a>
                      </li>
                      <li>
                        <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="#"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>7 May 2023 - 10 May 2023</td>
                  <td>Git is a version control ..</td>
                  <td>$400</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_training"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_training"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Swift Training</td>
                  <td>Title 1</td>
                  <td>
                    <ul class="team-members">
                      <li>
                        <a
                          href="#"
                          title="Sarwar Jahan Morshed"
                          data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                        /></a>
                      </li>
                      <li>
                        <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="#"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>7 May 2023 - 10 May 2023</td>
                  <td>Develop in Swift curriculum. ...</td>
                  <td>$800</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_training"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_training"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Node Training</td>
                  <td>Title 1</td>
                  <td>
                    <ul class="team-members">
                      <li>
                        <a
                          href="#"
                          title="Safwan Chowdhory"
                          data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                        /></a>
                      </li>
                      <li>
                        <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="#"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>7 May 2023 - 10 May 2023</td>
                  <td>Fast and secure RESTful..</td>
                  <td>$400</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_training"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_training"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Angular Training</td>
                  <td>Title 1</td>
                  <td>
                    <ul class="team-members">
                      <li>
                        <a
                          href="#"
                          title="Safwan Chowdhory"
                          data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                        /></a>
                      </li>
                      <li>
                        <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="#"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>7 May 2023 - 10 May 2023</td>
                  <td>Angular is a complete JavaScript...</td>
                  <td>$400</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_training"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_training"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Git Training</td>
                  <td>Title 1</td>
                  <td>
                    <ul class="team-members">
                      <li>
                        <a
                          href="#"
                          title="Safwan Chowdhory"
                          data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                        /></a>
                      </li>
                      <li>
                        <a href="#" title="Sabuj Ahmed" data-bs-toggle="tooltip"
                          ><img
                            alt=""
                            src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="#"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-13.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-01.jpg"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img
                                alt=""
                                src="<?php echo $baseurl;?>/assets/img/profiles/avatar-16.jpg"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>7 May 2023 - 10 May 2023</td>
                  <td>Git is a version control ..</td>
                  <td>$400</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_training"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_training"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Training List Modal -->
    <div id="add_training" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Training</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Payment Type</label>
                    <select class="form-control form-select">
                      <option value="">Select a payment type</option>
                      <option>Paid</option>
                      <option>Unpaid</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Cost</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Training title <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Department <span class="text-danger">*</span></label>
                    <select class="form-control form-select">
                      <option value="">Select a department</option>
                      <option>Department 1</option>
                      <option>Department 2</option>
                    </select>
                  </div>
                </div>


                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Employees <span class="text-danger">*</span></label>
                    <div class="dropdown-container">
        <div class="dropdown-button" id="dropdownButton">Select</div>
        <div class="dropdown-content" id="dropdownContent">
            <input type="text" class="search-input" id="searchInput" placeholder="Search...">
            <label><input type="checkbox" value="Jamal">Jamal</label>
            <label><input type="checkbox" value="Kamal"> Kamal</label>
            <label><input type="checkbox" value="Rafiq"> Rafiq</label>
            <label><input type="checkbox" value="Ahmad"> Ahmad</label>
        </div>
    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      >Training Type <span class="text-danger">*</span></label
                    >
                    <select class="form-control form-select">
                      <option value="">Select a type</option>
                      <option>Internal</option>
                      <option>External</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      >Trainer Name <span class="text-danger">*</span></label
                    >
                    <select class="form-control form-select">
                      <option value="">Select a type</option>
                      <option>Pankaz</option>
                      <option>Sarwar</option>
                      <option>Morshed</option>
                    </select>
                  </div>
                </div>


                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Start Date  <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="date" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >End Date  <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="date" />
                  </div>
                </div>


                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Time Start <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="time" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Time End <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="time" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Description<span class="text-danger">*</span></label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="details"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Training List Modal -->

    <!-- Edit Training List Modal -->
    <div id="edit_training" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Training List</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Type</label>
                    <select class="select">
                      <option selected>Node Training</option>
                      <option>Swift Training</option>
                      <option>Git Training</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Trainer</label>
                    <select class="select">
                      <option>Pankaz Debnath</option>
                      <option selected>Sarwar Jahan Morshed</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employees</label>
                    <select class="select">
                      <option>Safwan Chowdhory</option>
                      <option selected>Pankaz Debnath</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Training Cost <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="$400" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Start Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <input
                        class="form-control datetimepicker"
                        value="07-08-2023"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>End Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <input
                        class="form-control datetimepicker"
                        value="10-08-2023"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="4">
Lorem ipsum ismap</textarea
                    >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option selected>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Training List Modal -->

    <!-- Delete Training List Modal -->
    <div class="modal custom-modal fade" id="delete_training" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Training List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Training List Modal -->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
      },
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};

</script>

<style>
@import "datatables.net-bs5";
.dropdown-container {
            position: relative;
            display: inline-block;
            width: 100%;
        }
        .dropdown-button {
            padding: 10px;
            font-size: 16px;
            border: 1px solid #ccc;
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 0.1rem;
        }
        .dropdown-button::after {
            content: '\25BC';
            font-size: 12px;
        }
        .dropdown-content {
            display: none;
            background-color: white;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid #ccc;
            border-top: none;
        }
        .dropdown-content label {
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;
        }
        .dropdown-content label:hover {
            background-color: #f1f1f1;
        }
        .dropdown-content input[type="checkbox"] {
            margin-right: 10px;
        }
        .dropdown-content .select-button {
            width: 100%;
            padding: 10px;
            background-color: #4CAF50;
            color: white;
            text-align: center;
            border: none;
            cursor: pointer;
        }
        .dropdown-content .select-button:hover {
            background-color: #45a049;
        }
        .search-input {
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px;
            box-sizing: border-box;
        }
</style>
