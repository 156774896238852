<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Performance Indicator</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Performance Indicator
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">

        <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_indicator"
              ><i class="fas fa-plus"></i> Add New</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th style="width: 30px">#</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Added By</th>
                  <th>Create At</th>
                  <th>Status</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Web Designer</td>
                  <td>Designing</td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                      /></a>
                      <a href="profile">Sarwar Jahan Morshed </a>
                    </h2>
                  </td>
                  <td>7 May 2023</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_indicator"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_indicator"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>IOS Developer</td>
                  <td>IOS</td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-05.jpg"
                      /></a>
                      <a href="profile">Pankaz Debnath </a>
                    </h2>
                  </td>
                  <td>7 May 2023</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_indicator"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_indicator"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Web Designer</td>
                  <td>Designing</td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-10.jpg"
                      /></a>
                      <a href="profile">Assaduzzaman </a>
                    </h2>
                  </td>
                  <td>7 May 2023</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_indicator"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_indicator"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Web Designer</td>
                  <td>Designing</td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-12.jpg"
                      /></a>
                      <a href="profile">Pankaz Debnath </a>
                    </h2>
                  </td>
                  <td>7 May 2023</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_indicator"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_indicator"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Web Designer</td>
                  <td>Designing</td>
                  <td>
                    <h2 class="table-avatar">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-11.jpg"
                      /></a>
                      <a href="profile">Ejaz Wahid </a>
                    </h2>
                  </td>
                  <td>7 May 2023</td>
                  <td>
                    <div class="dropdown action-label">
                      <a
                        class="role-info role-bg-two dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-success"></i>
                          Active</a
                        >
                        <a class="dropdown-item" href="#"
                          ><i class="fa fa-dot-circle-o text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_indicator"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_indicator"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Performance Indicator Modal -->
    <div id="add_indicator" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set New Indicator</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Designation</label>
                    <select class="select">
                      <option>Select Designation</option>
                      <option>Web Designer</option>
                      <option>IOS Developer</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6"></div>
                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Technical</h4>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Domain Knowledge</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Administration</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Presentation Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Quality Of Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Efficiency</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Organizational</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Integrity</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Professionalism</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Team Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Critical Thinking</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Conflict Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Attendance</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Ability To Meet Deadline</label
                    >
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12"></div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Performance Indicator Modal -->

    <!-- Edit Performance Indicator Modal -->
    <div id="edit_indicator" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Performance Indicator</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Designation</label>
                    <select class="select">
                      <option>Select Designation</option>
                      <option selected>Web Designer</option>
                      <option>IOS Developer</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Technical</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Domain knowledge</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option selected>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Administration</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Presentation Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Quality Of Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Efficiency</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Organizational</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Integrity</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Professionalism</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Team Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Critical Thinking</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Conflict Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Attendance</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Ability To Meet Deadline</label
                    >
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12"></div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Performance Indicator Modal -->

    <!-- Delete Performance Indicator Modal -->
    <div class="modal custom-modal fade" id="delete_indicator" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Performance Indicator List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Performance Indicator Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
