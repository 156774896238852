

export default {

     

    // Company

  company_leaves: "/company/total/leaves",

  company: {
    payslip_create:"/company/payslips",
    payslip_list:"/company/employee/payslips/",
    departmentPostion:"/company/positions/department/",
    grade:"/company/grades",
    emoloyee_list:"/company/employees",
    assets: "/company/assets",
    assets_category:"/company/asset_categories",
    positions:"/company/positions",
    departments:"/company/departments",
    performace_indicators:"/company/performace_indicators",
    performace_apprisals:"/company/performace_appraisals",
    employee:"company/employee/performace_indicator/",
    leaves_employee:"employee/leaves/senior/",
    leave_details:"/company/leaves",

    change_password:"/employee/changepassword",

},

  //Employee
employee: {
    company_leaves_employee: "/employee/total/leaves",
    leaves: "/employee/leaves",
    departmentPostion:"/employee/positions/department/",
    job_demand:"/employee/jobdemands",
    departments:"/employee/departments",
    performace_indicator:"employee/performace_indicator/",



    

}

  
}