<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Leaves</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Leaves
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Leave Statistics -->
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="stats-info">
            <h4>12</h4>
            <h6>Annual Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>3</h4>
            <h6>Medical Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>4</h4>
            <h6>Other Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>5</h4>
            <h6>Remaining Leave</h6>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="stats-info">
            <h4>12</h4>
            <h6>Annual Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>3</h4>
            <h6>Medical Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>4</h4>
            <h6>Other Leave</h6>
          </div>
        </div>
        <div class="col-md-3">
          <div class="stats-info">
            <h4>5</h4>
            <h6>Remaining Leave</h6>
          </div>
        </div>
      </div>
      <!-- /Leave Statistics -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8"></div>
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add Leave</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="leaves" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Leave Type</th>
                <th>From</th>
                <th>Till</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Leave Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Leave</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Leave Type<span class="text-danger">*</span></label>
                <select
                  class="form-control form-select"
                  v-model="leave_id"
                  @change="countleave(leave_id)"
                >
                  <option value="">Select leave type</option>
                  <option
                    :value="companyleaves.id"
                    v-for="companyleaves in company_leaves"
                    :key="companyleaves"
                    selected="selected"
                  >
                    {{ companyleaves.type }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-6">
                <label>Number of days <span class="text-danger">*</span></label>

                <input v-model="leave_req" class="form-control" type="number" />
              </div>

              <div class="form-group col-md-6">
                <label>From <span class="text-danger">*</span></label>

                <input v-model="from" class="form-control" type="date" />
              </div>

              <div class="form-group col-md-6">
                <label>To <span class="text-danger">*</span></label>

                <input
                  v-model="to"
                  class="form-control datetimepicker"
                  type="date"
                />
              </div>

              <div class="form-group col-md-6">
                <label
                  >Address during leave<span class="text-danger">*</span></label
                >

                <input
                  v-model="leave_address"
                  class="form-control"
                  type="text"
                />
              </div>

              <div class="form-group col-md-6">
                <label
                  >Phone no during leave<span class="text-danger"
                    >*</span
                  ></label
                >

                <input
                  v-model="leave_address"
                  class="form-control"
                  type="number"
                />
              </div>

              <div class="col-md-12">
                  <div class="form-group">
                    <label>Reason in details<span class="text-danger">*</span></label>
                    <ckeditor
                      :editor="editor"
                      v-model="reason"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>


            </div>
            <div v-if="company_leave" class="row px-2">
              <div class="col-lg-4 border p-2">
                <span style="font-size: 120%"
                  ><strong class="sc"
                    >Total {{ company_leave.type }} Leave :</strong
                  >
                  {{ company_leave.days }}</span
                >
              </div>
              <div class="col-lg-4 border p-2">
                <span style="font-size: 120%"
                  ><strong class="sc"
                    >Your {{ company_leave.type }} Leave :</strong
                  >
                  {{ totalleavecount }}</span
                >
              </div>

              <div class="col-lg-4 border p-2">
                <span style="font-size: 120%"
                  ><strong class="sc"> Remaining :</strong>
                  {{ company_leave.days - totalleavecount }}</span
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="submit-section col-md-12">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click="leaveApply" class="btn btn-primary submit-btn">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Leave Modal -->

    <!-- Edit Leave Modal -->
    <div id="edit_leave" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Leave</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form class="row">
              <div class="form-group col-md-12">
                <label>Leave Type <span class="text-danger">*</span></label>
                <select class="select">
                  <option>Select Leave Type</option>
                  <option>Casual Leave 12 Days</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>From <span class="text-danger">*</span></label>
                <div class="cal-icon">
                  <input
                    class="form-control datetimepicker"
                    value="01-01-2023"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>To <span class="text-danger">*</span></label>
                <div class="cal-icon">
                  <input
                    class="form-control datetimepicker"
                    value="01-01-2023"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Number of days <span class="text-danger">*</span></label>
                <input class="form-control" readonly type="text" value="2" />
              </div>
              <div class="form-group col-md-6">
                <label
                  >Remaining Leaves <span class="text-danger">*</span></label
                >
                <input class="form-control" readonly value="12" type="text" />
              </div>
              <div class="form-group col-md-12">
                <label>Leave Reason <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control">
                  Going to hospital</textarea
                >
              </div>
              <div class="submit-section col-md-12">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Leave Modal -->

    <!-- Delete Leave Modal -->
    <div class="modal custom-modal fade" id="delete_approve" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Leave</h3>
              <p>Are you sure want to Cancel this leave?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Leave Modal -->
  </div>
</template>

<script>
import { Validator } from "@/other/Validator";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Api from "@/other/Api";
// import ApiCall from "@/other/ApiCall"
import $ from "jquery";
import moment from "moment";
import axios from "axios";

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },
  data() {
    return {
      errors: {},
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
      },
      departments: [],
      department_id: "",
      leave_type: "",
      authority: "",
      leaves: [],
      leave_id: "",
      company_leaves: [],
      company_leave: "",
      yourleaves: "",
      approved_leaves: [],
      daycount: 0,
      totalleavecount: 0,
      file: "",
    };
  },
  methods: {
    leaveApply() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "from", value: this.from, type: "required" },
        { field: "to", value: this.to, type: "required" },
        { field: "reason", value: this.reason, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var formData = new FormData();

        formData.append("leave_id", this.leave_id);
        formData.append("from", this.from);
        formData.append("to", this.to);
        formData.append("reason", this.reason);
        formData.append("file", this.file);

        axios
          .post(Api.employee.leaves, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response) {
              $("#addModal .close").click();
              this.getLeaves();
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    getDepartments() {
      axios
        .get("employee/departments")
        .then((response) => {
          this.departments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyLeaves() {
      axios
        .get(Api.employee.company_leaves_employee)
        .then((response) => {
          this.company_leaves = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countleave(id) {
      //  var daycount=0;
      this.company_leave = this.company_leaves.find((el) => el.id === id);
      this.approved_leaves = this.leaves.filter(
        (item) => item.status === "1" && item.leave_id === id
      );
      this.totalleavecount = 0;
      for (var approved_leave of this.approved_leaves) {
        var start = moment(approved_leave.from, "YYYY-MM-DD");
        var end = moment(approved_leave.to, "YYYY-MM-DD");
        this.daycount = moment.duration(end.diff(start)).asDays();

        this.totalleavecount += this.daycount;
      }
    },

    handleDocumentUpload(event) {
      this.file = event.target.files[0];
    },

    getLeaves() {
      axios
        .get(Api.employee.leaves)
        .then((response) => {
          var leaves = response.data.data;
          for (var leave of leaves) {
            var uuid = leave.uuid;
            var id = leave.id;
            var type = leave.leave.type;
            var from = leave.from;
            var to = leave.to;
            var status =
              leave.status == null
                ? "Pending"
                : leave.status == 1
                ? "Approved"
                : "Rejected";

            var action =
              "<a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")' ' data-bs-toggle='modal' data-bs-target='#addModal'><i class='fas fa-eye'></i></a> </a>";

            this.leaves.push([id, type, from, to, status, action]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
  },

  getemployeeleave() {
    axios
      .get("company/leaves")
      .then((response) => {
        if (response) {
          var leaves = response.data.data;
          for (var leave of leaves) {
            var id = leave.id;
            var emaployee_name =
              JSON.parse(leave.employee.basic_information).first_name +
              " " +
              JSON.parse(leave.employee.basic_information).last_name;
            var from = leave.from;
            var to = leave.to;
            var status =
              leave.status == null
                ? "Pending"
                : leave.status == 1
                ? "Approved"
                : "Rejected";
            var action =
              '<button onclick="handleClick(' +
              id +
              ')" class="btn btn-sm btn-default"><i class="fa-solid fa-eye"></i></button>';

            this.leaves.push([id, emaployee_name, from, to, status, action]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  mounted: function () {
    this.getDepartments();
    this.getCompanyLeaves();
    this.getLeaves();
  },
};
</script>

<style></style>
