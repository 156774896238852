<template>

    <footer style="position: fixed; bottom: 0;right: 0;left: 0;" class="main-footer">
          <strong>Copyright &copy; 2023-2024 <a href="https://adminlte.io">HRM</a>.</strong>
          All rights reserved.
          <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.2.0
          </div>
        </footer>
        
      
    </template>
    
    <script>
    export default {
    
    }
    </script>
    
    <style>
    
    </style>