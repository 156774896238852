<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Resignation</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Resignation
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#add_resignation"
              ><i class="fas fa-plus"></i> Add Resignation</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Resigning Employee</th>
                  <th>Department</th>
                  <th>Reason</th>
                  <th>Notice Date</th>
                  <th>Resignation Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <h2 class="table-avatar blue-link">
                      <a href="profile" class="avatar"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-02.jpg"
                      /></a>
                      <a href="profile">Maliha Tabassum</a>
                    </h2>
                  </td>
                  <td>Web Development</td>
                  <td>Personal</td>
                  <td>28 Feb 2023</td>
                  <td>28 Feb 2023</td>
                  <td class="ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_resignation"
                      ><i class="fas fa-eye"></i
                    ></a>
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#edit_resignation"><i class="fas fa-pen"></i></a>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#delete_resignation"><i class="far fa-trash-alt"></i></a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Resignation Modal -->
    <div id="add_resignation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Resignation</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label
                  >Resigning Employee <span class="text-danger">*</span></label
                >
                <input class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label>Notice Date <span class="text-danger">*</span></label>
                <div class="cal-icon">
                  <input type="text" class="form-control datetimepicker" />
                </div>
              </div>
              <div class="form-group">
                <label
                  >Resignation Date <span class="text-danger">*</span></label
                >
                <div class="cal-icon">
                  <input type="text" class="form-control datetimepicker" />
                </div>
              </div>
              <div class="form-group">
                <label>Reason <span class="text-danger">*</span></label>
                <textarea class="form-control" rows="4"></textarea>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Resignation Modal -->

    <!-- View Resignation Modal -->
    <div id="edit_resignation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Resignation Details</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label
                      >Resigning Employee
                      <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      type="text"
                      value="Sarwar Jahan Morshed"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Department <span class="text-danger">*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      value="Web Development"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label
                      >Notice Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <input
                        type="text"
                        class="form-control datetimepicker"
                        value="28/02/2023"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label
                      >Resignation Date
                      <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <input
                        type="text"
                        class="form-control datetimepicker"
                        value="28/02/2023"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Reason <span class="text-danger">*</span></label>
                    <textarea
                      class="form-control"
                      rows="4"
                      value="A resignation letter is a formal document that lets your employer know that you are resigning from your position. It can be submitted either by email or printed letter. It lets you officially announce your departure from the company and offers essential housekeeping information, like your last day and other details about your exit. Ideally, you'll submit your resignation letter at least two weeks before leaving the company."
                    >
                    </textarea>
                  </div>
                </div>

                <div class="col-md-12 text-center">
                  <div class="submit-section">
                    <button
                      class="btn btn-primary text-danger cancel-btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Reject
                    </button>
                    <button class="btn btn-primary submit-btn">Approve</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Resignation Modal -->

    <!-- Delete Resignation Modal -->
    <div class="modal custom-modal fade" id="delete_resignation" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Resignation</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Resignation Modal -->
  </div>
</template>

<script>
import axios from "axios";
//   import Constant from "@/other/Constant";
//   import DataTable from "datatables.net-vue3";
//   import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

//   DataTable.use(DataTablesCore);

export default {
  // components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
    };
  },

  methods: {
    list() {
      axios
        .get("/company/weekends")
        .then((response) => {
          if (response) {
            var weekends = response.data.data;
            this.weekends = [];

            for (var weekend of weekends) {
              var id = weekend.id;
              var uuid = weekend.uuid;
              var day = weekend.day;
              var note = weekend.note;
              var rate = weekend.rate;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.weekends.push([id, day, note, rate, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
