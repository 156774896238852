<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Payroll
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add {{ title }}</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="payrolls" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Grade</th>

                <th>Department</th>
                <th>Basic</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Grade<span class="text-danger">*</span></label
                    >

                    <select v-model="grade_id" class="form-control form-select">
                      <option disabled value="null">Select a grade</option>
                      <option
                        v-for="grade in grades"
                        :key="grade.id"
                        :value="grade.id"
                      >
                        {{ grade.grade_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-1" v-if="errors.day">
                      {{ errors.day[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department<span class="text-danger">*</span></label
                    >

                    <select
                      v-model="company_department_id"
                      class="form-control form-select"
                    >
                      <option disabled value="null">Select a Deparment</option>
                      <option
                        v-for="department in departments"
                        :key="department.id"
                        :value="department.id"
                      >
                        {{ department.department_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-1" v-if="errors.day">
                      {{ errors.day[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Basic</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="basic"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Increment %</label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="increment"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Increment Duration</label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>

           

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Allowances</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="allowance"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Value</label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="value"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Type</label>
                    <select
                      v-model="type"
                      class="form-control form-select"
                    >
                      <option >Adding</option>
                      <option >Deduction</option>
                 
                    
                    </select>
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-1">
                  <div class="form-group">
                    <button
                      @click.prevent="addallowance"
                      class="form-control btn btn-primary"
                      style="margin-top: 37px"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div> 

                 

              </div>

              <table
                v-if="allowances.length > 0"
                class="table text-center table-bordered"
              >
                <thead>
                  <tr>
                    <th style="width: 10px">#</th>
                    <th>Allowance</th>
                    <th>Value</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="allowance in allowances" :key="allowance">
                    <td>1.</td>
                    <td>{{ allowance.allowance }}</td>
                    <td>
                      {{ allowance.value }}
                    </td>
                    <td>
                      {{ allowance.type }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="addpayroll" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department.department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} ID(Max 2 Digit)</label
                    >
                    <input
                      i
                      type="text"
                      @input="errors.code = null"
                      v-model="department.code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
      
      <script>
import axios from "axios";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Payroll",
      allowances: [],
      departments: [],
      department: {},
      errors: {},
      grades: [],
      grade_id: null,
      company_department_id: null,
      payrolls: [],
      uuid: "",
    };
  },

  methods: {
    addallowance() {
      var allowance = {
        allowance: this.allowance,
        value: this.value,
        type: this.type,
      };
      this.allowances.push(allowance);
    },

    list() {
      axios
        .get("/company/payrolls")
        .then((response) => {
          if (response) {
            // alert(response.data.message)
            var payrolls = response.data.data;

            payrolls.forEach((element) => {
              var id = element.id;
              var uuid = element.uuid;
              var grade = element.grade.grade_name;
              var department = element.company_department.department_name;
              var basic = element.basic;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              var arrayobject = [id, grade, department,basic, action];

              this.payrolls.push(arrayobject);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            // alert(response.data.message)
            this.departments = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getgrades() {
      axios
        .get("/company/grades")
        .then((response) => {
          if (response) {
            this.grades = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addpayroll(){


axios
  .post("/company/payrolls", {
    grade_id: this.grade_id,
    company_department_id: this.company_department_id,
    basic: this.basic,
    increment: this.increment,
    date: this.date,
    // features: JSON.stringify(Object.assign({}, this.features))
    allowances: JSON.stringify(this.allowances)
  })
  .then((response) => {

    if(response){
      $(".form-control").val('')
     
    }



   

  }).catch((error)=>{
     

   this.errors=error.response.data.errors





  
  })
 





},

    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    this.getdepartment();
    this.getgrades();
  },
};
</script>
      
      
            
         <style>
@import "datatables.net-bs5";
</style>