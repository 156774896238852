<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Visiting Plan</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Visiting Plan
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Visiting ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Visiting Date"
                />
              </div>
            </div>
          
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add Visiting Plan</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="prices" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Visiting ID</th>
                <th>Visiting Date</th>
                <th>Starting Point</th>

                <th>Status</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Visiting Plan</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Visiting Date<span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="date" v-model="date" />

                    <p class="text-danger mt-1" v-if="errors.name">
                      {{ errors.name[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Starting Point<span class="text-danger">*</span></label
                    >
                    <select class="form-control form-select">
                      <option value="">Select</option>
                      <option value="Gulshan">Gulshan</option>
                      <option value="Dhanmondi">Dhanmondi</option>
                      <option value="Mirpur">Mirpur</option>
                      <option value="Uttara">Uttara</option>
                      <option value="Mohammadpur">Mohammadpur</option>
                      <option value="Bashundhara">Bashundhara</option>
                      <option value="Rampura">Rampura</option>
                      <option value="Khilgaon">Khilgaon</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <!-- <h4 class="pt-3 pb-1 fw-bold">Add Destination Details</h4> -->

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >Transportation Type</label
                    >

                    <select class="form-control form-select">
                      <option value="">Select</option>
                      <option value="Rikshaw">Rikshaw</option>
                      <option value="Bus">Bus</option>
                      <option value="Train">Train</option>
                      <option value="Car">Car</option>
                      <option value="Bike">Bike</option>
                      <option value="CNG">CNG</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6"></div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >Destination</label
                    >
                    <select class="form-control form-select">
                      <option value="">Select</option>
                      <option value="Gulshan">Gulshan</option>
                      <option value="Dhanmondi">Dhanmondi</option>
                      <option value="Mirpur">Mirpur</option>
                      <option value="Uttara">Uttara</option>
                      <option value="Mohammadpur">Mohammadpur</option>
                      <option value="Bashundhara">Bashundhara</option>
                      <option value="Rampura">Rampura</option>
                      <option value="Khilgaon">Khilgaon</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >From</label
                    >
                    <input
                      type="time"
                      class="form-control"
                      id="additional-info"
                      placeholder="Enter from"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >Till</label
                    >
                    <input
                      type="time"
                      class="form-control"
                      id="additional-info"
                      placeholder="Enter till"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >Partner/Destributor</label
                    >
                    <select class="form-control form-select">
                      <option value="">Select</option>
                      <option value="Gulshan">Asif Enterprise</option>
                      <option value="Dhanmondi">Star Group</option>
                      <option value="Mirpur">Max Group</option>
                      <option value="Uttara">Crown Group</option>
                      <option value="Mohammadpur">Golap Group</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="additional-info" class="col-form-label"
                      >Details</label
                    >
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="details"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>

                <div class="col-md-12" style="text-align: right">
                  <button
                    class="btn btn-primary px-3 py-2 ml-auto"
                    type="button"
                    @click="addItem"
                  >
                    <i class="fa fa-plus"></i> ADD
                  </button>
                </div>

                <!-- Add more detail fields as needed -->
              </div>

              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="table-responsive m-t-15">
                    <table
                      v-if="items.length > 0"
                      class="table table-striped custom-table"
                    >
                      <thead>
                        <tr>
                          <th>Destrination</th>
                          <th class="text-center">Partner</th>
                          <th class="text-center">From</th>
                          <th class="text-center">Till</th>
                          <th class="text-center">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items" :key="item">
                          <td>{{ item.item }}</td>
                          <td class="text-center">{{ item.quantity }}</td>
                          <td class="text-center">
                            {{ item.amount }}
                          </td>
                          <td class="text-center">
                            {{ item.total }}
                          </td>
                          <td class="text-center">
                            <i class="fas fa-trash"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="addPrice"
                  class="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="Barry" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Cuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="barrycuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="barrycuda@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="CLT-0001"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      value="9876543210"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Global Technologies"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      name: "",
      items: [],
      errors: {},
      modules: [],
      employeemodules: [],
      selectedModulesID: [],
      selectedEmployeeModulesID: [],
      prices: [],

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
      },
    };
  },

  methods: {
    addItem() {
      var item = {
        item: this.item,
        quantity: this.quantity,
        amount: this.amount,
        total: this.quantity * this.amount,
      };
      this.items.push(item);
    },

    //Adding price to database

    addPrice() {
      axios
        .post("/superadmin/prices", {
          name: this.name,
          price: this.price,
          duration: this.duration,
          // features: JSON.stringify(Object.assign({}, this.features))
          features: JSON.stringify(this.features),
          modules: this.selectedModulesID,
          employeemodules: this.selectedEmployeeModulesID,
        })
        .then((response) => {
          if (response) {
            (this.name = ""),
              (this.price = ""),
              (this.duration = ""),
              (this.features = []);
            $("#addModal .close").click();
            this.getPrice();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    getModule() {
      axios
        .get("/superadmin/company_modules")
        .then((response) => {
          if (response) {
            this.modules = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyModule() {
      axios
        .get("/superadmin/employee_modules")
        .then((response) => {
          if (response) {
            this.employeemodules = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrice() {
      axios
        .get("/superadmin/prices")
        .then((response) => {
          if (response) {
            var prices = response.data.data;

            for (var priceplan of prices) {
              var uuid = priceplan.uuid;
              var name = priceplan.name;
              var price = priceplan.price;
              var duration = priceplan.duration;
              var status = "";
              var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")'  ><i class=' ms-3 far fa-eye'></i></a>";

              this.prices.push([name, price, duration, status, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleClick(uuid) {
      this.$router.push({
        name: "admin-companyprofile",
        query: { uuid: uuid },
      });
    },
  },

  mounted: function () {
    this.getModule();
    this.getCompanyModule();
    this.getPrice();
    window.handleClick = this.handleClick;
  },
};
</script>

<style>
@import "datatables.net-bs5";

.detail-fields {
  display: none;
}
</style>
