<template>
    <div class="wrapper">
      <!-- Page Content -->
      <div class="content-wrapper p-4">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>{{ title }}</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Performance Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
            
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable :data="performace_appraisals" class="table table-striped custom-table">
              <thead>
                <tr>
                  <th>ID</th>
                          <th>Date</th>
                       
                          <th>Status</th>
  
                          <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">View Performance</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-box">
                        <div class="row user-tabs">
                          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                            <ul class="nav nav-tabs nav-tabs-solid">
                              <li class="nav-item">
                                <a
                                  href="#appr_technical"
                                  data-bs-toggle="tab"
                                  class="nav-link active"
                                  >Technical</a
                                >
                              </li>
                              <li class="nav-item">
                                <a
                                  href="#appr_organizational"
                                  data-bs-toggle="tab"
                                  class="nav-link"
                                  >Organizational</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div
                          id="appr_technical"
                          class="pro-overview tab-pane fade show active"
                        >
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Technical Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Result</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Skill</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).skill
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>

                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).skill
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Domain Knowledge</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).domain_knowledge
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).domain_knowledge
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Management</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).management
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).management
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Administration</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).administration
                                        }}
                                      </td>

                            


                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).administration
                                        }}
                                      </td>

                                    </tr>
                                    <tr>
                                      <td colspan="2">Presentation Skill</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).presentation_skill
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).presentation_skill
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Quality Of Work</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).quality_of_work
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).quality_of_work
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Efficiency</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).efficiency
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.technical
                                          ).efficiency
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="appr_organizational">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Organizational Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Result</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Integrity</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.organizational
                                          ).integrity
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).integrity
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Professionalism</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).professionalism
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.organizational
                                          ).professionalism
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Team Work</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).team_work
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.organizational
                                          ).team_work
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Conflict Management</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).conflict_management
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.organizational
                                          ).conflict_management
                                        }}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colspan="2">Attendance</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).attendance
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_appraisal.organizational
                                          ).attendance
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        Ability To Meet Deadline
                                      </td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{ performance_indicator.dead_line }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td
                                        v-if="
                                          Object.keys(performance_appraisal)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{ performance_appraisal.dead_line }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button @click.prevent="aplyjob" class="btn btn-primary submit-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
  
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                  type="button"
                  class="close visually-hidden"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
        
        <script>
  
  // import $ from "jquery";
  import axios from "axios";
  // import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  // import Constant from "@/other/Constant";
  import Api from "@/other/Api";

  import DataTable from "datatables.net-vue3";
   import DataTablesCore from "datatables.net-bs5";
  
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
          title:"Performance Report",
          id:"",
          performace_appraisals_old:[],

          performace_appraisals:[],
          performance_appraisal: "",
          performance_indicator: {},

          

      };
    },
  
    methods: {
      list() {
          axios
          .get("employee/performace_appraisals/"+this.id)
          .then((response) => {
            var performace_appraisals = response.data.data;
            this.performace_appraisals_old = performace_appraisals;
            this.performace_appraisals = [];
  
            for (var performance_apraisal of performace_appraisals) {
              var id = performance_apraisal.id;
              // var uuid = performance_apraisal.uuid;
              var date = performance_apraisal.created_at;
              var status="";
  
              var action =
                  "<a href='#' onclick='handleClick(" +
                  '"' +
                  id +
                  '"' +
                  ")' ' data-bs-toggle='modal' data-bs-target='#addModal'><i class='fas fa-eye'></i></a>  ";
  
              this.performace_appraisals.push([
                id,
                date,
                status,             
                action,
              ]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      
     
      handleClick(id) {
          

        this.performance_appraisal=this.performace_appraisals_old.find((item) => item.id == id);

     


  
        // this.$router.push({ name: "jobdetails", query: { uuid: id } });
      },

      getEmployee() {
      axios
        .get(Api.employee.performace_indicator + this.id)
        .then((response) => {
          if (response) {
            this.employee = response.data.data;

            this.performance_indicator =
              this.employee.position.performance_indicator;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    
    },
  
    mounted: function () {
      this.id = JSON.parse(localStorage.getItem("user")).user.details.id;
      window.handleClick = this.handleClick;
      this.list();
      this.getEmployee();
    },
  };
  </script>
        
        
              
           <style>
  @import "datatables.net-bs5";
  </style>