<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Holidays
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Holiday Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Holiday Date"
                />
              </div>
            </div>
            <!-- <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div> -->
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add {{ title }}</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="holidays" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>

                <th>Holiday Name</th>
                <th>Work Rate</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Date<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.date = null"
                      v-model="date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.date">
                      {{ errors.date[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Holiday name</label>
                    <input
                      type="text"
                      @input="errors.holiday_name = null"
                      v-model="holiday_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.holiday_name">
                      {{ errors.holiday_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Work Rate (ex:10%) </label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="rate"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Date<span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      @input="errors.date = null"
                      v-model="holiday.date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.date">
                      {{ errors.date[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Holiday name</label>
                    <input
                      type="text"
                      @input="errors.holiday_name = null"
                      v-model="holiday.holiday_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.holiday_name">
                      {{ errors.holiday_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Work Rate (ex:10%)</label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="holiday.rate"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update"
                  class="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },
  data() {
    return {
      title: "Holiday",
      errors: {},
      holidays: [],
      department: {},
      uuid: "",
      holiday: {}, // Used for the edit form
      date: "",
      holiday_name: "",
      rate: "",
    };
  },
  methods: {
    list() {
      axios
        .get("/company/holidays")
        .then((response) => {
          if (response) {
            const holidays = response.data.data;
            this.holidays = [];

            holidays.forEach((holiday) => {
              const uuid = holiday.uuid; // Make sure the UUID is correctly retrieved here
              const id = holiday.id;
              const date = holiday.date;
              const holiday_name = holiday.holiday_name;
              const rate = holiday.rate;
              const action = `<a href='#' onclick='edit("${uuid}")' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a> 
                 <a href='#' onclick='deletePopUp("${uuid}")' data-bs-toggle='modal' data-bs-target='#deleteModal'><i class='ms-3 far fa-trash-alt'></i></a>`;
              this.holidays.push([id, date, holiday_name, rate, action]);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post("/company/holidays", {
          date: this.date,
          holiday_name: this.holiday_name,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.holiday_name = "";
            this.date = "";
            this.rate = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update() {
      axios
        .put("/company/holidays/" + this.holiday.uuid, {
          date: this.holiday.date,
          holiday_name: this.holiday.holiday_name,
          rate: this.holiday.rate,
        })
        .then((response) => {
          if (response) {
            this.list();
            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    delete_single() {
      axios
        .delete("/company/holidays/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal .close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(uuid) {
      axios
        .get("/company/holidays/" + uuid)
        .then((response) => {
          if (response) {
            this.holiday = response.data.data;

            this.date = this.holiday.date;
            this.holiday_name = this.holiday.holiday_name;
            this.rate = this.holiday.rate;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },
  mounted() {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
