export class Validator {
  validated(data) {
    var $ = require("jquery");
    var error = "";

    for (let i = 0; i < data.length; i++) {
      if (data[i].type) {
        if (data[i].type == "required") {
          if (data[i].value == "" || data[i].value == null) {
            error = "This filed is required !";
            $("#" + data[i].field).attr("placeholder", error);
            $("#" + data[i].field).addClass("error-input");
          }
        }

        if (data[i].type == "required|length") {
          if (data[i].value == "" || data[i].value == null) {
            error = "This filed is required !";
            $("#" + data[i].field).attr("placeholder", error);
            $("#" + data[i].field).addClass("error-input");
          } else if (data[i].size) {
            if (data[i].value.length < data[i].size) {
                $("#" + data[i].field).val("");

              error = "Minimum  length will be " + data[i].size;
              $("#" + data[i].field).attr("placeholder", error);
              $("#" + data[i].field).addClass("error-input");

              return error;
            }
          }
        }

        if (data[i].type == "required|email") {


          if (data[i].value == "" || data[i].value == null) {
            error = "This filed is required !";


            $("#" + data[i].field).attr("placeholder", error);
            $("#" + data[i].field).addClass("error-input");
          } else if (!data[i].value.includes("@")) {
            error = "Email is not valid !"
            $("#" + data[i].field).val("");
            $("#" + data[i].field).attr("placeholder", error);
            $("#" + data[i].field).addClass("error-input");

          }
        }
      }
    }

    return error;
  }

  clear(id) {
    var $ = require("jquery");
    $("#" + id).attr("placeholder", "");
    $("#" + id).removeClass("error-input");
  }
}
