<template>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>{{ title }}</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                     Interview Results
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Applicant Name" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Department" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
          
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
              <!-- <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-plus"></i> Add {{ title }}</a
              > -->
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable :data="shortlists" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                        <th>Applicant Name</th>
                        <th>Job Title/Position</th>
                        <th>Department</th>
                        <th>Result</th>
                        <th>Status</th>

                        <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Select job<span class="text-danger">*</span></label
                      >
                      <div class="custom-select" >

                        <select
           v-model="day"
         >
           <option disabled value=null>Select a job</option>
           <option >Job 1</option>
           <option >Job 2</option>
        
         </select>
         <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                        
                      </div>
           
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Select a candidate<span class="text-danger">*</span></label
                      >
                      <div class="custom-select" >

                        <select
           v-model="day"
         >
           <option disabled value=null>Select a candidate</option>
           <option >candidate 1</option>
           <option >candidate 2</option>
        
         </select>
         <p class="text-danger mt-1" v-if="errors.day" >{{ errors.day[0] }}</p>


                        
                      </div>
           
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Date</label
                      >
                      <input type="date"  @input="errors.note=null" v-model="note" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.note" >{{ errors.note[0] }}</p>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Time</label
                      >
                      <input type="time"  @input="errors.note=null" v-model="note" class="form-control" />
                  <p class="text-danger mt-1" v-if="errors.note" >{{ errors.note[0] }}</p>

                    </div>
                  </div>
                 
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="add"
                    class="btn btn-primary submit-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="editModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Applicant name  : </label
                      >
                     <p>{{ appication.applicant_name }}</p> 


                     <label class="col-form-label"
                        >Department  : </label
                      >
                     <p  >{{department_name}}</p> 
                  
                      <p class="text-danger mt-1" v-if="errors.department_name">
                        {{ errors.department_name[0] }}
                      </p>

                      <div class="form-group">
                      <label class="col-form-label"
                        >Result</label
                      >
                      <input i type="text"  @input="errors.code=null" v-model="result" class="form-control" />
                      <p class="text-danger mt-1" v-if="errors.code" >{{ errors.code[0] }}</p>


                      <input class="" style="height: 20px;width: 20px;margin-top: 20px;" v-model="recommended" type="checkbox" id="vehicle1" name="vehicle1" >
                      <span style="margin-left: 10px;margin-bottom: -5px;" >Recommended</span>
                    </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Postion  : </label
                      >
                     <p>{{ position}}</p> 


                    </div>
                  </div>
                  
                  
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="update(appication.uuid)"
                    class="btn btn-primary submit-btn"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
  
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a
      
                      data-bs-dismiss="modal"
            
                   
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
      
      <script>
  import axios from "axios";
  //   import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
  import $ from "jquery";
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
        title: "Interview Result",
        weekends: [],
        errors: {},
        department: {},
        day:null,
        uuid:"",
        interviewResults: [],
        employee_name:"",
        shortlists:[],
        appication:{},
        applications:[],
        department_name:"",

        position:"",
        recommended:false,

        result:""

      };
    },
  
    methods: {
      list() {
        axios
        .get("company/jobapplication/shortlist")
        .then((response) => {

          this.shortlists=[]

          var applications = response.data.data;
          this.applications = response.data.data;

          for (var application of applications) {
            var id = application.id;
            var uuid = application.uuid;
            var applicant_name = application.applicant_name;
            var position =
              application.employee_job_demand.position.position_name;
            var department =
              application.employee_job_demand.department.department_name;
              var result = application.result;

            var recommended = application.recommended;

            var recommended_name = "";
            if(recommended==1){

              recommended_name="<span class='badge badge-success m'>Recommended</span>"


            }

            var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' </a> ";

            this.shortlists.push([
              id,
              applicant_name,
              position,
              department,
              result,
              recommended_name,
              action,
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
      add() {
        axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
   
             
         
           this.rate

           this.holiday_name="";
           this.note="";
              this.list();
              $("#addModal .close").click()
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
      },
      update(uuid) {
        var re="";

        if(this.recommended==true){

          re=1
        }else{
          re=0
        }


        axios
          .post("/company/jobapplication/addresult/" + uuid, {
            result: this.result,
            recommended: re,
          })
          .then((response) => {
            if (response) {
              this.list();
  
              $("#editModal .close").click();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      delete_single() {
        axios
          .delete("/company/departments/" + this.uuid)
          .then((response) => {
            if (response) {
              $("#deleteModal.close").click();
              this.list();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

  


      edit(id) {
        axios
          .get("/company/departments/" + id)
          .then((response) => {
            if (response) {
              this.department = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deletePopUp(uuid) {
      this.uuid=uuid
    
  
      },

      handleClick(uuid) {
      this.uuid=uuid

      this.appication=this.applications.find((item) => item.uuid == uuid);
      this.department_name=this.appication.employee_job_demand.department.department_name;
      this.position=this.appication.employee_job_demand.position.position_name;
      
      this.result=this.appication.result; //post

      if(this.appication.recommended==1){
        this.recommended=true;

        
      }else{
        this.recommended=false;
      }
      

     
    },
    },
  
    mounted: function () {
      this.name = JSON.parse(localStorage.getItem("user")).user.name;
      window.edit = this.edit;
      window.handleClick = this.handleClick;
      this.list();
    },
  };
  </script>
      
      
            
         <style>
  @import "datatables.net-bs5";
  </style>