<template>
  <div class="wrapper">
    <div class="notification-popup hide">
      <p>
        <span class="task"></span>
        <span class="notification-text"></span>
      </p>
    </div>
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Performance Indicator</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Performance Indicator
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
       
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Position"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
       
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add New</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          
            <DataTable
              :data="indicators"
              class="table table-striped custom-table"
            >
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Position</th>
                  <th>Department</th>
                  <th>Added By</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
         
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Performance Indicator Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set New Indicator</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Department</label>
                    <select
                      v-model="department_id"
                      @change="getPosition()"
                      class="form-control form-select"
                    >
                      <option value="null">Select a department</option>
                      <option
                        v-for="department in departments"
                        :key="department.id"
                        :value="department.id"
                      >
                        {{ department.department_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Postion</label>
                    <select
                      v-model="position_id"
                      @change="errors.position_id = null"
                      class="form-control form-select"
                    >
                      <option value="null">Select a position</option>
                      <option
                        v-for="position in positions"
                        :key="position.id"
                        :value="position.id"
                      >
                        {{ position.position_name }}
                      </option>
                    </select>
                    <p class="text-danger" v-if="errors.position_id">
                      {{ errors.position_id[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Departmental</h4>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Skill</label>
                    <select v-model="skill" class="form-control form-select">
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Domain Knowledge</label>
                    <select
                      v-model="domain_knowledge"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Management</label>
                    <select
                      v-model="management"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Administration</label>
                    <select
                      v-model="administration"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Presentation Skill</label>
                    <select
                      v-model="presentation_skill"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Quality Of Work</label>
                    <select
                      v-model="quality_of_work"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Efficiency</label>
                    <select
                      v-model="efficiency"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Organizational</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Integrity</label>
                    <select
                      v-model="integrity"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Professionalism</label>
                    <select
                      v-model="professionalism"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Team Work</label>
                    <select
                      v-model="team_work"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Critical Thinking</label>
                    <select
                      v-model="critical_thinking"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Conflict Management</label>
                    <select
                      v-model="conflict_management"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Attendance</label>
                    <select
                      v-model="attendance"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Ability To Meet Deadline</label
                    >
                    <select
                      v-model="dead_line"
                      class="form-control form-select"
                    >
                      <option value="null">None</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                      <option value="Expert / Leader">Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12"></div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select v-model="status" class="form-control form-select">
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Performance Indicator Modal -->

    <!-- Edit Performance Indicator Modal -->
    <div id="edit_indicator" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Performance Indicator</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Designation</label>
                    <select class="select">
                      <option>Select Designation</option>
                      <option selected>Web Designer</option>
                      <option>IOS Developer</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Technical</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Domain knowledge</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option selected>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Administration</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Presentation Skill</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Quality Of Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Efficiency</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4 class="modal-sub-title">Organizational</h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Integrity</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Professionalism</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Team Work</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Critical Thinking</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Conflict Management</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Attendance</label>
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option selected>Intermediate</option>
                      <option>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Ability To Meet Deadline</label
                    >
                    <select class="select">
                      <option>None</option>
                      <option>Beginner</option>
                      <option>Intermediate</option>
                      <option selected>Advanced</option>
                      <option>Expert / Leader</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-12"></div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Performance Indicator Modal -->

    <!-- Delete Performance Indicator Modal -->
    <div class="modal custom-modal fade" id="delete_indicator" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Performance Indicator List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Performance Indicator Modal -->
  </div>
</template>

<script>
import axios from "axios";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";
import Api from "@/other/Api";
import Notify from "@/other/Notify";
import moment from "moment";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",

      positions: [],
      departments: [],
      indicators: [],

      position_id: null,
      department_id: null,

      skill: null,
      domain_knowledge: null,
      management: null,
      administration: null,
      presentation_skill: null,
      quality_of_work: null,
      efficiency: null,
      integrity: null,
      professionalism: null,
      team_work: null,
      critical_thinking: null,
      conflict_management: null,
      attendance: null,
      dead_line: null,
      status: 1,
    };
  },

  methods: {
    list() {
      axios
        .get(Api.company.performace_indicators)
        .then((response) => {
          if (response) {
            var indicators = response.data.data;
            this.indicators = [];

            for (var indicator of indicators) {
              var id = indicator.id;
              var uuid = indicator.uuid;
              var position = indicator.position.position_name;
              var department = indicator.company_department.department_name;
              var added_by = "Company Admin";
              var created_at = moment(indicator.created_at).format(
                "DD-MM-YYYY"
              );
              var Status = indicator.status == 1 ? "Active" : "Inactive";
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.indicators.push([
                id,
                position,
                department,
                added_by,
                created_at,
                Status,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      var technical = {
        skill: this.skill,
        domain_knowledge: this.domain_knowledge,
        management: this.management,
        administration: this.administration,
        presentation_skill: this.presentation_skill,
        quality_of_work: this.quality_of_work,
        efficiency: this.efficiency,
      };

      var organizational = {
        integrity: this.integrity,
        professionalism: this.professionalism,
        team_work: this.team_work,
        conflict_management: this.conflict_management,
        attendance: this.attendance,
      };

      axios
        .post(Api.company.performace_indicators, {
          department_id: this.department_id,
          position_id: this.position_id,
          technical: JSON.stringify(technical),
          organizational: JSON.stringify(organizational),
          dead_line: this.dead_line,
          status: this.status,
        })
        .then((response) => {
          if (response) {
            this.list();
            Notify.updateNotification(
              "New performance indicator added successfully"
            );
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },

    getPosition() {
      axios
        .get(Api.company.departmentPostion + this.department_id)
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepartment() {
      axios
        .get(Api.company.departments)
        .then((response) => {
          if (response) {
            this.departments = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    this.getPosition();
    this.getDepartment();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
