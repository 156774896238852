<template>

    <div class="account-page" >
    <!-- Main Wrapper --> 
    <div class="main-wrapper">
     
      <div style="position: absolute;top:60px;right:  0;">

        <span style="color: #2E2A77;margin-top: 100px; margin-right: 100px;" > <i class="fa fa-question"></i> Help</span>


      </div>

      <div style="margin-top: 10%;" class="account-content ">
        <h2  class="account-title text-center">Welcome to PROHRM !</h2>

        <div class="container">

          <div class="account-box mt-5">
            <div class="account-wrapper">
              <p class="account-subtitle">Access to our dashboard</p>

              <!-- Account Form -->
              <form>
                <div class="form-group">
                  <label>Email Address</label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="Enter email"
                  />

                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label for="userpassword" class="form-label"
                        >Password</label
                      >
                    </div>
                    <div class="col-auto">
                      <a class="text-muted" href="auth-recoverpw">
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div class="position-relative">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="Enter password"
                    />

                    <span class="fa fa-eye-slash" id="toggle-password"></span>
                  </div>
                </div>
                <div class="form-group text-center">
              

                  <div id="loader-small">
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                  </div>

                  <button
                    @click.prevent="login"
                    class="btn btn-primary account-btn"
                  >
                    L O G I N
                  </button>
                </div>
                <div class="account-footer">
                  <p>
                    Don't have an account yet?
                   <router-link :to="{ name: 'register' }">Register</router-link>
                  </p>
                </div>
              </form>
              <!-- /Account Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->

    <!-- JAVASCRIPT -->
  </div>



  

</template>

<script>
import { Validator } from "@/other/Validator";
import $ from "jquery";

import axios from "axios";

export default {
  name: "LoginView",

  data() {
    return {};
  },

  methods: {
    login() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "email", value: this.email, type: "required" }, 

        {
          field: "password",
          value: this.password,
          type: "required|length",
          size: 8,
        },
      ]);

      if (error) {
        console.log(error);
      } else {

        var loader = document.getElementById("loader-small");

       loader.style.display = "block";
    
        axios
          .post("login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data));

            if (response.data.user.roles[0] == "superadmin") {
              this.$router
                .push({ name: "admin-dashboard" })
                .then(() => {
                  // window.location.reload();
                })
                .catch((error) => {
                  console.error(error);
                });
            }
            if (response.data.user.roles[0] == "company") {
             
               
              this.$router
                .push({ name: "company-dashboard" })
                .then(() => {
                  // window.location.reload();
                })
                .catch((error) => {
                  console.error(error);
                });
            }
            if (response.data.user.roles[0] == "employee") {
              this.$router.push({ name: "employee-dashboard" });
            }
          })
          .catch((error) => {

            
        var loader = document.getElementById("loader-small");

       loader.style.display = "none";


             
            if (error.response.data.errors.password) {
              $("#password").addClass('error-input')
              $("#password").val("")

              $("#password").attr("placeholder", error.response.data.errors.password[0]);
              
            }


            if (error.response.data.errors.email) {
              $("#email").addClass('error-input')
              $("#email").val("")

              $("#email").attr("placeholder", error.response.data.errors.email[0]);
            }
          });
      }
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
  },
  created() {
    var scripts = [


    "/assets/js/app.js",


    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      var allscript = document.getElementById("allscript");
      allscript.appendChild(tag);
    });

   
  }


};
</script>

<style>
</style>