<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Leave</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Leaves
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Leave type" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Number of Days" />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add Leave</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable
            :data="leaves"
            class="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Serial</th>
                <th>Leave Type</th>
                <th>Number of Days</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Leave</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Leave type<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.type=null"  v-model="type"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.type" >{{ errors.type[0] }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Number of days</label
                    >
                    <input
                      type="number"
                      min="0"
                      @input="errors.days=null" v-model="days"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.days" >{{ errors.days[0] }}</p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="addleave"
                  class="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Leave</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Leave type <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.type = null"
                      v-model="leave.type"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.type">
                      {{ errors.type[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Number of days</label
                    >
                    <input
                      type="number"
                      @input="errors.days = null"
                      v-model="leave.days"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.days">
                      {{ errors.days[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="updatedepartment(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <!-- <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <button
              type="button"
              class="close visually-hidden"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>

              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="deletedepartment"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
    
                    data-bs-dismiss="modal"
          
                 
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Delete Client Modal -->
  </div>
</template>
    
    <script>
import axios from "axios";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

import api from "@/other/Api";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      errors: {},
      leaves: [],

      leave: {},
      
      id: ""
    };
  },

  methods: {
    getleaves() {
      axios
        .get(api.company_leaves)
        .then((response) => {
          if (response) {
            
           var leaves = response.data.data;

           this.leaves=[]
           var serial=0;
           for(var leave of leaves){
             var id=leave.id

            serial++

            var type=leave.type
            var days=leave.days;

            var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                id +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                id +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

            

            this.leaves.push([serial,type,days,action]);

           }
        
            
        }
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
    addleave() {
      axios
        .post(api.company_leaves, {
          type: this.type,
          days: this.days,
        })
        .then((response) => {
          if (response) {
   
       

         
             
          //  this.$refs.addForm.reset();

           this.type="";
           this.days="";
              this.getleaves();
              $("#addModal .close").click()
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },


    updateleave(id) {
      axios
        .put(api.company_leaves + id, {
          type: this.leave.type,
          days: this.leave.days,
          
        })
        .then((response) => {
          if (response) {
            this.getleaves();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // deleteleave() {
    //   axios
    //     .delete("/company/departments/" + this.uuid)
    //     .then((response) => {
    //       if (response) {
      
    //         // $("#addModal .close").click();
    //         $("#delete_client .close").click();
    //         this.getdepartment();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    handleClick(id) {
      axios
        .get(api.company_leaves + id)
        .then((response) => {
          if (response) {
            this.leave = response.data.data;
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
    this.uuid=uuid
  

    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.handleClick = this.handleClick;
    window.deletePopUp = this.deletePopUp;
    this.getleaves();
  },
};
</script>
    
    
          
       <style>
@import "datatables.net-bs5";
</style>