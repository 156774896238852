<template>
    <div class="wrapper">
      <!-- Page Content -->
      <div class="content-wrapper p-4">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>{{ title }}</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Demand Job
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-plus"></i> Add {{ title }}</a
              >
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable :data="job_demands" class="table table-striped custom-table">
              <thead>
                <tr>
                  <th>ID</th>
                 
                          <th>Job Title/Position</th>
                          <th>Department</th>
                          <th>Created at</th>
  
                          <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        > Department<span class="text-danger">*</span></label
                      >
          
                          <select
                              @input="clearError('department_id')"
                              @change="getposition(department_id)"
                              v-model="department_id"
                              class="form-control form-select"
                              style="width: 100%"
                            >
                            <option disabled value="null">Select a department</option>
                            <option
                            v-for="department in departments"
                            :key="department"
                             :value="department.id"
                             >{{ department.department_name }}</option>
                            </select>
                        <p class="text-danger mt-1" v-if="errors.day">
                          {{ errors.day[0] }}
                        </p>
                    
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        > Position<span class="text-danger">*</span></label
                      >
          
                          <select
                              v-model="position_id"
                              @input="clearError('position_id')"
                              class="form-control form-select"
                              style="width: 100%"
                            >
                            <option disabled value="null">Select a position</option>
                            <option
                            v-for="position in positions"
                            :key="position"
                             :value="position.id"
                             >{{ position.position_name }}</option>
                            </select>
                        <p class="text-danger mt-1" v-if="errors.day">
                          {{ errors.day[0] }}
                        </p>
                    
                    </div>
                  </div>
              
  
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="col-form-label">No of position</label>
                      <input
                        type="text"
                        @input="errors.note = null"
                        v-model="quantity"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.note">
                        {{ errors.note[0] }}
                      </p>
                    </div>
                  </div>
                 
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label">When to recruit</label>
                      <input
                        type="date"
                        @input="errors.note = null"
                        v-model="recruitement_date"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.note">
                        {{ errors.note[0] }}
                      </p>
                    </div>
                  </div>
                 
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="col-form-label">Qualification</label>
                      <ckeditor
                              style="height: 100px !important"
                              :editor="editor"
                              v-model="qualification"
                              :config="editorConfig"
                            ></ckeditor>
                      <p class="text-danger mt-1" v-if="errors.rate">
                        {{ errors.rate[0] }}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="col-form-label">Job Discription</label>
                      <ckeditor
                              style="height: 100px !important"
                              :editor="editor"
                              v-model="job_description"
                              :config="editorConfig"
                            ></ckeditor>
                      <p class="text-danger mt-1" v-if="errors.rate">
                        {{ errors.rate[0] }}
                      </p>
                    </div>
                  </div>
  
  
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button @click.prevent="add" class="btn btn-primary submit-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="editModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update {{ title }}</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} name
                        <span class="text-danger">*</span></label
                      >
                      <input
                        id="okk"
                        type="text"
                        @input="errors.department_name = null"
                        v-model="department.department_name"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.department_name">
                        {{ errors.department_name[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ title }} ID(Max 2 Digit)</label
                      >
                      <input
                        i
                        type="text"
                        @input="errors.code = null"
                        v-model="department.code"
                        class="form-control"
                      />
                      <p class="text-danger mt-1" v-if="errors.code">
                        {{ errors.code[0] }}
                      </p>
                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="update(department.uuid)"
                    class="btn btn-primary submit-btn"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                  type="button"
                  class="close visually-hidden"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
        
  <script>
  
  import $ from "jquery";
  import axios from "axios";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Constant from "@/other/Constant";
  import Api from "@/other/Api";
  
  
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";

  import { Validator } from "@/other/Validator";
  
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
        title:"Job Demand",
        editor: ClassicEditor,
        editorData: "",
        editorConfig: {
          height: "100px",
        },
        errors: {},
        weekends: [],
        department: {},
        department_id:null,
        day: null,
        departments:[],
        job_demands:[],
        positions:[],
        position_id:null
      };
    },
  
    methods: {
      list() {
          axios
          .get(Api.employee.job_demand)
          .then((response) => {
            var job_demands = response.data.data;
            this.job_demands=[]
            

            for(var job_demand of job_demands){
             var uuid=job_demand.uuid
           var position_name=job_demand.position!=null?job_demand.position.position_name:""
           var department_name=job_demand.department!=null?job_demand.department.department_name:""

           var quantity=job_demand.quantity
           var qualification=job_demand.qualification
           var action =
                  "<a href='#' onclick='handleClick(" +
                  '"' +
                  uuid +
                  '"' +
                  ")' ' data-bs-toggle='modal' data-bs-target='#addModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                  '"' +
                  uuid +
                  '"' +
                  ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";
           this.job_demands.push([department_name,position_name,quantity,qualification,action]);

          }
           
          })
          .catch((error) => {
            console.log(error);
          });
      },
      aplyjob() {
        var formData = new FormData();
        formData.append("applicant_name", this.applicant_name);
        formData.append("cover_leter", this.cover_leter);
        formData.append("published_job_id", this.published_job_id);
  
        for (var doc of this.new_documents) {
          formData.append("doc_names[]", doc.doc_name);
          formData.append("files[]", doc.file);
          formData.append("types[]", doc.type);
          formData.append("document_types[]", doc.document_type);
        }
  
        axios
          .post("company/job_appliocations", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
  
            this.list();
            $("#addModal .close").click();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      handleClick(uuid) {
        this.getApplication(uuid);
  
        // this.$router.push({ name: "jobdetails", query: { uuid: id } });
      },
      getApplication(uuid) {
        this.applicant_name = "";
        this.title = "Update";
        this.documents = [];
  
        axios
          .get("company/job_appliocations/" + uuid)
          .then((response) => {
            if (response) {
              this.applicant_name = response.data.data.applicant_name;
              this.published_job_id = response.data.data.employee_job_demand_id;
              this.cover_leter = response.data.data.cover_leter;
              var files = response.data.data.files;
  
              if (files.length > 0) {
                for (var file of files) {
                  var doc = {
                    doc_name: file.file_info.document_name,
                    file: "",
                    type: file.file_info.type,
                    document_type: file.file_info.document_type,
                    path: Constant.filebaseurl + file.path,
                  };
                  this.documents.push(doc);
                }
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      },


      add() {
        var validator = new Validator();
      var error = validator.validated([
        { field: "department_id", value: this.department_id, type: "required" },
        { field: "position_id", value: this.position_id, type: "required" },
        { field: "quantity", value: this.quantity, type: "required" },
        // { field: "qualification", value: this.qualification, type: "required" },
        {
          field: "recruitement_date",
          value: this.recruitement_date,
          type: "required",
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        var job_demand = {
          department_id: this.department_id,
          position_id: this.position_id,
          quantity: this.quantity,
          qualification: this.qualification,
          salary_range: this.salary_range,
          recruitement_date: this.recruitement_date,
          job_description: this.job_description,
        };

        axios
          .post(Api.employee.job_demand, job_demand)
          .then((response) => {
            if (response) {
              $("#addModal .close").click()
              this.list();
            
              

            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
      },
      update(uuid) {
        axios
          .put("/company/departments/" + uuid, {
            department_name: this.department.department_name,
            d_id: this.department.d_id,
          })
          .then((response) => {
            if (response) {
              this.list();
  
              $("#editModal .close").click();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 
      delete_single() {
        axios
          .delete("/company/departments/" + this.uuid)
          .then((response) => {
            if (response) {
              $("#deleteModal.close").click();
              this.list();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      edit(id) {
        axios
          .get("/company/departments/" + id)
          .then((response) => {
            if (response) {
              this.department = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deletePopUp(uuid) {
        this.uuid = uuid;
      },
  
      getPublishedJob() {
        this.publishedjobs = [];
        axios
  
          .get("company/publishedjob")
          .then((response) => {
            this.publishedjobs = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      onFileSelect(event) {
        this.file = event.target.files[0];
      },
      adddocument() {
        if (this.file) {
          var doc = {
            doc_name: this.doc_name,
            file: this.file,
            type: this.file.type,
            path: URL.createObjectURL(this.file),
            document_type: "Applicant document",
          };
          this.new_documents.push(doc);
          this.documents.push(doc);
        }
      },

      getdepartment() {
      axios
        .get(Api.employee.departments)
        .then((response) => {
          if (response) {
            this.departments = response.data.data;


          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getposition(department_id) {
      axios
        .get(Api.employee.departmentPostion+department_id)
        .then((response) => {
          if (response) {
            this.positions = response.data.data;


          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    createjobdemand() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "department_id", value: this.department_id, type: "required" },
        { field: "position_id", value: this.position_id, type: "required" },
        { field: "quantity", value: this.quantity, type: "required" },
        // { field: "qualification", value: this.qualification, type: "required" },
        {
          field: "recruitement_date",
          value: this.recruitement_date,
          type: "required",
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        var job_demand = {
          department_id: this.department_id,
          position_id: this.position_id,
          quantity: this.quantity,
          qualification: this.qualification,
          salary_range: this.salary_range,
          recruitement_date: this.recruitement_date,
          job_description: this.job_description,
        };

        axios
          .post(Api.employee.job_demand, job_demand)
          .then((response) => {
            if (response) {
              $("#addModal .close").click()
            
              
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
    
    clearError(field) {
      $("#" + field).css("display", "none");
    },



    },
  
    mounted: function () {
      this.name = JSON.parse(localStorage.getItem("user")).user.name;
      window.edit = this.edit;
      window.deletePopUp = this.deletePopUp;
      this.getPublishedJob();
      window.handleClick = this.handleClick;
      this.list();
      this.getdepartment()
    },
  };
  </script>
        
        
              
           <style>
  @import "datatables.net-bs5";
  </style>