<template>
    <div
      class="account-page"
     
    >
      <!-- Main Wrapper -->
      <div class="main-wrapper">
        <div style="position: absolute; top: 60px; right: 0">
          <span style="color: #2e2a77; margin-top: 100px; margin-right: 100px">
            <i class="fa fa-question"></i> Help</span
          >
        </div>
  
        <div class="account-content sdfds">
          <div class="container">
            <h2 class="account-title text-center mt-5">Welcome to PROHRM !</h2>
  
            <div class="account-box register mt-5">
              <div class="account-wrapper">
                <p class="account-subtitle">Verify your account</p>
             
        
                <!-- Account Form -->
                <form class="mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input
                          @input="validator.clear('email')"
                          id="email"
                          v-model="email"
                          type="text"
                          class="form-control"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          @input="validator.clear('code')"
                          id="code"
                          v-model="code"
                          type="text"
                          class="form-control"
                          placeholder="Enter verfication code"
                        />
                      </div>
                    </div>
  
                
                  </div>
  
               
  
               
  
                  <div class="form-group text-center">
                    <div id="loader-small">
                      <span class="loading__dot_small"></span>
                      <span class="loading__dot_small"></span>
                      <span class="loading__dot_small"></span>
                    </div>
                    <button
                      @click.prevent="verify"
                      class="btn btn-primary account-btn"
                    >
                      Verify
                    </button>
                  </div>
  
                  <div class="account-footer">
                    <p>
                      If you have an account yet?
                     <router-link :to="{ name: 'login' }">Login</router-link>
                    </p>
                  </div>
                </form>
                <!-- /Account Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Main Wrapper -->
  
      <!-- JAVASCRIPT -->
    </div>
  </template>
    
    <script>
  import { Validator } from "@/other/Validator";
  import $ from "jquery";
  
  import Notify from "@/other/Notify";
  
  import axios from "axios";
  
  export default {
    name: "LoginView",
  
    data() {
      return {
        
        validator: new Validator(),
        email : "",
        code : "",
    
       
      };
    },
  
    methods: {
      pricebtntoggle(event, id) {
        let button = event.target;
        var buttons = document.getElementsByClassName("price-btn");
  
        for (var i = 0; i < buttons.length; i++) {
          buttons[i].classList.remove("price-btn-fill");
        }
  
        button.classList.toggle("price-btn-fill");
  
        this.price_id = id;
        this.price_error = "";
      },
      getCountry() {
        axios
          .get("public/countries")
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      verify() {
        var validator = new Validator();
        var error = validator.validated([
  
          { field: "email", value: this.email, type: "required|email" },
          { field: "code", value: this.code, type: "required" },
  
       
        ]);
  
        if (error) {
          console.log(error);
        } else {
          var loader = document.getElementById("loader-small");
  
          loader.style.display = "block";
  
          axios
            .post("verification", {
              email: this.email,
              code: this.code,
         
            })
            .then((response) => {
              console.log(response.data);
              var loader = document.getElementById("loader-small");
  
              loader.style.display = "none";
              Notify.updateNotification(response.data.message);
  
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
  
              var loader = document.getElementById("loader-small");
  
               loader.style.display = "none";
  
              
               Notify.updateNotification("", error.response.data.message, "error");

  
           
           
            });
        }
      },
  
      getprice() {
        axios
          .get("/public/prices")
          .then((response) => {
            if (response) {
              this.prices = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      clearError(field) {
        $("#" + field).css("display", "none");
      },
    },
    mounted() {
      this.getprice();
      this.getCountry();
      this.email = this.$route.query.email;
    },
  };
  </script>
    
    <style>
  </style>