<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="notification-popup hide">
      <p>
        <span class="task"></span>
        <span class="notification-text"></span>
      </p>
    </div>
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Performance Appraisal</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                   </router-link>
                  </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Performance Appraisal
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        

        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Department"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
        
        
        <div class="col-md-4">
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add New</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">

          <DataTable class="table table-bordered table-striped custom-table" :data="performance_apraisals">

            <thead>
              <tr>
                <th>SL</th>
                <th>Employee </th>
                <th>Position</th>
                <th>Department</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>

          </DataTable>
      
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Performance Appraisal Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Give Performance Appraisal</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employee</label>
                    <select
                      @change="getEmployee"
                      v-model="employee_id"
                      class="form-control form-select"
                    >
                      <option value="null">Select Employee</option>

                      <option
                        v-for="employee in employees"
                        :key="employee.id"
                        :value="employee.id"
                      >
                        {{ JSON.parse(employee.basic_information).first_name }}
                        {{ JSON.parse(employee.basic_information).last_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-2" v-if="errors.employee_id">
                      {{ errors.employee_id[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Select Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control datetimepicker" type="date" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-box">
                        <div class="row user-tabs">
                          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                            <ul class="nav nav-tabs nav-tabs-solid">
                              <li class="nav-item">
                                <a
                                  href="#appr_technical"
                                  data-bs-toggle="tab"
                                  class="nav-link active"
                                  >Technical</a
                                >
                              </li>
                              <li class="nav-item">
                                <a
                                  href="#appr_organizational"
                                  data-bs-toggle="tab"
                                  class="nav-link"
                                  >Organizational</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div
                          id="appr_technical"
                          class="pro-overview tab-pane fade show active"
                        >
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Technical Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Skill</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).skill
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>

                                      <td>
                                        <select
                                          v-model="skill"
                                          class="form-control form-select"
                                        >
                                          <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Domain Knowledge</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).domain_knowledge
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="domain_knowledge"
                                          class="form-control form-select"
                                        >
                                          <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Management</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).management
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="management"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Administration</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).administration
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="administration"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Presentation Skill</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).presentation_skill
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="presentation_skill"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Quality Of Work</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).quality_of_work
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="quality_of_work"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Efficiency</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.technical
                                          ).efficiency
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="efficiency"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="appr_organizational">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Organizational Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Integrity</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).integrity
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="integrity"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Professionalism</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).professionalism
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="professionalism"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Team Work</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).team_work
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          name="team_work"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Conflict Management</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).conflict_management
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="critical_thinking"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colspan="2">Attendance</td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{
                                          JSON.parse(
                                            performance_indicator.organizational
                                          ).attendance
                                        }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="attendance"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        Ability To Meet Deadline
                                      </td>
                                      <td
                                        v-if="
                                          Object.keys(performance_indicator)
                                            .length != 0
                                        "
                                        colspan="2"
                                      >
                                        {{ performance_indicator.dead_line }}
                                      </td>
                                      <td v-else colspan="2"></td>
                                      <td>
                                        <select
                                          v-model="dead_line"
                                          class="form-control form-select"
                                        >
                                        <option value="None">None</option>
                                          <option value="Beginner">Beginner</option>
                                          <option value="Intermediate">
                                            Intermediate
                                          </option>
                                          <option value="Advanced">Advanced</option>
                                          <option value="Expert / Leader">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select v-model="status" class="select">
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Performance Appraisal Modal -->

    <!-- Edit Performance Appraisal Modal -->
    <div id="edit_appraisal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Performance Appraisal</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employee</label>
                    <select class="select">
                      <option>Select Employee</option>
                      <option>Sarwar Jahan Morshed</option>
                      <option selected>Pankaz Debnath</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      >Select Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="date" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-box">
                        <div class="row user-tabs">
                          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                            <ul class="nav nav-tabs nav-tabs-solid">
                              <li class="nav-item">
                                <a
                                  href="#appr_technical1"
                                  data-bs-toggle="tab"
                                  class="nav-link active"
                                  >Technical</a
                                >
                              </li>
                              <li class="nav-item">
                                <a
                                  href="#appr_organizational1"
                                  data-bs-toggle="tab"
                                  class="nav-link"
                                  >Organizational</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div
                          id="appr_technical1"
                          class="pro-overview tab-pane fade show active"
                        >
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Technical Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Customer Experience</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="customer_experience"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Marketing</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="marketing"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Management</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="management"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Administration</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="administration"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Presentation Skill</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="presentation_skill"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Quality Of Work</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="quality_of_work"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Efficiency</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="efficiency"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="appr_organizational1">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Organizational Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Integrity</td>
                                      <td colspan="2">Beginner</td>
                                      <td>
                                        <select
                                          name="integrity"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Professionalism</td>
                                      <td colspan="2">Beginner</td>
                                      <td>
                                        <select
                                          name="professionalism"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Team Work</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="team_work"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Critical Thinking</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="critical_thinking"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Conflict Management</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="conflict_management"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Attendance</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="attendance"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        Ability To Meet Deadline
                                      </td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="ability_to_meet_deadline"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Performance Appraisal Modal -->

    <!-- Delete Performance Appraisal Modal -->
    <div class="modal custom-modal fade" id="delete_appraisal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Performance Appraisal List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Performance Appraisal Modal -->
  </div>
</template>
          
          <script>
import axios from "axios";
//   import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  // import DataTablesCore from "datatables.net-bs5"
import $ from "jquery";
import Api from "@/other/Api";
import Notify from "@/other/Notify";

//   DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Weekend",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
      employees: [],
      employee_id: null,

      employee: {},

      performance_indicator: {},
      performance_apraisals:[],

      skill: null,
      domain_knowledge: null,
      management: null,
      administration: null,
      presentation_skill: null,
      quality_of_work: null,
      efficiency: null,
      integrity: null,
      professionalism: null,
      team_work: null,
      critical_thinking: null,
      conflict_management: null,
      attendance: null,
      dead_line: null,
      status: 1,
    };
  },

  methods: {
    getemployees() {
      axios
        .get(Api.company.emoloyee_list)
        .then((response) => {
          if (response) {
            this.employees = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEmployee() {
      axios
        .get(Api.company.employee + this.employee_id)
        .then((response) => {
          if (response) {
            this.employee = response.data.data;

            this.performance_indicator =
              this.employee.position.performance_indicator;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    list() {
      axios
        .get(Api.company.performace_apprisals)
        .then((response) => {
          if (response) {
            var performance_apraisals = response.data.data;
            this.performance_apraisals = [];

            for (var performance_apraisal of performance_apraisals) {
              var id = performance_apraisal.id;
              var uuid = performance_apraisal.uuid;
              var employee_name = JSON.parse(performance_apraisal.employee.basic_information).first_name +" "+ JSON.parse(performance_apraisal.employee.basic_information).last_name;

              var designation = performance_apraisal.employee.designation.position_name;

              var department = performance_apraisal.employee.company_department.department_name;
              var date = performance_apraisal.created_at;
              var status=performance_apraisal.status;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-eye'></i></a>   ";

              this.performance_apraisals.push([id, employee_name, designation, department, date,status,action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      var technical = {
        skill: this.skill,
        domain_knowledge: this.domain_knowledge,
        management: this.management,
        administration: this.administration,
        presentation_skill: this.presentation_skill,
        quality_of_work: this.quality_of_work,
        efficiency: this.efficiency,
      };

      var organizational = {
        integrity: this.integrity,
        professionalism: this.professionalism,
        team_work: this.team_work,
        conflict_management: this.conflict_management,
        attendance: this.attendance,
      };

      axios
        .post(Api.company.performace_apprisals, {
          employee_id: this.employee_id,
          technical: JSON.stringify(technical),
          organizational: JSON.stringify(organizational),
          status: this.status,
        })
        .then((response) => {
          if (response) {
            this.list();
            Notify.updateNotification(
              "New performance apprisal added successfully"
            );
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    this.getemployees();
  },
};
</script>
          
          
                
             <style>
@import "datatables.net-bs5";
</style>