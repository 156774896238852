<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Service Role</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  service role
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <!-- <div class="row filter-row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <div class="add-emp-section">
                                <a href="#" class="btn btn-success btn-add-emp" data-bs-toggle="modal" data-bs-target="#add_appraisal"><i class="fas fa-plus"></i> Add New</a>
                            </div>
                        </div>
                    </div> -->
      <!-- /Search Filter -->
<!-- 
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  
                  <th>Service Role</th>
                  <th>Description</th>
                  <th>Approved Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1001</td>

                  <td>something</td>

                  <td>2023-02-20</td>

                  <td>
                    <p style="color: green; font-weight: 600">Approved</p>
                  </td>
                  <td class="ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fas fa-eye"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fas fa-print"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>1002</td>
                  <td>something</td>
                  <td>0123456789</td>

                  <td>
                    <p style="color: green; font-weight: 600">Approved</p>
                  </td>
                  <td class="ico-sec">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fas fa-eye"></i
                    ></a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fas fa-print"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->

       <div class="row">
        <div class="col-md-12">
          <DataTable :data="serviceRules" class="table table-striped custom-table">
            <thead>
              <tr>               
                <th>Title</th>
                <th>Created on</th>
                <th>Approved on</th>              
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Performance Appraisal Modal -->
    <div id="add_appraisal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Give Performance Appraisal</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employee</label>
                    <select class="select">
                      <option>Select Employee</option>
                      <option>Sarwar Jahan Morshed</option>
                      <option>Pankaz Debnath</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Select Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-box">
                        <div class="row user-tabs">
                          <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                            <ul class="nav nav-tabs nav-tabs-solid">
                              <li class="nav-item">
                                <a
                                  href="#appr_technical"
                                  data-bs-toggle="tab"
                                  class="nav-link active"
                                  >Technical</a
                                >
                              </li>
                              <li class="nav-item">
                                <a
                                  href="#appr_organizational"
                                  data-bs-toggle="tab"
                                  class="nav-link"
                                  >Organizational</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div
                          id="appr_technical"
                          class="pro-overview tab-pane fade show active"
                        >
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Technical Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Customer Experience</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="customer_experience"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Marketing</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="marketing"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Management</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="management"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Administration</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="administration"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Presentation Skill</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="presentation_skill"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Quality Of Work</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="quality_of_work"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Efficiency</td>
                                      <td colspan="2">Expert / Leader</td>
                                      <td>
                                        <select
                                          name="efficiency"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                          <option value="4">
                                            Expert / Leader
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="appr_organizational">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="bg-white">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Organizational Competencies</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th colspan="2">Indicator</th>
                                      <th colspan="2">Expected Value</th>
                                      <th>Set Value</th>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Integrity</td>
                                      <td colspan="2">Beginner</td>
                                      <td>
                                        <select
                                          name="integrity"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Professionalism</td>
                                      <td colspan="2">Beginner</td>
                                      <td>
                                        <select
                                          name="professionalism"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Team Work</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="team_work"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Critical Thinking</td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="critical_thinking"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Conflict Management</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="conflict_management"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">Attendance</td>
                                      <td colspan="2">Intermediate</td>
                                      <td>
                                        <select
                                          name="attendance"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        Ability To Meet Deadline
                                      </td>
                                      <td colspan="2">Advanced</td>
                                      <td>
                                        <select
                                          name="ability_to_meet_deadline"
                                          class="form-control form-select"
                                        >
                                          <option value="">None</option>
                                          <option value="1">Beginner</option>
                                          <option value="2">
                                            Intermediate
                                          </option>
                                          <option value="3">Advanced</option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Performance Appraisal Modal -->

    <!-- Edit Performance Appraisal Modal -->
    <div id="edit_appraisal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rules and Regulations</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>

          <div class="card-body">
            <div class="role-card">
              <div style="width: 100%; font-weight: bold">
                <p>Created Date: <span>{{ serviceRule.creating_date }}</span></p>
                <p>Approved Date: <span>{{ serviceRule.appreve_date }}</span></p>
              </div>
              <h2>{{ serviceRule.descripton }}</h2>
            
              <p>
               {{ serviceRule.sr_details }}
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Performance Appraisal Modal -->

    <!-- Delete Performance Appraisal Modal -->
    <div class="modal custom-modal fade" id="delete_appraisal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Performance Appraisal List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Performance Appraisal Modal -->
  </div>
</template>

<script>
import axios from "axios";
  // import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

  DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Service Roles",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
      serviceRules: [], 
      serviceRule: {},
       
    };
  },

  methods: {
    list() {
   axios
     .get("/employee/serviceRule/byemployee")
     .then((response) => {
       if (response) {
         this.serviceRules = [];

         var serviceRules = response.data.data.rule;

         

         for (var serviceRule of serviceRules) {
           var uuid = serviceRule.uuid;
           var descripton = serviceRule.descripton;
           var creating_date = serviceRule.creating_date;
           var appreve_date = serviceRule.appreve_date;

           var action =
             "<a href='#' onclick='handleClick(" +
             '"' +
             uuid +
             '"' +
             ")' ' data-bs-toggle='modal' data-bs-target='#edit_appraisal'><i class='fas fa-eye'></i></a>   <a href='#' onclick='handleClick(" +
             '"' +
             uuid +
             '"' +
             ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 fas fa-print'></i></a> ";

           this.serviceRules.push([descripton, creating_date, appreve_date, action]);
         }
       }
     })
     .catch((error) => {
       console.log(error);
     });
 },

    add() {
      axios
        .post("/company/weekends", {
          note: this.note,
          day: this.day,
          rate: this.rate,
        })
        .then((response) => {
          if (response) {
            this.rate;

            this.holiday_name = "";
            this.note = "";
            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    show(uuid){
      axios
       .get("/employee/serviceRule/byemployee/" + uuid)
       .then((response) => {
          if (response) {
            this.serviceRule = response.data.data.rule[0];
            
          }
        })
       .catch((error) => {
          console.log(error);
        });

    },

    handleClick(uuid) {
      this.uuid = uuid;
      this.show(uuid);
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    window.handleClick = this.handleClick;
  },
};
</script>

<style>
@import "datatables.net-bs5";

.role-card {
  padding: 20px;
  background: #ffffff;
  margin: 20px 0;
  border-radius: 8px;
}
h1,
h2,
h3 {
  color: #35424a;
}
</style>
