<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Assets</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Assets
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Asset Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Asset ID"
                />
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Model Name"
                />
              </div>
            </div>
           
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="add-emp-section">

          <router-link class="btn btn-success btn-add-emp" :to="{ name: 'company-assets-category' }">
            <i class="fas fa-plus"></i> Add Asset Category
          </router-link>
            
            
          </div>
        </div>

        <div class="col-md-2">
         
          <div class="add-emp-section">
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add Asset</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          
            <DataTable :data="assets" class="table table-striped custom-table">
              <thead>
                <tr>
                  <th>Asset Id</th>
                  <th>Asset Category</th>
                  <th>Asset Name</th>
                  <th>Model Name</th>
                  <th>Purchase Date</th>
                  <th>Warrenty</th>
                  <th>Amount</th>
                  <th>Assign To</th>
                  <th class="text-center">Status</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Asset Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Asset</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset Category</label>

                    <input
                      type="text"
                      @input="errors.asset_category = null"
                      v-model="asset_category"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.asset_category">
                      {{ errors.asset_category[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset Title</label>

                    <input
                      type="text"
                      @input="errors.asset_name = null"
                      v-model="asset_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.asset_name">
                      {{ errors.asset_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset Id</label>

                    <input
                      type="text"
                      @input="errors.asset_id = null"
                      v-model="asset_id"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.asset_id">
                      {{ errors.asset_id[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Value</label>
                    <input
                      placeholder="$1800"
                      v-model="value"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Purchase Date</label>

                    <input
                      type="date"
                      @input="errors.purchase_date = null"
                      v-model="purchase_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.purchase_date">
                      {{ errors.purchase_date[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Purchase From</label>
                    <input
                      type="text"
                      @input="errors.purchase_from = null"
                      v-model="purchase_from"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.purchase_from">
                      {{ errors.purchase_from[0] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Manufacturer</label>
                    <input
                      type="text"
                      @input="errors.manufacturer = null"
                      v-model="manufacturer"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.manufacturer">
                      {{ errors.manufacturer[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Model</label>

                    <input
                      type="text"
                      @input="errors.model = null"
                      v-model="model"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.model">
                      {{ errors.model[0] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Serial Number</label>
                    <input
                      class="form-control"
                      v-model="serial_number"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Supplier</label>
                    <input
                      class="form-control"
                      v-model="supplier"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Condition</label>
                    <input
                      class="form-control"
                      v-model="condition"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Warranty</label>
                    <input
                      class="form-control"
                      v-model="warranty"
                      type="text"
                      placeholder="In Months"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Description</label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="sr_details"
                      :config="editorConfig"
                    ></ckeditor>
                    <!-- <textarea v-model="description" class="form-control"></textarea> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Status</label>
                    <select v-model="status" class="form-control form-select">
                      <option :value="1">Unssigned</option>

                      <option :value="2">Assigned</option>

                      <option :value="3">Deployed</option>
                      <option :value="4">Damaged</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Asset Modal -->

    <!-- Edit Asset Modal -->
    <div id="edit_asset" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Asset</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Dell Laptop"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset Id</label>
                    <input
                      class="form-control"
                      type="text"
                      value="#AST-0001"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Purchase Date</label>
                    <input class="form-control datetimepicker" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Purchase From</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Manufacturer</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Model</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Serial Number</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Supplier</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Condition</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Warranty</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="In Months"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Value</label>
                    <input
                      placeholder="$1800"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Asset User</label>
                    <select class="select">
                      <option>Sarwar Jahan Morshed</option>
                      <option>Sabuj Ahmed</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Status</label>
                    <select class="select">
                      <option>Pending</option>
                      <option>Approved</option>
                      <option>Deployed</option>
                      <option>Damaged</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Asset Modal -->
    <div class="notification-popup hide">
      <p>
        <span class="task"></span>
        <span class="notification-text"></span>
      </p>
    </div>
    <!-- Delete Asset Modal -->
    <div class="modal custom-modal fade" id="delete_asset" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Asset</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Asset Modal -->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";
import Api from "@/other/Api";
import Notify from "@/other/Notify";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "Assets",
      weekends: [],
      assets: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "90px",
      },
    };
  },

  methods: {
    list() {
      axios
        .get(Api.company.assets)
        .then((response) => {
          if (response) {
            var assets = response.data.data;
            this.assets = [];

            for (var asset of assets) {
              var uuid = asset.uuid;
              var asset_name = asset.asset_name;
              var asset_id = asset.asset_id;
              var purchase_date = asset.purchase_date;
              var warranty = asset.warranty;
              var amount = asset.value;
              var assigned_to = asset.assigned_to;
              var status = asset.status;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.assets.push([
                asset_name,
                asset_id,
                purchase_date,
                warranty,
                amount,
                assigned_to,
                status,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    add() {
      axios
        .post(Api.company.assets, {
          asset_category: this.asset_category,  
          asset_name: this.asset_name,
          asset_id: this.asset_id,
          value: this.value,
          purchase_date: this.purchase_date,
          purchase_from: this.purchase_from,
          manufacturer: this.manufacturer,
          model: this.model,
          serial_number: this.serial_number,
          supplier: this.supplier,
          condition: this.condition,
          warranty: this.warranty,
          description: this.description,
          status: this.status,
        })
        .then((response) => {
          if (response) {
            Notify.updateNotification("New asset added successfully");

            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
