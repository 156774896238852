<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Salary</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Salary
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Payslip ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Month"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
      </div>
      <!-- Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table datatable">
              <thead>
                <tr>
                  <th>Payslip ID</th>
                  <th>Month</th>
                  <th>Year</th>
                  <th>Date</th>
                  <th>Salary</th>
                  <th>Status</th>
                  <th>Status</th>

                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>FT-0001</td>
                  <td>January</td>
                  <td>2024</td>
                  <td>1 Jan 2024</td>

                  <td>$596</td>

                  <td>
                    <div class="dropdown">
                      <a
                        href=""
                        class="role-info role-bg-one dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >Paid
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">Paid</a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <router-link
                      class="role-info role-bg-two"
                      :to="{ name: 'employee-payslip' }"
                      >Download Slip</router-link
                    >
                  </td>
                  <td class="text-end ico-sec">
                    <a
                      class="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_salary"
                      ><i class="fas fa-pen"></i
                    ></a>
                    <a
                      class="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_salary"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Salary Modal -->
    <div id="add_salary" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Staff Salary</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Select Staff</label>
                    <select class="select">
                      <option>Sarwar Jahan Morshed</option>
                      <option>Sabuj Ahmed</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>Net Salary</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <h4 class="text-primary">Earnings</h4>
                  <div class="form-group">
                    <label>Basic</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>DA(40%)</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>HRA(15%)</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Conveyance</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Allowance</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Medical Allowance</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Others</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="add-more">
                    <a href="#"><i class="fa fa-plus-circle"></i> Add More</a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h4 class="text-primary">Deductions</h4>
                  <div class="form-group">
                    <label>TDS</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>ESI</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>PF</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Leave</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Prof. Tax</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Labour Welfare</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Others</label>
                    <input class="form-control" type="text" />
                  </div>
                  <div class="add-more">
                    <a href="#"><i class="fa fa-plus-circle"></i> Add More</a>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Salary Modal -->

    <!-- Edit Salary Modal -->
    <div id="edit_salary" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Staff Salary</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Select Staff</label>
                    <select class="select">
                      <option>Sarwar Jahan Morshed</option>
                      <option>Sabuj Ahmed</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>Net Salary</label>
                  <input class="form-control" type="text" value="$400" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <h4 class="text-primary">Earnings</h4>
                  <div class="form-group">
                    <label>Basic</label>
                    <input class="form-control" type="text" value="$650" />
                  </div>
                  <div class="form-group">
                    <label>DA(40%)</label>
                    <input class="form-control" type="text" value="$200" />
                  </div>
                  <div class="form-group">
                    <label>HRA(15%)</label>
                    <input class="form-control" type="text" value="$120" />
                  </div>
                  <div class="form-group">
                    <label>Conveyance</label>
                    <input class="form-control" type="text" value="$70" />
                  </div>
                  <div class="form-group">
                    <label>Allowance</label>
                    <input class="form-control" type="text" value="$30" />
                  </div>
                  <div class="form-group">
                    <label>Medical Allowance</label>
                    <input class="form-control" type="text" value="$20" />
                  </div>
                  <div class="form-group">
                    <label>Others</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <h4 class="text-primary">Deductions</h4>
                  <div class="form-group">
                    <label>TDS</label>
                    <input class="form-control" type="text" value="$90" />
                  </div>
                  <div class="form-group">
                    <label>ESI</label>
                    <input class="form-control" type="text" value="$20" />
                  </div>
                  <div class="form-group">
                    <label>PF</label>
                    <input class="form-control" type="text" value="$20" />
                  </div>
                  <div class="form-group">
                    <label>Leave</label>
                    <input class="form-control" type="text" value="$250" />
                  </div>
                  <div class="form-group">
                    <label>Prof. Tax</label>
                    <input class="form-control" type="text" value="$110" />
                  </div>
                  <div class="form-group">
                    <label>Labour Welfare</label>
                    <input class="form-control" type="text" value="$10" />
                  </div>
                  <div class="form-group">
                    <label>Fund</label>
                    <input class="form-control" type="text" value="$40" />
                  </div>
                  <div class="form-group">
                    <label>Others</label>
                    <input class="form-control" type="text" value="$15" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Salary Modal -->

    <!-- Delete Salary Modal -->
    <div class="modal custom-modal fade" id="delete_salary" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Salary</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Salary Modal -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
