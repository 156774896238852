<template>
  <div class="wrapper">
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3 data-bs-target="#attendance_info">Attendance</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Attendance
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Month</option>
                  <option>January</option>
                  <option>February</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>June</option>
                  <option>July</option>
                  <option>August</option>
                  <option>September</option>
                  <option>October</option>
                  <option>November</option>
                  <option>December</option>
                  
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Year</option>
                  <option>2024</option>
                  <option>2023</option>
                  <option>2022</option>
                  <option>2021</option>
                  <option>2020</option>
                  <option>2019</option>
                  <option>2018</option>
                  <option>2017</option>
                  <option>2016</option>
                  <option>2015</option>
                  <option>2014</option>
                  <option>2013</option>
                  
                  
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive att-table">
            <table class="table table-striped custom-table table-nowrap mb-0">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th v-for="i in 31" :key="i">{{ i }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="employee in employees" :key="employee">
                  <td>
                    <h2 class="table-avatar">
                      <a class="avatar" href="profile"
                        ><img
                          alt=""
                          src="<?php echo $baseurl;?>/assets/img/profiles/avatar-09.jpg"
                      /></a>
                      <a href="profile"
                        >{{
                          JSON.parse(employee.basic_information).first_name
                        }}
                        {{
                          JSON.parse(employee.basic_information).last_name
                        }}</a
                      >
                    </h2>
                  </td>
                  <td v-for="i in 31" :key="i">
                    <span
                      v-if="employee.employee_attendance_activity.length > 0"
                    >
                      <a
                        data-bs-toggle="modal"
                        v-if="
                          JSON.parse(
                            employee.employee_attendance_activity[0].activities
                          ).find((a) => a.day == i && a.status != 'leave')
                        "
                        data-bs-target="#attendance_info"
                        @click="
                          details_show(
                            JSON.parse(
                              employee.employee_attendance_activity[0]
                                .activities
                            ).find((a) => a.day == i),
                            employee
                          )
                        "
                        ><i class="fa fa-check text-success"></i
                      ></a>
                      <a
                        v-else-if="
                          JSON.parse(
                            employee.employee_attendance_activity[0].activities
                          ).find((a) => a.day == i && a.status == 'leave')
                        "
                        class="text-danger"
                        >L</a
                      >

                      <a v-else><i class="fa fa-times text-danger"></i></a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Attendance Modal -->
    <div class="modal custom-modal fade" id="attendance_info" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Attendance Info</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card punch-status">
                  <div class="card-body">
                    <h4 class="font-weight-bold" v-if="employee != null">
                      {{ JSON.parse(employee.basic_information).first_name }}
                      {{ JSON.parse(employee.basic_information).last_name }}
                    </h4>
                    <h5 class="card-title">
                      Timesheet
                      <small v-if="activity != null" class="text-muted">{{
                        activity.Date
                      }}</small>
                    </h5>
                    <div class="punch-det">
                      <h6>Punch In at</h6>
                      <p v-if="activity != null">{{ activity.punchin[0] }}</p>
                    </div>
                    <div class="punch-info">
                      <div class="punch-hours">
                        <span v-if="activity != null"
                          >{{ activity.works_hours }} hrs</span
                        >
                      </div>
                    </div>
                    <div class="punch-det">
                      <h6>Punch Out at</h6>
                      <p v-if="activity != null">
                        {{ activity.punchout[activity.punchout.length - 1] }}
                      </p>
                    </div>
                    <div class="statistics">
                      <div class="row">
                        <div class="col-md-6 col-6 text-center">
                          <div class="stats-box">
                            <p>Break</p>
                            <h6 v-if="activity != null">
                              {{ activity.break_hours }}
                            </h6>
                          </div>
                        </div>
                        <div class="col-md-6 col-6 text-center">
                          <div class="stats-box">
                            <p>Overtime</p>
                            <h6 v-if="activity != null">
                              {{ activity.over_time }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card recent-activity flex-fill">
                  <div class="card-body">
                    <h5 class="card-title">Today Activity</h5>
                    <ul v-if="activity" class="res-activity-list">
                      <div v-for="(s, i) in activity.punchin" :key="s">
                        <li>
                          <p class="mb-0">Punch In at</p>
                          <p class="res-activity-time">
                            <i class="far fa-clock"></i>
                            {{ s }}
                          </p>
                        </li>
                        <li v-if="activity.punchout[i]">
                          <p class="mb-0">Punch Out at</p>
                          <p class="res-activity-time">
                            <i class="far fa-clock"></i>
                            {{ activity.punchout[i] }}
                          </p>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Attendance Modal -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      startOfMonth: "",
      endOfMonth: "",
      currentDate: "",
      currentMonth: "",
      currentMonthName: "",
      currentYear: "",
      selectedYear: "",
      selectedMonth: "",
      currentTime: "",

      employees: [],
      activity: null,
      employee: null,
      user_id: "",
    };
  },
  methods: {
    getAttendance() {
      axios
        .get(
          "employee/attendance/activity/bysenior/" +
            this.user_id +
            "/" +
            this.currentMonthName +
            "/" +
            this.selectedYear
        )
        .then((response) => {
          if (response) {
            this.employees = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    current() {
      this.startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
      this.endOfMonth = parseInt(moment().endOf("month").format("DD"));
      this.currentDate = moment().format("D/MM/YYYY");
      this.currentMonth = moment().format("MM");
      this.currentMonthName = moment().format("MMMM");
      this.currentYear = moment().format("YYYY");
      this.selectedYear = this.currentYear;
      this.selectedMonth = this.currentMonthName;
      this.currentTime = moment().format("hh:mm:ss A");
    },

    details_show(activity, employee) {
      this.activity = activity;
      this.employee = employee;
    },
  },

  mounted: function () {
    this.user_id = JSON.parse(localStorage.getItem("user")).user.details.id;

    this.current();
    this.getAttendance();
  },
};
</script>

<style></style>
