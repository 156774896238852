<template>
    <div class="wrapper">
      <!-- Page Content -->
      <div class="content-wrapper p-4">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>Special Performance </h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                  <router-link :to="{ name: 'employee-dashboard' }">
                    <a href="">Dashboard</a>
                  </router-link>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Special Performance
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date"
                />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              
            
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable :data="specialPerformances" class="table table-striped custom-table">
              <thead>
                <tr>
                          <th>ID</th>
                          <th>Date</th>
                       
                          <th>Status</th>
  
                          <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">Sepecial Performance Report </h3>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
            <h4>Your kpis</h4>


            <div  v-if="specialPerformance!=''"> 
              
              <div class="border p-2" v-for="( kpi, index ) in JSON.parse(specialPerformance.kpis)" :key="index" >
        <h4>  <strong>Title :</strong>  {{ kpi.kpi_title }}  <strong class="ms-5" >From :</strong>  {{ kpi.from }}  <strong class="ms-5" >To :</strong>  {{ kpi.to }} <strong class="ms-5" >Type :</strong>  {{ kpi.type }} </h4>
        <h4>  Kpi Description </h4>
        <h4>  Status : {{ kpi.status }} </h4>



        <div class="border p-2 mt-2" >

          <input type="text" v-model="kpi.description" placeholder="Enter Your Description" class="form-control" >
          <input type="file"  placeholder="Enter Your " class="form-control mt-2" >

          <button class="btn btn-primary  mt-3" @click="update() ">Update</button>


        </div>

    



              </div>
              



            </div>

         
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
  
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="deleteModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete {{ title }}</h3>
                <button
                  type="button"
                  class="close visually-hidden"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="#"
                      @click.prevent="delete_single"
                      class="btn btn-primary continue-btn"
                      >Delete</a>
                    >
                  </div>
                  <div class="col-6">
                    <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
        
        <script>
  
  // import $ from "jquery";
  import axios from "axios";
  // import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  // import Constant from "@/other/Constant";
//   import Api from "@/other/Api";

  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
  
  
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
          title:"Performance Report",
          id:"",
          specialPerformances:[],
          specialPerformances_old:[],


         specialPerformance:"",
     
          

      };
    },
  
    methods: {
      list() {
          axios
          .get("employee/specialperformance")
          .then((response) => {
            var specialPerformances = response.data.data;
            this.specialPerformances_old = specialPerformances;

            this.specialPerformances = [];
  
            for (var specialperformance of specialPerformances) {
              var id = specialperformance.id;
              // var uuid = performance_apraisal.uuid;
              var date = specialperformance.created_at;
              var status="";
  
              var action =
                  "<a href='#' onclick='handleClick(" +
                  '"' +
                  id +
                  '"' +
                  ")' ' data-bs-toggle='modal' data-bs-target='#addModal'><i class='fas fa-eye'></i></a>  ";
  
              this.specialPerformances.push([
                id,
                date,
                status,             
                action,
              ]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      
     
      handleClick(id) {
          

       this.specialPerformance = this.specialPerformances_old.find((item) => item.id == id)


  
        // this.$router.push({ name: "jobdetails", query: { uuid: id } });
      },



    
    },
  
    mounted: function () {
      this.id = JSON.parse(localStorage.getItem("user")).user.details.id;
      window.handleClick = this.handleClick;
      this.list();
    },
  };
  </script>
        
        
              
           <style>
  @import "datatables.net-bs5";
  </style>