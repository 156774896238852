<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>Department</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                  <a href="dashboard">Dashboard</a>
                   </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Department
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Department Name" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0 ">
                

                <input type="text" class="form-control" placeholder="Department Code" />
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3">
            
                <i class="fas fa-search mt-3 secondary_color "></i> 
              
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">

          <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#help_Modal"
                class="me-2 pt-1"
              >
                <i
                  class="fas fa-question-circle primary_color"
                  title="Help"
                ></i>
              </a>
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add Department</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable
            :data="departments"
            class="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Department name</th>
                <th>ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Department</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department ID(Max 2 Digit)</label
                    >
                    <input
                      type="number"
                      min="0"
                      @input="errors.d_id = null"
                      v-model="d_id"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.d_id">
                      {{ errors.d_id[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="adddepartment"
                  class="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Department</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department name <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department.department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Department ID(Max 2 Digit)</label
                    >
                    <input
                      type="number"
                      @input="errors.d_id = null"
                      v-model="department.d_id"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.d_id">
                      {{ errors.d_id[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="updatedepartment(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <button
              type="button"
              class="close visually-hidden"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>

              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="deletedepartment"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
    
                    data-bs-dismiss="modal"
          
                 
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>
    
    <script>
import axios from "axios";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      errors: {},
      departments: [],

      department: {},
      uuid:""
    };
  },

  methods: {
    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            var departments = response.data.data;

            this.departments = [];

            for (var department of departments) {
              // var id = department.id;
              var uuid = department.uuid;

              var department_name = department.department_name;
              var d_id = department.d_id;
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.departments.push([department_name, d_id, action]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adddepartment() {
      axios
        .post("/company/departments", {
          department_name: this.department_name,
          d_id: this.d_id,
        })
        .then((response) => {
          if (response) {
            //  this.$refs.addForm.reset();

            this.department_name = "";
            this.d_id = "";
            $("#addModal .close").click();
            this.getdepartment();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    updatedepartment(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.getdepartment();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletedepartment() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
      
            // $("#addModal .close").click();
            $("#delete_client .close").click();
            this.getdepartment();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleClick(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
    this.uuid=uuid
  

    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.handleClick = this.handleClick;
    window.deletePopUp = this.deletePopUp;
    this.getdepartment();
  },
};
</script>
    
    
          
       <style>
@import "datatables.net-bs5";
</style>