<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <!-- Page Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="page-head-box">
            <h3>{{ title }}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'company-dashboard' }">
                    <a href="dashboard">Dashboard</a>
                   </router-link>
                  </li>
                <li class="breadcrumb-item active" aria-current="page">
                  PF Rule
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div class="row filter-row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee ID"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Employee Name"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group mb0">
                <select class="form-control form-select">
                  <option>Select Position</option>
                  <option>Global Technologies</option>
                  <option>Delta Infotech</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <i class="fas fa-search mt-3 secondary_color"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="add-emp-section">
            
            <a
              href="#"
              class="btn btn-success btn-add-emp"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              ><i class="fas fa-plus"></i> Add {{ title }}</a
            >
          </div>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="pfrules" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Grade</th>

                <th>PF%</th>
                <th>Age of job</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Add Client Modal -->
    <div id="addModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm" >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Eligible Salary</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="eligible_salary"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Age of job </label>
                    <input
                      type="text"
                      @input="errors.rate = null"
                      v-model="age_of_job"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Grade<span class="text-danger">*</span></label
                    >
                    <select v-model="grade_id" class="form-control form-select">
                      <option disabled value="null">Select a grade</option>
                      <option
                        v-for="grade in grades"
                        :key="grade"
                        :value="grade.id"
                      >
                        {{ grade.grade_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-1" v-if="errors.day">
                      {{ errors.day[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Interest Rate (%)</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="note"
                      class="form-control"
                      placeholder="%"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Employee Contribution (%)</label
                    >
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="employee_contribution"
                      class="form-control"
                      placeholder="% of Basic"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Company Contribution (%)</label
                    >
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="company_contribution"
                      class="form-control"
                      placeholder="% of Company contribution"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Reasness Allowance (%)</label>
                    <input
                      type="text"
                      @input="errors.note = null"
                      v-model="reasness_allowance"
                      class="form-control"
                      placeholder="Base on employee"
                    />
                    <p class="text-danger mt-1" v-if="errors.note">
                      {{ errors.note[0] }}
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Reason <span class="text-danger">*</span></label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="reason"
                      class="form-control"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1" v-if="errors.rate">
                      {{ errors.rate[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button @click.prevent="add" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="editModal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update {{ title }}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      id="okk"
                      type="text"
                      @input="errors.department_name = null"
                      v-model="department.department_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.department_name">
                      {{ errors.department_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{ title }} ID(Max 2 Digit)</label
                    >
                    <input
                      i
                      type="text"
                      @input="errors.code = null"
                      v-model="department.code"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.code">
                      {{ errors.code[0] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="update(department.uuid)"
                  class="btn btn-primary submit-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="deleteModal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete {{ title }}</h3>
              <button
                type="button"
                class="close visually-hidden"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="#"
                    @click.prevent="delete_single"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//   import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      title: "PF Rule",
      weekends: [],
      errors: {},
      department: {},
      day: null,
      uuid: "",
      pfrules: [],
      
      grades: [],
      grade_id: null,


      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
      },
    };
  },

  methods: {
    list() {
      axios
        .get("/company/pfrules")
        .then((response) => {
          var pfrules = response.data.data;

          for (var pfrule of pfrules) {
            var id = pfrule.id;
            var grade = pfrule.grade.grade_name;
            var p_pf = pfrule.p_pf;
            var age_of_job = pfrule.age_of_job;
            var action =
              "<a href='#' onclick='handleClick(" +
              '"' +
              id +
              '"' +
              ")' ' data-bs-toggle='modal' data-bs-target='#editModal'><i class='fas fa-pen'></i></a>   <a href='#' onclick='deletePopUp(" +
              '"' +
              id +
              '"' +
              ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

            this.pfrules.push([id, grade, p_pf, age_of_job, action]);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    add() {
      axios
        .post("/company/pfrules", {
          grade_id: this.grade_id,
          p_pf: this.p_pf,
          age_of_job: this.age_of_job,
          interest_rate: this.interest_rate,
          eligible_salary: this.eligible_salary,  
          employee_contribution: this.employee_contribution,
          company_contribution: this.company_contribution,
          reasness_allowance: this.reasness_allowance,
          reason: this.reason,
          status: 1,
        })
        .then((response) => {
          if (response) {
            $(".form-control").val("");


            this.list();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update(uuid) {
      axios
        .put("/company/departments/" + uuid, {
          department_name: this.department.department_name,
          d_id: this.department.d_id,
        })
        .then((response) => {
          if (response) {
            this.list();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getgrades() {
      axios
        .get("/company/grades")
        .then((response) => {
          if (response) {
            this.grades = response.data.data;

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/company/departments/" + this.uuid)
        .then((response) => {
          if (response) {
            $("#deleteModal.close").click();
            this.list();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      axios
        .get("/company/departments/" + id)
        .then((response) => {
          if (response) {
            this.department = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    window.edit = this.edit;
    window.deletePopUp = this.deletePopUp;
    this.list();
    this.getgrades();
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
