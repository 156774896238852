<template>
  <div class="wrapper">
    <!-- Page Content -->
    <div class="content-wrapper p-4">
      <div class="page-header">
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>Employees</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'company-dashboard' }">
                      <a href="dashboard">Dashboard</a>
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Employees
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->

        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Employee ID"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Employee Name"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group mb0">
                  <select class="form-control form-select">
                    <option>Select Position</option>
                    <option>Global Technologies</option>
                    <option>Delta Infotech</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <i class="fas fa-search mt-3 secondary_color"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="add-emp-section d-flex align-items-center justify-content-end"
            >
              <!-- Help Icon -->
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#help_Modal"
                class="me-2 pt-1"
              >
                <i
                  class="fas fa-question-circle primary_color"
                  title="Help"
                ></i>
              </a>
              <!-- Add Employee Button -->
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#add_client"
              >
                <i class="fas fa-plus"></i> Add Employee
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Page Header -->

      <!-- /Search Filter -->

      <div class="row">
        <div class="col-md-12">
          <DataTable :data="employees" class="table table-striped custom-table">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Employee ID</th>
                <th>Email</th>
                <th>Position</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- help Modal -->
    <div id="help_Modal" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Help</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="text-center">Help something</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- /help Modal -->

    <!-- Add Client Modal -->
    <div id="add_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Employee</h5>

            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        :class="{ collapsed: collapsed }"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Basic Information
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      :class="{ show: error }"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>First Name *</label>
                              <input
                                v-model="first_name"
                                @input="clearError('first_name')"
                                type="text"
                                class="form-control"
                              />
                              <sapn id="first_name" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Middle Name </label>
                              <input
                                v-model="middle_name"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Last Name </label>
                              <input
                                v-model="last_name"
                                @input="clearError('last_name')"
                                type="text"
                                class="form-control"
                              />

                              <sapn id="last_name" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Date of Birth * </label>
                              <input
                                v-model="dob"
                                @input="clearError('dob')"
                                type="date"
                                class="form-control"
                              />
                              <sapn id="dob" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Gender</label>
                              <select
                                class="form-control form-select"
                                v-model="gender"
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Blood Group</label>
                              <select
                                class="form-control form-select"
                                v-model="blood_group"
                              >
                                <option>Select</option>
                                <option>A (+ve)</option>
                                <option>A (-ve)</option>
                                <option>B (+ve)</option>
                                <option>B (-ve)</option>
                                <option>O (+ve)</option>
                                <option>O (-ve)</option>
                                <option>AB (+ve)</option>
                                <option>AB (-ve)</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Contact Number *</label>
                              <input
                                v-model="contact_number"
                                @input="clearError('contact_number')"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="contact_number" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Email* </label>
                              <input
                                v-model="email"
                                @input="clearError('email')"
                                type="email"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="email" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Religion</label>
                              <select
                                class="form-control form-select"
                                v-model="religion"
                              >
                                <option>Select</option>
                                <option>Islam</option>
                                <option>Hindu</option>
                                <option>Christian</option>
                                <option>Buddhist</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile">Passport/NID</label>
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        'passport_nid',
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Father's Name *</label>
                              <input
                                v-model="father_name"
                                @input="clearError('father_name')"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="father_name" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Father's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        father_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Mother's Name </label>
                              <input
                                v-model="mother_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Mother's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        mother_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Present Address</label>
                              <input
                                v-model="present_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>District</label>
                              <input
                                v-model="district_of_present_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Countery</label>
                              <input
                                v-model="country"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label> Permanent Address</label>
                              <input
                                v-model="permanent_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>District</label>
                              <input
                                v-model="district_of_permanent_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Country</label>
                              <input
                                v-model="country"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Emargency Contact Person Name</label>
                              <input
                                v-model="emargency_contact_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Emargency Contact Number *</label>
                              <input
                                v-model="emargency_contact_number"
                                @input="clearError('contact_number')"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="contact_number" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Name</label>
                              <input
                                v-model="nomeniee_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Relation</label>
                              <input
                                v-model="nomeniee_relation"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Contact Number</label>
                              <input
                                v-model="nomeniee_contact_number"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Nomeniee's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        nomeniee_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <label
                              for="file-input"
                              style="
                                height: 100px;
                                width: 100px;
                                background-color: #dedede;
                                padding: 30px;
                              "
                              >Select photo</label
                            >
                            <input
                              style="display: none"
                              id="file-input"
                              type="file"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingem">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseem"
                        aria-expanded="false"
                        aria-controls="collapseem"
                      >
                        Employment Details
                      </button>
                    </h2>
                    <div
                      id="collapseem"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingem"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Department</label>
                              <select
                                v-model="department_id"
                                class="form-control"
                                style="width: 100%"
                                @change="departmentpostions()"
                              >
                                Positions
                                <option disabled value="null">
                                  Select a Department
                                </option>
                                <option
                                  v-for="department in departments"
                                  :key="department"
                                  :value="department.id"
                                >
                                  {{ department.department_name }}
                                </option>
                              </select>
                              <p
                                class="text-danger mt-1"
                                v-if="errors.department_id"
                              >
                                {{ errors.department_id[0] }}
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Position</label>
                              <select
                                v-model="position_id"
                                class="form-control"
                                style="width: 100%"
                                @change="getlevel()"
                              >
                                <option disabled value="null">
                                  Select a Position
                                </option>
                                <option
                                  v-for="position in positions"
                                  :key="position"
                                  :value="position.id"
                                >
                                  {{ position.position_name }}
                                </option>
                              </select>
                              <p
                                class="text-danger mt-1"
                                v-if="errors.position_id"
                              >
                                {{ errors.position_id[0] }}
                              </p>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Assign Under</label>
                              <select
                                v-model="assigned_under"
                                class="form-control"
                                style="width: 100%"
                              >
                                <option disabled value="null">
                                  Select a position
                                </option>
                                <option
                                  v-for="position in newpostiions"
                                  :key="position"
                                  :value="position.id"
                                >
                                  {{ position.position_name }}
                                </option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Joining Date</label>
                              <input
                                v-model="joining_date"
                                type="date"
                                class="form-control"
                                @input="clearError('passing')"
                              />
                              <sapn id="passing" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Grade</label>
                              <select
                                v-model="grade_id"
                                class="form-control"
                                style="width: 100%"
                              >
                                <option disabled value="null">
                                  Select a grade
                                </option>
                                <option
                                  v-for="grade in grades"
                                  :key="grade"
                                  :value="grade.id"
                                >
                                  {{ grade.grade_name }}
                                </option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>
                        </div>

                        <div>
                          <button
                            class="btn btn-primary"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Education
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Degree*</label>
                              <select
                                v-model="degree"
                                class="form-control"
                                style="width: 100%"
                                @change="clearError('degree')"
                              >
                                <option selected="selected">SSC</option>
                                <option>HSC</option>
                                <option>Bachalor</option>
                                <option>Masters</option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Mejor</label>
                              <input
                                v-model="field"
                                type="text"
                                class="form-control"
                                @input="clearError('field')"
                              />
                              <sapn id="field" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Institute </label>
                              <input
                                v-model="institute"
                                type="text"
                                class="form-control"
                                @input="clearError('institute')"
                              />
                              <sapn id="institute" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Passing year </label>
                              <input
                                v-model="passing"
                                type="text"
                                class="form-control"
                                @input="clearError('passing')"
                              />
                              <sapn id="passing" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Result/Grade</label>
                              <input
                                v-model="result"
                                type="text"
                                class="form-control"
                                @input="clearError('result')"
                              />
                              <sapn id="result" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Certificate(PDF)</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        degree,
                                        'education'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 pt-1">
                            <button
                              @click="addeducation"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>

                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="educations.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Degree</th>
                                <th>Field</th>
                                <th>Institute</th>
                                <th>Passing year</th>
                                <th>Result</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(education, index) in educations"
                                :key="index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ education.degree }}</td>
                                <td>{{ education.field }}</td>
                                <td>{{ education.institute }}</td>
                                <td>{{ education.passing }}</td>
                                <td>{{ education.result }}</td>
                                <td
                                  v-if="
                                    docs.find(
                                      (el) =>
                                        el.document_name === education.degree
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-3">
                          <button
                            class="btn btn-primary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Work Exprience
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Company Name*</label>
                              <input
                                v-model="cname"
                                @input="clearError('cname')"
                                type="text"
                                class="form-control"
                              />
                              <span id="cname" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Address* </label>
                              <input
                                v-model="caddress"
                                @input="clearError('caddress')"
                                type="text"
                                class="form-control"
                              />
                              <span id="caddress" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Designation* </label>
                              <input
                                v-model="designation"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>State/ Province </label>
                              <input
                                v-model="state"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Country </label>
                              <input
                                v-model="country"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="form-group">
                              <label>Responsiblity</label>
                              <textarea
                                class="form-control"
                                v-model="responsiblity"
                                rows="4"
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Work from* </label>
                              <input
                                v-model="workfrom"
                                @input="clearError('workfrom')"
                                type="date"
                                min="0"
                                class="form-control"
                              />
                              <span id="workfrom" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Work till* </label>
                              <input
                                v-model="worktill"
                                @input="clearError('worktill')"
                                type="date"
                                class="form-control"
                              />
                              <span id="worktill" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Exprience Certificate</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        cname,
                                        'work'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 pt-1">
                            <button
                              @click="addwork"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>

                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="works.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Company Name</th>
                                <th>Address</th>
                                <th>Designation</th>
                                <th>Work from</th>
                                <th>Work till</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="work in works" :key="work">
                                <td>1.</td>
                                <td>{{ work.cname }}</td>
                                <td>{{ work.caddress }}</td>
                                <td>{{ work.designation }}</td>
                                <td>{{ work.workfrom }}</td>
                                <td>{{ work.worktill }}</td>
                                <td
                                  v-if="
                                    this.docs.find(
                                      (el) => el.document_name === work.cname
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-3">
                          <button
                            @click.prevent="addworkall"
                            class="btn btn-primary mr-2"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-secondary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Traning
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Traning Name</label>
                              <input
                                v-model="tname"
                                @input="clearError('tname')"
                                type="text"
                                class="form-control"
                              />
                              <span id="tname" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Organization</label>
                              <input
                                v-model="torg"
                                @input="clearError('torg')"
                                type="text"
                                class="form-control"
                              />
                              <span id="torg" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Country</label>
                              <input
                                v-model="country"
                                @input="clearError('torg')"
                                type="text"
                                class="form-control"
                              />
                              <span id="torg" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>From</label>
                              <input
                                v-model="tfrom"
                                @input="clearError('tfrom')"
                                type="date"
                                class="form-control"
                              />
                              <span id="tfrom" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Till</label>
                              <input
                                @input="clearError('ttill')"
                                v-model="ttill"
                                type="date"
                                class="form-control"
                              />
                              <span id="ttill" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile">
                                Training Certificate</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        tname,
                                        'traning'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 pt-1">
                            <button
                              @click="addt"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="tras.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Name</th>
                                <th>Organization</th>
                                <th>From</th>
                                <th>Till</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="tra in tras" :key="tra">
                                <td>1.</td>
                                <td>{{ tra.tname }}</td>
                                <td>{{ tra.torg }}</td>
                                <td>{{ tra.tfrom }}</td>
                                <td>{{ tra.ttill }}</td>
                                <td
                                  v-if="
                                    this.docs.find(
                                      (el) => el.document_name === tra.tname
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-2">
                          <button
                            @click.prevent="addtraall"
                            class="btn btn-primary mr-2"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-secondary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Visa and work permit
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Orgine Country*</label>
                              <input
                                v-model="orgine_country"
                                @input="clearError('orgine_country')"
                                type="text"
                                class="form-control"
                              />
                              <span id="orgine_country" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Passport No*</label>
                              <input
                                v-model="passport_no"
                                @input="clearError('passport_no')"
                                type="text"
                                class="form-control"
                              />
                              <span id="passport_no" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Reference Number</label>
                              <input
                                v-model="reference_number"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Issue date*</label>
                              <input
                                type="date"
                                v-model="visa_issue_date"
                                @input="clearError('visa_issue_date')"
                                class="form-control"
                              />
                              <span id="visa_issue_date" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Expri date*</label>
                              <input
                                type="date"
                                @input="clearError('visa_expri_date')"
                                v-model="visa_expri_date"
                                class="form-control"
                              />
                              <span id="visa_expri_date" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Issue in country*</label>
                              <select
                                v-model="visa_issue_in_country"
                                class="form-control"
                                @change="clearError('visa_issue_in_country')"
                                style="width: 100%"
                              >
                                <option selected="selected">Bangladesh</option>
                                <option>UK</option>
                                <option>USA</option>
                              </select>
                              <span
                                id="visa_issue_in_country"
                                class="error"
                              ></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Category</label>
                              <select
                                v-model="visa_category"
                                class="form-control"
                                style="width: 100%"
                                @change="clearError('visa_category')"
                              >
                                <option selected="selected">Student</option>
                                <option>Work</option>
                                <option>Toriest</option>
                              </select>
                              <span id="visa_category" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"> Image</label>
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        visa,
                                        'visa'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="addEmployee"
                  class="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit employee Modal  -->
    <div id="Edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Employee</h5>

            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        :class="{ collapsed: collapsed }"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Basic Information
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      :class="{ show: error }"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>First Name *</label>
                              <input
                                v-model="first_name"
                                @input="clearError('first_name')"
                                type="text"
                                class="form-control"
                              />
                              <sapn id="first_name" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Middle Name </label>
                              <input
                                v-model="middle_name"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Last Name </label>
                              <input
                                v-model="last_name"
                                @input="clearError('last_name')"
                                type="text"
                                class="form-control"
                              />

                              <sapn id="last_name" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Date of Birth * </label>
                              <input
                                v-model="dob"
                                @input="clearError('dob')"
                                type="date"
                                class="form-control"
                              />
                              <sapn id="dob" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Gender</label>
                              <select
                                class="form-control form-select"
                                v-model="gender"
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Blood Group</label>
                              <select
                                class="form-control form-select"
                                v-model="blood_group"
                              >
                                <option>Select</option>
                                <option>A (+ve)</option>
                                <option>A (-ve)</option>
                                <option>B (+ve)</option>
                                <option>B (-ve)</option>
                                <option>O (+ve)</option>
                                <option>O (-ve)</option>
                                <option>AB (+ve)</option>
                                <option>AB (-ve)</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Contact Number *</label>
                              <input
                                v-model="contact_number"
                                @input="clearError('contact_number')"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="contact_number" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Email* </label>
                              <input
                                v-model="email"
                                @input="clearError('email')"
                                type="email"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="email" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Religion</label>
                              <select
                                class="form-control form-select"
                                v-model="religion"
                              >
                                <option>Select</option>
                                <option>Islam</option>
                                <option>Hindu</option>
                                <option>Christian</option>
                                <option>Buddhist</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile">Passport/NID</label>
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        'passport_nid',
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Father's Name *</label>
                              <input
                                v-model="father_name"
                                @input="clearError('father_name')"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="father_name" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Father's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        father_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Mother's Name </label>
                              <input
                                v-model="mother_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Mother's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        mother_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Present Address</label>
                              <input
                                v-model="present_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>District</label>
                              <input
                                v-model="district_of_present_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Countery</label>
                              <input
                                v-model="country"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label> Permanent Address</label>
                              <input
                                v-model="permanent_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>District</label>
                              <input
                                v-model="district_of_permanent_address"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Country</label>
                              <input
                                v-model="country"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Emargency Contact Person Name</label>
                              <input
                                v-model="emargency_contact_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Emargency Contact Number *</label>
                              <input
                                v-model="emargency_contact_number"
                                @input="clearError('contact_number')"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                              <sapn id="contact_number" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Name</label>
                              <input
                                v-model="nomeniee_name"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Relation</label>
                              <input
                                v-model="nomeniee_relation"
                                type="text"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Nomeniee Contact Number</label>
                              <input
                                v-model="nomeniee_contact_number"
                                type="number"
                                min="0"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Nomeniee's Passport/NID</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        nomeniee_name,
                                        'basic'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <label
                              for="file-input"
                              style="
                                height: 100px;
                                width: 100px;
                                background-color: #dedede;
                                padding: 30px;
                              "
                              >Select photo</label
                            >
                            <input
                              style="display: none"
                              id="file-input"
                              type="file"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingem">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseem"
                        aria-expanded="false"
                        aria-controls="collapseem"
                      >
                        Employment Details
                      </button>
                    </h2>
                    <div
                      id="collapseem"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingem"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Department</label>
                              <select
                                v-model="department_id"
                                class="form-control"
                                style="width: 100%"
                                @change="departmentpostions()"
                              >
                                Positions
                                <option disabled value="null">
                                  Select a Department
                                </option>
                                <option
                                  v-for="department in departments"
                                  :key="department"
                                  :value="department.id"
                                >
                                  {{ department.department_name }}
                                </option>
                              </select>
                              <p
                                class="text-danger mt-1"
                                v-if="errors.department_id"
                              >
                                {{ errors.department_id[0] }}
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Position</label>
                              <select
                                v-model="position_id"
                                class="form-control"
                                style="width: 100%"
                                @change="getlevel()"
                              >
                                <option disabled value="null">
                                  Select a Position
                                </option>
                                <option
                                  v-for="position in positions"
                                  :key="position"
                                  :value="position.id"
                                >
                                  {{ position.position_name }}
                                </option>
                              </select>
                              <p
                                class="text-danger mt-1"
                                v-if="errors.position_id"
                              >
                                {{ errors.position_id[0] }}
                              </p>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Assign Under</label>
                              <select
                                v-model="assigned_under"
                                class="form-control"
                                style="width: 100%"
                              >
                                <option disabled value="null">
                                  Select a position
                                </option>
                                <option
                                  v-for="position in newpostiions"
                                  :key="position"
                                  :value="position.id"
                                >
                                  {{ position.position_name }}
                                </option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Joining Date</label>
                              <input
                                v-model="joining_date"
                                type="date"
                                class="form-control"
                                @input="clearError('passing')"
                              />
                              <sapn id="passing" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Grade</label>
                              <select
                                v-model="grade_id"
                                class="form-control"
                                style="width: 100%"
                              >
                                <option disabled value="null">
                                  Select a grade
                                </option>
                                <option
                                  v-for="grade in grades"
                                  :key="grade"
                                  :value="grade.id"
                                >
                                  {{ grade.grade_name }}
                                </option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>
                        </div>

                        <div>
                          <button
                            class="btn btn-primary"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Education
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Degree*</label>
                              <select
                                v-model="degree"
                                class="form-control"
                                style="width: 100%"
                                @change="clearError('degree')"
                              >
                                <option selected="selected">SSC</option>
                                <option>HSC</option>
                                <option>Bachalor</option>
                                <option>Masters</option>
                              </select>
                              <sapn id="degree" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Mejor</label>
                              <input
                                v-model="field"
                                type="text"
                                class="form-control"
                                @input="clearError('field')"
                              />
                              <sapn id="field" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Institute </label>
                              <input
                                v-model="institute"
                                type="text"
                                class="form-control"
                                @input="clearError('institute')"
                              />
                              <sapn id="institute" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Passing year </label>
                              <input
                                v-model="passing"
                                type="text"
                                class="form-control"
                                @input="clearError('passing')"
                              />
                              <sapn id="passing" class="error"></sapn>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Result/Grade</label>
                              <input
                                v-model="result"
                                type="text"
                                class="form-control"
                                @input="clearError('result')"
                              />
                              <sapn id="result" class="error"></sapn>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Certificate(PDF)</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        degree,
                                        'education'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 pt-1">
                            <button
                              @click="addeducation"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>

                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="educations.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Degree</th>
                                <th>Field</th>
                                <th>Institute</th>
                                <th>Passing year</th>
                                <th>Result</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(education, index) in educations"
                                :key="index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ education.degree }}</td>
                                <td>{{ education.field }}</td>
                                <td>{{ education.institute }}</td>
                                <td>{{ education.passing }}</td>
                                <td>{{ education.result }}</td>
                                <td
                                  v-if="
                                    docs.find(
                                      (el) =>
                                        el.document_name === education.degree
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-3">
                          <button
                            class="btn btn-primary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Work Exprience
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Company Name*</label>
                              <input
                                v-model="cname"
                                @input="clearError('cname')"
                                type="text"
                                class="form-control"
                              />
                              <span id="cname" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Address* </label>
                              <input
                                v-model="caddress"
                                @input="clearError('caddress')"
                                type="text"
                                class="form-control"
                              />
                              <span id="caddress" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Designation* </label>
                              <input
                                v-model="designation"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>State/ Province </label>
                              <input
                                v-model="state"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Country </label>
                              <input
                                v-model="country"
                                @input="clearError('designation')"
                                type="text"
                                class="form-control"
                              />
                              <span id="designation" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="form-group">
                              <label>Responsiblity</label>
                              <textarea
                                class="form-control"
                                v-model="responsiblity"
                                rows="4"
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Work from* </label>
                              <input
                                v-model="workfrom"
                                @input="clearError('workfrom')"
                                type="date"
                                min="0"
                                class="form-control"
                              />
                              <span id="workfrom" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <label>Work till* </label>
                              <input
                                v-model="worktill"
                                @input="clearError('worktill')"
                                type="date"
                                class="form-control"
                              />
                              <span id="worktill" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"
                                >Exprience Certificate</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        cname,
                                        'work'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 pt-1">
                            <button
                              @click="addwork"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>

                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="works.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Company Name</th>
                                <th>Address</th>
                                <th>Designation</th>
                                <th>Work from</th>
                                <th>Work till</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="work in works" :key="work">
                                <td>1.</td>
                                <td>{{ work.cname }}</td>
                                <td>{{ work.caddress }}</td>
                                <td>{{ work.designation }}</td>
                                <td>{{ work.workfrom }}</td>
                                <td>{{ work.worktill }}</td>
                                <td
                                  v-if="
                                    this.docs.find(
                                      (el) => el.document_name === work.cname
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-3">
                          <button
                            @click.prevent="addworkall"
                            class="btn btn-primary mr-2"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-secondary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Traning
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Traning Name</label>
                              <input
                                v-model="tname"
                                @input="clearError('tname')"
                                type="text"
                                class="form-control"
                              />
                              <span id="tname" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Organization</label>
                              <input
                                v-model="torg"
                                @input="clearError('torg')"
                                type="text"
                                class="form-control"
                              />
                              <span id="torg" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Country</label>
                              <input
                                v-model="country"
                                @input="clearError('torg')"
                                type="text"
                                class="form-control"
                              />
                              <span id="torg" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>From</label>
                              <input
                                v-model="tfrom"
                                @input="clearError('tfrom')"
                                type="date"
                                class="form-control"
                              />
                              <span id="tfrom" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label>Till</label>
                              <input
                                @input="clearError('ttill')"
                                v-model="ttill"
                                type="date"
                                class="form-control"
                              />
                              <span id="ttill" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile">
                                Training Certificate</label
                              >
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        tname,
                                        'traning'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                    ref="fileInput"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 pt-1">
                            <button
                              @click="addt"
                              type="button"
                              class="btn btn-default btn-sm mt-4 mb-4"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div style="max-height: 400px; overflow-y: auto">
                          <table
                            v-if="tras.length > 0"
                            class="table text-center table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Name</th>
                                <th>Organization</th>
                                <th>From</th>
                                <th>Till</th>
                                <th>Certificate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="tra in tras" :key="tra">
                                <td>1.</td>
                                <td>{{ tra.tname }}</td>
                                <td>{{ tra.torg }}</td>
                                <td>{{ tra.tfrom }}</td>
                                <td>{{ tra.ttill }}</td>
                                <td
                                  v-if="
                                    this.docs.find(
                                      (el) => el.document_name === tra.tname
                                    )
                                  "
                                >
                                  <i
                                    class="fas fa-file-alt"
                                    style="font-size: 24px; color: #2e2a77"
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="mt-2">
                          <button
                            @click.prevent="addtraall"
                            class="btn btn-primary mr-2"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-secondary ms-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Visa and work permit
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Orgine Country*</label>
                              <input
                                v-model="orgine_country"
                                @input="clearError('orgine_country')"
                                type="text"
                                class="form-control"
                              />
                              <span id="orgine_country" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Passport No*</label>
                              <input
                                v-model="passport_no"
                                @input="clearError('passport_no')"
                                type="text"
                                class="form-control"
                              />
                              <span id="passport_no" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Reference Number</label>
                              <input
                                v-model="reference_number"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Issue date*</label>
                              <input
                                type="date"
                                v-model="visa_issue_date"
                                @input="clearError('visa_issue_date')"
                                class="form-control"
                              />
                              <span id="visa_issue_date" class="error"></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Expri date*</label>
                              <input
                                type="date"
                                @input="clearError('visa_expri_date')"
                                v-model="visa_expri_date"
                                class="form-control"
                              />
                              <span id="visa_expri_date" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Issue in country*</label>
                              <select
                                v-model="visa_issue_in_country"
                                class="form-control"
                                @change="clearError('visa_issue_in_country')"
                                style="width: 100%"
                              >
                                <option selected="selected">Bangladesh</option>
                                <option>UK</option>
                                <option>USA</option>
                              </select>
                              <span
                                id="visa_issue_in_country"
                                class="error"
                              ></span>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label>Visa Category</label>
                              <select
                                v-model="visa_category"
                                class="form-control"
                                style="width: 100%"
                                @change="clearError('visa_category')"
                              >
                                <option selected="selected">Student</option>
                                <option>Work</option>
                                <option>Toriest</option>
                              </select>
                              <span id="visa_category" class="error"></span>
                            </div>
                          </div>

                          <div class="col-lg-5">
                            <div class="form-group">
                              <label for="exampleInputFile"> Image</label>
                              <div class="input-group">
                                <div class="custom-file">
                                  <input
                                    @change="
                                      handleMultipleFileUpload(
                                        $event,
                                        visa,
                                        'visa'
                                      )
                                    "
                                    type="file"
                                    class="form-control"
                                    id="exampleInputFile"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="updateEmployee"
                  class="btn btn-primary submit-btn"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit employee Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="Barry" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Cuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="barrycuda" type="text" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="barrycuda@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input
                      class="form-control"
                      value="barrycuda"
                      type="password"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Client ID <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      value="CLT-0001"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      value="9876543210"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value="Global Technologies"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Chat</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Estimates</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Invoices</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                      <td class="text-center">
                        <input checked="" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Client Modal -->
  </div>
</template>

<script>
import { Validator } from "@/other/Validator";
import $ from "jquery";

import axios from "axios";
// import Constant from "@/other/Constant";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import Api from "@/other/Api";
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },

  data() {
    return {
      employees: [],
      educations: [],
      departments: [],
      positions: [],
      newpostiions: [],
      levels: [],
      works: [],
      cers: [],
      tras: [],
      errors: "",
      department_id: null,
      position_id: null,
      level_id: null,
      assigned_under: null,
      grades: [],
      grade_id: null,
      error: false,
      collapsed: true,
      mfile: "",
      file: "",
      docs: [],

      father_name: "",
      mother_name: "",
      nomeniee_name: "",
    };
  },

  methods: {
    getemployees() {
      var progressbar = document.querySelector(".cprogress");

      progressbar.style.display = "block";

      

      axios
        .get(
          Api.company.emoloyee_list,

          {
            onDownloadProgress: (event) => {
          
               console.log(event)
            },
          }
        )
        .then((response) => {
          if (response) {
            var employees = response.data.data;
           this.progress()
          

            for (var employee of employees) {
              // var logourl =
              //   employee.logo != null
              //     ? Constant.filebaseurl + employee.logo.file.path
              //     : "";
              var employee_id = employee.e_id;
              var uuid = employee.uuid;
              var employee_name =
                '<h2 class="table-avatar"> <a href="clients_profile" class="avatar"></a> </h2>' +
                JSON.parse(employee.basic_information).first_name +
                JSON.parse(employee.basic_information).last_name;
              var email = JSON.parse(employee.basic_information).email;

              var position =
                employee.position != null
                  ? employee.position.position_name
                  : "Unassigned";
              position =
                '<span class="role-info role-bg-one">' + position + "</span>";

              var action =
                "<a href='#' ' data-bs-toggle='modal' data-bs-target='#Edit_employee'><i class='fas fa-pen'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")'  > <i class=' ms-3 far fa-eye'></i></a>  <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.employees.push([
                employee_name,
                employee_id,
                email,
                position,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
   progress() {
  var elem = document.querySelector(".progress-stick");   
  var width = 10;
  var id = setInterval(frame, 10);
  function frame() {
    if (width >= 100) {
      clearInterval(id);
      var progressbar = document.querySelector(".cprogress");
      width =10;

      elem.style.width = width + '%'; 



      progressbar.style.display = "none";
    } else {
      width++; 
      elem.style.width = width + '%'; 
    }
  }
},
    handleClick(uuid) {
      this.$router.push({
        name: "company-employee-profile",
        query: { uuid: uuid },
      });
    },

    addeducation() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "degree", value: this.degree, type: "required" },
        { field: "field", value: this.field, type: "required" },
        { field: "institute", value: this.institute, type: "required" },
        { field: "passing", value: this.passing, type: "required" },
        { field: "result", value: this.result, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var education = {
          degree: this.degree,
          field: this.field,
          institute: this.institute,
          passing: this.passing,
          result: this.result,
        };
        this.educations.push(education);

        this.degree = null;
        this.field = null;
        this.institute = null;
        this.passing = null;
        this.result = null;

        this.$refs.fileInput.value = null; // Reset the file input value
      }
    },
    addwork() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "cname", value: this.cname, type: "required" },
        { field: "caddress", value: this.caddress, type: "required" },
        { field: "designation", value: this.designation, type: "required" },
        { field: "workfrom", value: this.workfrom, type: "required" },
        { field: "worktill", value: this.worktill, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var work = {
          cname: this.cname,
          caddress: this.caddress,
          designation: this.designation,
          state: this.state,
          country: this.country,
          workfrom: this.workfrom,
          worktill: this.worktill,
          responsiblity: this.responsiblity,
        };
        this.works.push(work);

        this.cname = null;
        this.caddress = null;
        this.designation = null;
        this.state = null;
        this.country = null;
        this.workfrom = null;
        this.worktill = null;
        this.responsiblity = null;

        this.$refs.fileInput.value = null; // Reset the file input value
      }
    },
    addcer() {
      var cer = {
        cername: this.cername,
        cerissuedate: this.cerissuedate,
        cerdetails: this.cerdetails,
      };
      this.cers.push(cer);
    },
    addt() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "tname", value: this.tname, type: "required" },
        { field: "torg", value: this.torg, type: "required" },
        { field: "tfrom", value: this.tfrom, type: "required" },
        { field: "ttill", value: this.ttill, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var tra = {
          tname: this.tname,
          torg: this.torg,
          country: this.country,
          tfrom: this.tfrom,
          ttill: this.ttill,
        };
        this.tras.push(tra);

        this.tname = null;
        this.torg = null;
        this.country = null;
        this.tfrom = null;
        this.ttill = null;

        this.$refs.fileInput.value = null; // Reset the file input value
      }
    },

    addEmployee() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "first_name", value: this.first_name, type: "required" },
        { field: "last_name", value: this.last_name, type: "required" },
        { field: "father_name", value: this.father_name, type: "required" },
        { field: "email", value: this.email, type: "required|email" },
        { field: "dob", value: this.dob, type: "required" },
        {
          field: "contact_number",
          value: this.contact_number,
          type: "required",
        },
      ]);

      if (error) {
        this.error = true;
        this.collapsed = false;
        console.log(error);
      } else {
        var basic_info = {
          first_name: this.first_name,
          middle_name: this.middle_name,
          last_name: this.last_name,
          dob: this.dob,
          gender: this.gender,
          blood_groups: this.blood_groups,
          contact_number: this.contact_number,
          email: this.email,
          religion: this.religion,
          father_name: this.father_name,
          mother_name: this.mother_name,
          present_address: this.present_address,
          district_of_present_address: this.district_of_present_address,
          country_name: this.country_name,
          permanent_address: this.permanent_address,
          district_of_permanent_address: this.district_of_permanent_address,
          country: this.country,
          emargency_contact_name: this.emargency_contact_name,
          emargency_contact_number: this.emargency_contact_number,
          nomeniee_name: this.nomeniee_name,
          nomeniee_relation: this.nomeniee_relation,
          nomeniee_contact_number: this.nomeniee_contact_number,
          logo: "",
        };

        var formData = new FormData();

        if (this.docs.length > 0) {
          for (var i = 0; i < this.docs.length; i++) {
            formData.append("files[]", this.docs[i].file);
            formData.append("types[]", this.docs[i].type);
            formData.append("document_types[]", this.docs[i].document_type);
            formData.append("document_names[]", this.docs[i].document_name);
          }
        }

        formData.append("basic_information", JSON.stringify(basic_info));

        formData.append("company_department_id", this.department_id);
        formData.append("position_id", this.position_id);
        formData.append("grade_id", this.grade_id);
        formData.append("assigned_under", this.assigned_under);

        formData.append("educations", JSON.stringify(this.educations));
        formData.append("expreriences", JSON.stringify(this.works));
        formData.append("tranings", JSON.stringify(this.tras));

        var visa = {
          orgine_country: this.orgine_country,
          passport_no: this.passport_no,
          reference_number: this.reference_number,
          visa_issue_date: this.visa_issue_date,
          visa_expri_date: this.visa_expri_date,
          visa_issue_in_country: this.visa_issue_in_country,
          visa_category: this.visa_category,
          logo: "",
        };

        formData.append("visas", JSON.stringify(visa));

        axios
          .post(
            "/company/employees",

            formData,

            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response) {
              alert(response.data.message);

              this.getemployees();
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    handleMultipleFileUpload(event, document_name, type) {
      this.mfile = event.target.files[0];
      this.createDocument(document_name, type);
    },

    createDocument(document_name, type) {
      alert(document_name);
      if (this.mfile) {
        var doc = {
          file: this.mfile,
          type: type,
          document_type: "image",

          document_name: document_name,
          date:
            new Date().getDate() +
            "/" +
            (new Date().getMonth() + 1) +
            "/" +
            new Date().getFullYear(),
        };

        this.docs.push(doc);
      } else {
        alert("select the file");
      }
    },

    getdepartment() {
      axios
        .get("/company/departments")
        .then((response) => {
          if (response) {
            this.departments = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getposition() {
      axios
        .get("/company/positions")
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    departmentpostions() {
      axios
        .get("/company/positions/department/" + this.department_id)
        .then((response) => {
          if (response) {
            this.positions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    levelpostions() {
      let level = this.levels.find((el) => el.id === this.level_id);

      var level_name = level.level_name;
      const myArray = level_name.split(" ");
      var levelid = myArray[1] - 1;

      if (levelid > 0) {
        var newlevelname = "Level " + levelid;

        var newlevel = this.levels.find((el) => el.level_name === newlevelname);
        alert(newlevel);
        this.newpostiions = [];
        for (let i = 0; i < this.positions.length; i++) {
          if (this.positions[i].level_id == newlevel.id) {
            this.newpostiions.push(this.positions[i]);
          }
        }
      } else {
        this.newpostiions = [];
      }
    },
    getlevel() {
      axios
        .get("/company/levels")
        .then((response) => {
          if (response) {
            this.levels = response.data.data;

            let position = this.positions.find(
              (el) => el.id === this.position_id
            );

            var level = this.levels.find((el) => el.id === position.level_id);

            var level_name = level.level_name;
            const myArray = level_name.split(" ");
            var levelid = myArray[1] - 1;

            if (levelid > 0) {
              var newlevelname = "Level " + levelid;

              var newlevel = this.levels.find(
                (el) => el.level_name === newlevelname
              );

              this.newpostiions = [];
              for (let i = 0; i < this.positions.length; i++) {
                if (this.positions[i].level_id == newlevel.id) {
                  this.newpostiions.push(this.positions[i]);
                }
              }
            } else {
              this.newpostiions = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addtraall() {
      if (this.tras.length == 0) {
        alert("Add a tranig first");
      } else {
        axios
          .put("/employee/profiles/3", {
            user_id: "3",
            tranings: JSON.stringify(this.tras),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    addvisa() {
      var validator = new Validator();
      var error = validator.validated([
        {
          field: "orgine_country",
          value: this.orgine_country,
          type: "required",
        },
        { field: "passport_no", value: this.passport_no, type: "required" },
        {
          field: "visa_issue_date",
          value: this.visa_issue_date,
          type: "required",
        },
        {
          field: "visa_expri_date",
          value: this.visa_expri_date,
          type: "required",
        },
        {
          field: "visa_issue_in_country",
          value: this.visa_issue_in_country,
          type: "required",
        },
        { field: "visa_category", value: this.visa_category, type: "required" },
      ]);

      if (error) {
        console.log(error);
      } else {
        var visa = {
          orgine_country: this.orgine_country,
          passport_no: this.passport_no,
          reference_number: this.reference_number,
          visa_issue_date: this.visa_issue_date,
          visa_expri_date: this.visa_expri_date,
          visa_issue_in_country: this.visa_issue_in_country,
          visa_category: this.visa_category,
          logo: "",
        };

        axios
          .put("/employee/profiles/3", {
            user_id: "3",
            visas: JSON.stringify(visa),
          })
          .then((response) => {
            if (response) {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
    getgrades() {
      axios
        .get("/company/grades")
        .then((response) => {
          if (response) {
            this.grades = response.data.data;

            this.$refs.addForm.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.name = JSON.parse(localStorage.getItem("user")).user.name;
    this.getemployees();
    this.getdepartment();
    this.getgrades();

    window.handleClick = this.handleClick;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
