<template>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="row">
          <div class="col-md-12">
            <div class="page-head-box">
              <h3>Tax Rule</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Clients
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <div class="row filter-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group form-focus mb-0">
                  <input type="text" class="form-control floating" />
                  <label class="focus-label">Plan ID</label>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group form-focus mb-0">
                  <input type="text" class="form-control floating" />
                  <label class="focus-label">Plan Name</label>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group form-focus select-focus mb-0">
                  <select class="select floating">
                    <option>Select Company</option>
                    <option>Global Technologies</option>
                    <option>Delta Infotech</option>
                  </select>
                  <label class="focus-label">Company</label>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <a href="#" class="btn btn-success btn-search"
                  ><i class="fas fa-search me-2"></i> Search
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="add-emp-section">
              <a href="clients" class="grid-icon"><i class="fas fa-th"></i></a>
              <a href="clients_list" class="list-icon active"
                ><i class="fas fa-bars"></i
              ></a>
              <a
                href="#"
                class="btn btn-success btn-add-emp"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                ><i class="fas fa-plus"></i> Add plan</a
              >
            </div>
          </div>
        </div>
        <!-- /Search Filter -->
  
        <div class="row">
          <div class="col-md-12">
            <DataTable :data="taxrules" class="table table-striped custom-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>Type</th>
        
  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
  
      <!-- Add Client Modal -->
      <div id="addModal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create plan</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Country<span class="text-danger">*</span></label
                      >
                      <div class="custom-select">
                    <select v-model="country_id"
 >

                     <option disabled value="null">Select a country</option>
                      <option
                        v-for="country in countries"
                        :key="country"
                        :value="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                  </select>
                  </div>
  
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">State</label>
                      <input
                      type="text"
                      @input="errors.state = null"
                      v-model="state"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.state">
                      {{ errors.state[0] }}
                    </p>
    
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Type <span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.state = null"
                      v-model="type"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.state">
                      {{ errors.state[0] }}
                    </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Tax low Cap <span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.tax_low_cap = null"
                      v-model="tax_low_cap"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.tax_low_cap">
                      {{ errors.tax_low_cap[0] }}
                    </p>                   
                 </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Tax High Cap <span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.tax_high_cap = null"
                      v-model="tax_high_cap"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.tax_high_cap">
                      {{ errors.tax_high_cap[0] }}
                    </p>                    </div>
                  </div>
               
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >
                        Tax Cap Difference <span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.tax_cap_difference = null"
                      v-model="tax_cap_difference"
                      class="form-control"
                    />
                    <p
                      class="text-danger mt-1"
                      v-if="errors.tax_cap_difference"
                    >
                      {{ errors.tax_cap_difference[0] }}
                    </p>                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >
                        Age<span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.tax_cap_difference = null"
                      v-model="tax_cap_difference"
                      class="form-control"
                    />
                    <p
                      class="text-danger mt-1"
                      v-if="errors.tax_cap_difference"
                    >
                      {{ errors.tax_cap_difference[0] }}
                    </p>                    </div>
                  </div>
  
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="col-form-label"
                        >
                        Tax %<span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.p_tax = null"
                      v-model="p_tax"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.p_tax">
                      {{ errors.p_tax[0] }}
                    </p>                  </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="col-form-label"
                        >
                        Remarks<span class="text-danger">*</span></label
                      >
                      <input
                      type="text"
                      @input="errors.remark = null"
                      v-model="remark"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.remark">
                      {{ errors.remark[0] }}
                    </p>                </div>
                  </div>
  
                  <div class="col-md-2">
                    <button
                      style="margin-top: 37px"
                      class="btn btn-primary px-3 py-2"
                      type="button"
                      @click.prevent="adddetail"
                    >
                      <i class="fa fa-plus"></i> ADD
                    </button>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive m-t-15">
                      <table v-if="features.length>0" class="table table-striped custom-table">
                        <thead>
                          <tr>
                            <th>Feature</th>
                            <th class="text-center">value</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="feature in features" :key="feature" >
                            <td>{{feature.feature}}</td>
                            <td class="text-center">
                              {{feature.value}}
                            </td>
                            <td class="text-center">
                              <input checked="" type="checkbox" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <div class="row">
                 
  
                  <div v-if="cap_details.length>0" class="col-lg-12 mt-4">
                    <div style=" height: 150px; overflow: auto;" class="table-responsive m-t-15">
                      <table  class="table table-striped custom-table">
                          <thead style=" position:sticky;
                                 top: -3px ;background-color: white;" >
                          <tr>
                            <th>Low tax cap</th>
                            <th  >Low tax cap</th>
                            <th >Tax %</th>
                            <th >Action</th>
                         
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cap_detail in cap_details" :key="cap_detail">
                    <td>1.</td>
                    <td>{{ cap_detail.tax_low_cap }}</td>
                    <td>
                      {{ cap_detail.tax_high_cap }}
                    </td>
                 

                    
                    <td>
                      {{ cap_detail.p_tax }}
                    </td>

                   
                  </tr>
                      
           
                      
           
                   
                      
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button @click.prevent="createtax"  class="btn btn-primary submit-btn">Confirm</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->
  
      <!-- Edit Client Modal -->
      <div id="edit_client" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Client</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >First Name <span class="text-danger">*</span></label
                      >
                      <input class="form-control" value="Barry" type="text" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Last Name</label>
                      <input class="form-control" value="Cuda" type="text" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Username <span class="text-danger">*</span></label
                      >
                      <input class="form-control" value="barrycuda" type="text" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Email <span class="text-danger">*</span></label
                      >
                      <input
                        class="form-control floating"
                        value="barrycuda@example.com"
                        type="email"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Password</label>
                      <input
                        class="form-control"
                        value="barrycuda"
                        type="password"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Confirm Password</label>
                      <input
                        class="form-control"
                        value="barrycuda"
                        type="password"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Client ID <span class="text-danger">*</span></label
                      >
                      <input
                        class="form-control floating"
                        value="CLT-0001"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Phone </label>
                      <input
                        class="form-control"
                        value="9876543210"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label">Company Name</label>
                      <input
                        class="form-control"
                        type="text"
                        value="Global Technologies"
                      />
                    </div>
                  </div>
                </div>
                <div class="table-responsive m-t-15">
                  <table class="table table-striped custom-table">
                    <thead>
                      <tr>
                        <th>Module Permission</th>
                        <th class="text-center">Read</th>
                        <th class="text-center">Write</th>
                        <th class="text-center">Create</th>
                        <th class="text-center">Delete</th>
                        <th class="text-center">Import</th>
                        <th class="text-center">Export</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Projects</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>Tasks</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>Chat</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>Estimates</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>Invoices</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>Timing Sheets</td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                        <td class="text-center">
                          <input checked="" type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="submit-section">
                  <button
                    class="btn btn-primary cancel-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button class="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit Client Modal -->
  
      <!-- Delete Client Modal -->
      <div class="modal custom-modal fade" id="delete_client" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Delete Client</h3>
                <p>Are you sure want to delete?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a
                      href="javascript:void(0);"
                      class="btn btn-primary continue-btn"
                      >Delete</a
                    >
                  </div>
                  <div class="col-6">
                    <a
                      href="javascript:void(0);"
                      data-bs-dismiss="modal"
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Client Modal -->
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import $ from "jquery";
  // import Constant from "@/other/Constant";
  import DataTable from "datatables.net-vue3";
  import DataTablesCore from "datatables.net-bs5";
  DataTable.use(DataTablesCore);
  
  export default {
    components: { DataTable },
  
    data() {
      return {
        name: "",
        features: [],   
        errors: {},
      
   
        prices:[],
        countries: [],
        taxrules: [],
        cap_details: [],
        country_id: null,
      };
    },
  
    methods: {
    getCountry() {
      axios
        .get("public/countries")
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    adddetail() {
      var cap_detail = {
        tax_low_cap: this.tax_low_cap,
        tax_high_cap: this.tax_high_cap,
        tax_cap_difference: this.tax_cap_difference,
        age: this.age,
        p_tax: this.p_tax,
        remark: this.remark,
      };

      this.cap_details.push(cap_detail);
    },

    createtax() {
      axios
        .post("/superadmin/taxrules", {
          country_id: this.country_id,
          state: this.state,
          type: this.type,
          cap_details: JSON.stringify(this.cap_details),
          status: 1,
        })
        .then((response) => {
          if (response) {


            this.country_id = null;
            this.state = "";
            this.type = "";
            this.cap_details = [];
            $("#addModal .close").click();
            this.gettaxrule();

          }
        })
        .catch((error) => {

            console.log(error)
        //   this.errors = error.response.data.errors;
        });
    },


    gettaxrule() {
      axios
        .get("/superadmin/taxrules")
        .then((response) => {
            var taxrules = response.data.data;
            this.taxrules=[];

for (var taxrule of taxrules) {
  var uuid = taxrule.uuid;
  var id = taxrule.id;

  var country_name = taxrule.country.country_name
  var state = taxrule.state
  var type = taxrule.type



  var action =
    "<a href='#' ' data-bs-toggle='modal' data-bs-target='#edit_client'><i class='fas fa-pen'></i></a>   <a href='#' data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> <a href='#' onclick='handleClick(" +
    '"' +
    uuid +
    '"' +
    ")'  ><i class=' ms-3 far fa-eye'></i></a>";

  this.taxrules.push([id,country_name,state , type, action]);
}     

        })
        .catch((error) => {
          console.log(error.response);
        });
    },

  
  
      handleClick(uuid) {
        this.$router.push({
          name: "admin-companyprofile",
          query: { uuid: uuid },
        });
      },
    },

    
  
    mounted: function () {
     this.getCountry();
     this.gettaxrule();
      window.handleClick = this.handleClick;
    },
  };
  </script>
    
    
          
       <style>
  @import "datatables.net-bs5";
  </style>